import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Yup from 'yup'

import { Form, InputGroup } from 'react-bootstrap'
import { useQueryClient } from '@tanstack/react-query'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import { authLogin } from '../../services/auth.service'
import { setWindowClass } from '../../util/helpers'

function Login() {
  const [isAuthLoading, setAuthLoading] = useState(false)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const login = async (email: string, password: string): Promise<void> => {
    try {
      setAuthLoading(true)
      const token = await authLogin(email, password)
      toast.success('Login is succesvol!')
      setAuthLoading(false)
      queryClient.invalidateQueries({
        queryKey: ['user'],
      })
      navigate('/')
    }
    catch (error: any) {
      setAuthLoading(false)
      toast.error(error.message || 'Failed')
    }
  }

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required'),
    }),
    onSubmit: (values) => {
      login(values.email, values.password)
    },
  })

  setWindowClass('hold-transition login-page')

  return (
    <div className="login-box">
      {/*
      <Snowfall
        snowflakeCount={250}
      /> */}
      {/* <Fireworks
        options={{
          rocketsPoint: {
            min: 0,
            max: 100
          }
        }}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'fixed',
          pointerEvents: 'none',
        }}
      /> */}

      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h2">
            <b>DATAFIBER</b>
            <span>NETWORK</span>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">Inloggen</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.email}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                />
                {touched.email && errors.email
                  ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )
                  : (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                    )}
              </InputGroup>
            </div>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password
                  ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    )
                  : (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faLock} />
                      </InputGroup.Text>
                    )}
              </InputGroup>
            </div>

            <div className="row">
              <div className="col-8">
                <Checkbox type="icheck" checked={false}>
                  Mij Onthouden
                </Checkbox>
              </div>
              <div className="col-4">
                <Button type="submit" isLoading={isAuthLoading} block className="test">
                  Login
                </Button>
              </div>
            </div>
          </form>
          <p className="mb-1">
            <Link to="/forgot-password">Wachtwoord Vergeten</Link>
          </p>
        </div>
      </div>

    </div>
  )
}

export default Login
