import type { ReactNode } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getAccessToken } from '../services/auth.service'

// Step 1: Update TypeScript Interfaces
interface User {
  username: string
  email: string
  firstName: string
  lastName: string
}

interface Group {
  id: string
  name: string
}

// Corrected Permission type definition
export type PermissionType = 'ACCEPT' | 'DENY' | 'INHERIT'
export type OperationType = 'create' | 'read' | 'update' | 'delete'
export type Permission = {
  node: string
} & {
  [key in OperationType]?: PermissionType
}

export interface Permissions { user: Permission[], group: Permission[] }

interface UserData {
  id: string
  user: User
  group: Group
  permissions: Permissions
}

// Initial state
const initialState: UserData = {
  id: '',
  user: { username: '', email: '', firstName: '', lastName: '' },
  group: { id: '', name: '' },
  permissions: { user: [], group: [] },
}

// Step 2: Create and Type the Context
const UserContext = createContext<UserData>(initialState)

// Define the type for the props of UserProvider
interface UserProviderProps {
  children: ReactNode
}

// Use React.FC to define the component type
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData>(initialState)

  const userQuery = useQuery({
    queryKey: ['user'],

    queryFn: async () => {
      const response = await fetch(`/api/auth/profile`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const json = await response.json()

      return json
    },
  })

  useEffect(() => {
    if (userQuery.data) {
      setUserData({
        id: userQuery.data.id,
        user: userQuery.data.user,
        group: userQuery.data.group,
        permissions: userQuery.data.permissions,
      })
    }
  }, [userQuery.data])

  return (
    <UserContext.Provider value={userData}>
      {children}
    </UserContext.Provider>
  )
}

// Step 4: Create a Custom Hook
export function useUser() {
  return useContext(UserContext)
}
