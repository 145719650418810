import { useQuery, useQueryClient } from '@tanstack/react-query'
import * as React from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { getAccessToken } from '../../../services/auth.service'
import { DarkModeContext } from '../../../providers/DarkModeProvider'
import { selectTheme } from '../../../util/helpers'

interface adres {
  id: string
  street: string
  homeNumber: string
  homeLetter: string
  homeAddition: string
  zipcode: string
  city: string
  connections: string
}

interface selectType {
  value: string
  label: string
}

const speedOptions = [
  { value: '-5', label: 'Cable Only' },
  { value: '-1', label: 'FTU Only' },
  { value: '0', label: 'Inactief' },
  { value: '2', label: '2 Mbit/s (Alarm)' },
  { value: '25', label: '25 Mbit/s' },
  { value: '50', label: '50 Mbit/s' },
  { value: '100', label: '100 Mbit/s' },
  { value: '200', label: '200 Mbit/s' },
  { value: '250', label: '250 Mbit/s' },
  { value: '500', label: '500 Mbit/s' },
  { value: '1000', label: '1000 Mbit/s' },
  { value: '-4', label: 'DWDM Wave' },
  { value: '-3', label: 'CWDM Wave' },
  { value: '-2', label: 'Dark Fiber' },
]

const typeOptions = [
  { value: 'fiber', label: 'Glasvezel' },
  { value: 'air', label: 'Straalzender' },
  { value: 'vdsl', label: 'VDSL' },
  { value: 'starlink', label: 'Starlink' },
]

/**
 * Adding a new connection is a 3 step process
 * First, an address must be selected
 * Second, a business must be selected
 * Third, the connection type must be selected
 */

export function AansluitingToevoegen() {
  const [address, setAddress] = React.useState<adres | null>(null)
  const [postcode, setPostcode] = React.useState('')
  const [huisnummer, setHuisnummer] = React.useState('')
  const [toevoeging, setToevoeging] = React.useState<selectType | null>(null)
  const [toevoegingOptions, setToevoegingOptions] = React.useState<selectType[]>([])
  const [projecten, setProjecten] = React.useState([])
  const [bedrijven, setBedrijven] = React.useState([])
  const [workflows, setWorkflows] = React.useState<selectType[]>([])

  const [selectedProject, setSelectedProject] = React.useState<selectType | null>(null)
  const [selectedBedrijf, setSelectedBedrijf] = React.useState<selectType | null>(null)
  const [selectedWorkflow, setSelectedWorkflow] = React.useState<selectType | null>(null)
  const [selectedType, setSelectedType] = React.useState('')
  const [selectedSpeed, setSelectedSpeed] = React.useState('')

  const [bedrijfSearch, setBedrijfSearch] = React.useState('')
  const [projectSearch, setProjectSearch] = React.useState('')
  const [workflowSearch, setWorkflowSearch] = React.useState('')

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const adresQuery = useQuery({
    queryKey: ['adres', {
      postcode,
      huisnummer,
    }],

    queryFn: async () => {
      let queryString = `/api/bag/addresses?limit=100`

      // Strip spaces from postcode
      if (postcode) {
        queryString += `&zipcode=${encodeURIComponent(postcode)}`
      }

      if (huisnummer) {
        queryString += `&homeNumber=${encodeURIComponent(huisnummer)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (adresQuery.data) {
      const toevoegingen: selectType[] = []

      adresQuery.data.result.forEach((adres: adres) => {
        // If number is not an exact match
        if (adres.homeNumber == huisnummer) {
          if (!adres.homeAddition && !adres.homeLetter) {
            toevoegingen.push({
              value: adres.id,
              label: 'Geen toevoeging',
            } as selectType)
          }
          if (adres.homeAddition && !adres.homeLetter) {
            toevoegingen.push({
              value: adres.id,
              label: adres.homeAddition,
            } as selectType)
          }
          if (adres.homeLetter && !adres.homeAddition) {
            toevoegingen.push({
              value: adres.id,
              label: adres.homeLetter,
            } as selectType)
          }
          if (adres.homeLetter && adres.homeAddition) {
            toevoegingen.push({
              value: adres.id,
              label: `${adres.homeLetter}-${adres.homeAddition}`,
            } as selectType)
          }
        }
      })

      setToevoegingOptions(toevoegingen)
    }

    // If length is 1, set the address
    if (adresQuery.data && adresQuery.data.result.length === 1) {
      setAddress(adresQuery.data.result[0])
    }
    // If length is > 1, and no additions are available and postcode is fully filled in, Find the exact match and set the address
    if (adresQuery.data && adresQuery.data.result.length > 1 && toevoegingOptions.length === 0 && postcode.length === 6) {
      const exactMatch = adresQuery.data.result.find((adres: adres) => {
        return (adres.homeNumber == huisnummer && !adres.homeAddition && !adres.homeLetter)
      })
      if (exactMatch) {
        setAddress(exactMatch)
      }
    }

    // If length is > 1, additions are available, but not set and postcode is fully filled in, Find the exact match and set the address
    if (adresQuery.data && adresQuery.data.result.length > 1 && toevoegingOptions.length > 0 && !toevoeging && postcode.length === 6) {
      const exactMatch = adresQuery.data.result.find((adres: adres) => {
        return (adres.homeNumber == huisnummer && !adres.homeAddition && !adres.homeLetter)
      })
      if (exactMatch) {
        setAddress(exactMatch)
      }
    }

    // If toevoeging is selected, set the address
    if (toevoeging) {
      const selectedAddress = adresQuery.data?.result.find((adres: adres) => adres.id == toevoeging.value)
      setAddress(selectedAddress)
    }

    // If length is 0, clear the address
    if (adresQuery.data && adresQuery.data.result.length == 0) {
      setAddress(null)
    }

    // If homenumber doesn't match the selected address, clear the address
    if (adresQuery.data && adresQuery.data.result.length > 0 && address && address.homeNumber != huisnummer) {
      setAddress(null)
    }

    // If postcode is empty or partial, clear the address
    if (postcode == '' || postcode.length < 6) {
      setAddress(null)
    }

    // If length is > 1 and additions are available, and the current selected has no exact match, clear the address
    if (adresQuery.data && adresQuery.data.result.length > 1 && toevoegingOptions.length > 0 && toevoeging && toevoegingOptions.find((option: selectType) => option.value == toevoeging.value)) {
      const selectedAddress = adresQuery.data?.result.find((adres: adres) => adres.id == toevoeging.value)
      if (!selectedAddress || selectedAddress.homeNumber != huisnummer) {
        setAddress(null)
      }
    }

    // If toevoeging is selected but no longer available, clear the selection
    if (toevoeging && toevoegingOptions.length > 0 && !toevoegingOptions.find((option: selectType) => option.value == toevoeging.value)) {
      setToevoeging(null)
    }

    // If toevoeging is selected, but doesn't match the home number, clear the selection
    if (toevoeging && toevoegingOptions.length > 0 && toevoegingOptions.find((option: selectType) => option.value == toevoeging.value)) {
      const selectedAddress = adresQuery.data?.result.find((adres: adres) => adres.id == toevoeging.value)
      if (!selectedAddress || selectedAddress.homeNumber != huisnummer) {
        setToevoeging(null)
        setAddress(null)
      }
    }
  }, [adresQuery.data, huisnummer, postcode, toevoeging, address, toevoegingOptions.length])

  const projectenQuery = useQuery({
    queryKey: ['projecten', {
      projectSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/projects?limit=5'

      if (projectSearch) {
        queryString += `&naam=${encodeURIComponent(projectSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (projectenQuery.data) {
      setProjecten(projectenQuery.data.result.map((project: any) => {
        return {
          label: `${project.usergroup.name} | ${project.name}`,
          value: project.id,
        }
      }))
    }
  }, [projectenQuery.data])

  const workflowQuery = useQuery({
    queryKey: ['workflow', {
      workflowSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/connections/workflow?limit=5'

      if (workflowSearch) {
        queryString += `&naam=${encodeURIComponent(workflowSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (workflowQuery.data) {
      setWorkflows(workflowQuery.data.map((workflow: any) => {
        return {
          label: workflow.name,
          value: workflow.id,
        }
      }))
    }
  }, [workflowQuery.data])

  const bedrijvenQuery = useQuery({
    queryKey: ['bedrijven', {
      bedrijfSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/crm/companies?limit=5'

      if (bedrijfSearch) {
        queryString += `&naam=${encodeURIComponent(bedrijfSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (bedrijvenQuery.data) {
      setBedrijven(bedrijvenQuery.data.data.map((bedrijf: any) => {
        return {
          label: bedrijf.Account_Name,
          value: bedrijf.id,
        } as selectType
      }))
    }
  }, [bedrijvenQuery.data])

  const addConnection = async () => {
    if (!address || !selectedProject || !selectedBedrijf || !selectedType || !selectedSpeed || !selectedWorkflow) {
      return
    }

    // No cache
    const response = await fetch('/api/netwerk/aansluitingen', {
      method: 'post',
      headers: new Headers({
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        bag_num_id: address.id,
        project_id: selectedProject.value,
        company_id: selectedBedrijf.value,
        workflow_id: selectedWorkflow.value,
        type: selectedType,
        speed: selectedSpeed,
      }),
    })

    if (!response.ok) {
      // Toast error
      toast.error('Er is iets misgegaan bij het opslaan van de verbinding')
      throw new Error(response.statusText)
    }
    else {
      const json = await response.json()
      // Toast success
      toast.success('De verbinding is opgeslagen')

      // Reset form
      setHuisnummer('')
      setPostcode('')
      setToevoeging(null)
      setProjectSearch('')
      setBedrijfSearch('')
      setAddress(null)
      setSelectedProject(null)
      setSelectedBedrijf(null)

      queryClient.invalidateQueries({
        queryKey: ['aansluitingen'],
      })

      // Redirect to connections
      navigate(`/netwerkbeheer/aansluitingen/${json.id}`)
    }
  }

  return (
    (
      <div>
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Locatie Gegevens</h3>
          </div>
          <div className="card-body">
            {/**
              * Input field for postcode, home number and suffix
              */}
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label>Postcode</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Postcode"
                    value={postcode}
                    onChange={(e) => { setPostcode(e.target.value.replace(/\s/g, '').toUpperCase()) }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label>Huisnummer</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Huisnummer"
                    value={huisnummer}
                    onChange={(e) => { setHuisnummer(e.target.value) }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label>Toevoeging</label>
                  {/* Input dropdown field */}
                  <Select
                    options={toevoegingOptions}
                    value={toevoeging}
                    onChange={(e) => { setToevoeging(e) }}
                    isDisabled={toevoegingOptions.length === 0 || postcode.length < 6 || !huisnummer}
                    placeholder={toevoegingOptions.length ? 'Selecteer toevoeging' : 'Geen toevoegingen'}
                    theme={selectStyle}
                    menuPlacement="auto"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div>
              {/* Write selected address */}
              {address && address.id && (
                <>
                  {Number(address.connections) > 0 && (
                    <div
                      className="alert alert-warning alert-sm"
                      style={{
                        marginBottom: '0px',
                        height: '38px',
                        padding: '6px',
                      }}
                    >
                      <i className="fas fa-exclamation-triangle"></i>
                      {' '}
                      {address.street}
                      {' '}
                      {address.homeNumber}
                      {address.homeLetter}
                      {address.homeAddition}
                      ,
                      {' '}
                      {address.zipcode}
                      {' '}
                      {address.city}
                      {' '}
                      (
                      {address.connections}
                      {' '}
                      bestaande aansluiting(en) gevonden!)
                    </div>
                  ) || (
                    <div
                      className="alert alert-success alert-sm"
                      style={{
                        marginBottom: '0px',
                        height: '38px',
                        padding: '6px',
                      }}
                    >
                      <i className="fas fa-check"></i>
                      {' '}
                      {address.street}
                      {' '}
                      {address.homeNumber}
                      {address.homeLetter}
                      {address.homeAddition}
                      ,
                      {' '}
                      {address.zipcode}
                      {' '}
                      {address.city}
                    </div>
                  )}
                </>
              ) || (
                <div
                  className="alert alert-danger alert-sm"
                  style={{
                    marginBottom: '0px',
                    height: '38px',
                    padding: '6px',
                  }}
                >
                  <i className="fas fa-times"></i>
                  {' '}
                  Geen adres gevonden
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Project</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Select
                    options={projecten}
                    onChange={(value) => { setSelectedProject(value) }}
                    onInputChange={(e) => { setProjectSearch(e) }}
                    isLoading={projectenQuery.isLoading}
                    placeholder="Zoek een project"
                    theme={selectStyle}
                    noOptionsMessage={
                      () => 'Geen project gevonden'
                    }
                    menuPlacement="auto"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Select
                    options={workflows}
                    onChange={(value) => { setSelectedWorkflow(value) }}
                    onInputChange={(e) => { setWorkflowSearch(e) }}
                    isLoading={workflowQuery.isLoading}
                    placeholder="Zoek een workflow"
                    theme={selectStyle}
                    noOptionsMessage={
                      () => 'Geen workflow gevonden'
                    }
                    menuPlacement="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Bedrijfsgegevens</h3>
          </div>
          <div className="card-body">
            {/* Full width search field with dropdown from the search input for selection */}
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Select
                    options={bedrijven}
                    noOptionsMessage={
                      () => 'Geen bedrijven gevonden'
                    }
                    theme={selectStyle}
                    placeholder="Zoek een bedrijf"
                    isLoading={bedrijvenQuery.isLoading}
                    onInputChange={value => setBedrijfSearch(value)}
                    onChange={value => setSelectedBedrijf(value)}
                    menuPlacement="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Product</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Type</label>
                  <Select
                    options={typeOptions}
                    onChange={value => setSelectedType(value ? value.value : '')}
                    placeholder="Selecteer een type"
                    theme={selectStyle}
                    menuPlacement="auto"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Snelheid</label>
                  {/* Default selection or other that changes to an input field */}
                  <Creatable
                    theme={selectStyle}
                    options={speedOptions}
                    value={speedOptions.filter(option => option.value === selectedSpeed)}
                    onChange={value => setSelectedSpeed(value ? value.value : '')}
                    onCreateOption={(value) => {
                      speedOptions.push({ label: `Anders: ${value} Mbit/s`, value })
                      console.log(`Anders: ${value} Mbit/s`)
                      setSelectedSpeed(value)
                    }}
                    placeholder="Selecteer een snelheid"
                    formatCreateLabel={value => `Anders: ${value} Mbit/s`}
                    menuPlacement="auto"
                    createOptionPosition="first"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Confirm button on the right */}
        <div className="row mt-3">
          <div className="col-sm-12 ml-auto mt-1 col-xl-2">
            {/* 2 buttons, one for adding and returning to main view, one for adding and continuing */}
            <button
              disabled={(!address || !selectedBedrijf || !selectedProject || !selectedType || !selectedSpeed || !selectedWorkflow)}
              onClick={addConnection}
              type="button"
              className="btn btn-primary float-right btn-block"
              style={{ marginLeft: '5px' }}
            >
              Toevoegen
            </button>
          </div>
        </div>
      </div>
    )
  )
}
