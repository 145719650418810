import React from 'react'
import { useNavigate } from 'react-router-dom'

export function Error404() {
  const navigate = useNavigate()
  return (
    <div className="error-page">
      <h2 className="headline text-warning">
        <i className="fas fa-exclamation-triangle text-warning" />
      </h2>
      <div className="error-content">
        <h3>Oops! Object niet gevonden.</h3>
        <p>
          Het object dat u probeert te vinden bestaat niet, of u heeft geen toegang tot deze bron.
          <br />
          <br />
          <button className="btn btn-primary" onClick={() => navigate(-1)}>Ga terug</button>
        </p>
      </div>
    </div>
  )
}
