import React from 'react'
import favicon from './logo512.png'

interface LoadingScreenProps {
  loadingText: string // Define a prop for the loading text
}

function LoadingScreen({ loadingText }: LoadingScreenProps) {
  return (
    <div className="preloader flex-column justify-content-center align-items-center">
      <div className="ipl-progress-indicator" id="ipl-progress-indicator">
        <div className="ipl-progress-indicator-head">
          <div className="first-indicator"></div>
          <div className="second-indicator"></div>
        </div>
        <div className="insp-logo-frame">
          <img className="insp-logo-frame-img" src={favicon} alt="DN" height="60" width="60" />
          <sub>{loadingText}</sub>
          {' '}
          {/* Use the loadingText prop as the subscript */}
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
