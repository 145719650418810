import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'

function UpdateGuard({ children }: { children: ReactNode }) {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const [skipUpdate, setSkipUpdate] = useState(false)

  if (!('serviceWorker' in navigator)) {
    return <>{children}</>
  }

  useEffect(() => {
    // Check for service worker updates
    const checkForUpdate = async () => {
      const registration = await navigator.serviceWorker.getRegistration()
      if (registration) {
        registration.addEventListener('updatefound', () => {
          setUpdateAvailable(true)
        })
      }
    }

    checkForUpdate()
  }, [])

  const handleReload = async () => {
    const registration = await navigator.serviceWorker.getRegistration()
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
    }
  }

  const handleClose = () => {
    setSkipUpdate(true)
  }

  return (
    <div>
      {updateAvailable && !skipUpdate && (
        <div
          className="dark-mode"
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: 9999,
          }}
        >
          <div className="">
            <div className="pt-3" />
            <section className="content m-3">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Update beschikbaar</h3>
                  <button className="close" onClick={handleClose}>
                    <span aria-hidden="true"><FontAwesomeIcon icon={faXmark} /></span>
                  </button>
                </div>
                <div className="card-body">
                  <div className="">
                    <div className="row">
                      <div className="col-1">
                        <i
                          className="fas fa-2x fa-exclamation-triangle text-danger"
                          style={{
                            position: 'relative',
                            top: '50%',
                            transform: 'translatey(-50%)',
                          }}
                        >
                        </i>
                      </div>
                      <div
                        className="col-11"
                        style={{
                          paddingLeft: '20px',
                        }}
                      >
                        <h3 className="text-danger">Update beschikbaar</h3>
                        <p
                          className="text-danger"
                          style={{
                            margin: '0',
                          }}
                        >
                          Er is een update beschikbaar. Voer nu een update uit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary" onClick={handleReload}>Update uitvoeren</button>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

export default UpdateGuard
