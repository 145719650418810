import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from '../../../../services/auth.service'
import type { PermissionsObject } from '../../../../util/checkPermission'
import { getPermissions } from '../../../../util/checkPermission'
import { useUser } from '../../../../providers/UserProvider'
import type { ApiResponse } from '../../../../util/helpers'
import { prettySpeed } from '../../../../util/helpers'
import { ModemToevoegenModal } from './ModemToevoegenModal'
import { ModemBandwidthCard } from './ModemBandwidthCard'

interface DeviceApiResponse extends ApiResponse {
  result: any[]
}

export function AansluitingModemCard(
  { aansluitingId }: { aansluitingId: string },
) {
  const { permissions } = useUser()
  const [showModal, setShowModal] = useState(false)

  const modemPermissions: PermissionsObject = getPermissions('/frontend/modules/netwerkbeheer/aansluitingen/modems', permissions)

  const toggleShow = () => setShowModal(!showModal)

  const modemsQuery = useQuery({
    queryKey: ['modems', aansluitingId],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices?${new URLSearchParams({
                connectionID: aansluitingId,
            })
                }`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const json = await response.json() as DeviceApiResponse

      return json
    },
  })

  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">Modem</h3>
        <div className="card-tools">
          {modemPermissions.create && (
            <button type="button" className="btn btn-primary" onClick={toggleShow}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          )}
        </div>
      </div>
      <div className="card-body">
        {modemsQuery.data && modemsQuery.data.result.length === 0 && (
          <div className="card card-primary">
            <div className="card-body">
              {/* Align center */}
              <div style={{
                textAlign: 'center',
              }}
              >
                <strong>Geen modem gevonden</strong>
              </div>
            </div>
          </div>
        )}
        {modemsQuery.data && modemsQuery.data.result.map((modem: any) => (
          <Modem key={modem.id} modem={modem} />
        ))}
      </div>
      {modemsQuery.isLoading && <div className="overlay dark"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>}
      {modemsQuery.isError && (
        <div className="overlay dark">
          <div className="row">
            <div className="col-1">
              <i
                className="fas fa-2x fa-exclamation-triangle text-danger"
                style={{
                  position: 'relative',
                  top: '50%',
                  transform: 'translatey(-50%)',
                }}
              >
              </i>
            </div>
            <div
              className="col-11"
              style={{
                paddingLeft: '20px',
              }}
            >
              <h3 className="text-danger">Er is iets misgegaan</h3>
              <p
                className="text-danger"
                style={{
                  margin: '0',
                // }}>Er is iets misgegaan bij het ophalen van de modems. Probeer het later opnieuw.</p>
                }}
              >
                De aliens hebben dit onderdeel ontvoerd voor een kosmische vreugderit! Ze komen snel terug, excuses voor het ongemak!
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Render the modal if showModal state is true */}

      <ModemToevoegenModal aansluitingId={aansluitingId} show={showModal} setShowModal={setShowModal} />

    </div>
  )
}

interface DeviceApiResponse2 {
  id: string
  deviceName: string
  deviceModel: {
    id: string
    deviceName: string
    deviceType: string
    isCPE: boolean
    isPON: boolean
  }
  managementAddress: {
    address: string
    block: {
      id: string
      network: string
      netmask: number
      version: string
    }
  }
  connection: {
    id: string
    company: {
      id: string
      name: string
    }
    speed: number
    type: string
  }
  evc: EVC[]
}

interface EVC {
  id: string
  name: string
  vpls_id: number
  speed: number
  company: {
    id: string
    name: string
  }
  serviceProvider: {
    id: string
    name: string
  }
}

function Modem({ modem }: { modem: any }) {
  const { permissions } = useUser()
  const navigate = useNavigate()

  const modemDetailsPermissions: PermissionsObject = getPermissions('/frontend/modules/netwerkbeheer/aansluitingen/modems/details', permissions)

  const deviceQuery = useQuery({
    queryKey: ['device', modem.id],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices/${modem.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json() as DeviceApiResponse2
    },
  })

  if (modem.deviceModel && modem.deviceModel.deviceType === 'NOCPE') {
    return (
      <Card className="card-primary" key={modem.id}>
        <Card.Header>
          <Card.Title>
            {modem.deviceModel.deviceType}
            :
            {' '}
            {modem.deviceName}
          </Card.Title>
          <div className="card-tools">
          </div>
        </Card.Header>
        <Card.Body>
          <EVCTable evcs={deviceQuery.data?.evc} />
        </Card.Body>
      </Card>
    )
  }

  const healthQuery = useQuery({
    queryKey: ['modem', 'health', modem.id],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices/${modem.id}/health`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
    refetchInterval: 1000 * 30,
  })

  return (
    <div className="card card-primary" key={modem.id}>
      <div className="card-header">
        <h3 className="card-title">
          {modem.deviceModel.deviceType}
          :
          {' '}
          {modem.deviceName}
        </h3>
        <div className="card-tools">
          {/* Render the loading spinner if the query is still loading */}
          {healthQuery.isLoading && (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {/* Render the error message if the query failed */}
          {healthQuery.isError && (
            <span
              className="badge badge-warning"
              style={{
                fontSize: '1rem',
              }}
            >
              KAN STATUS NIET OPHALEN
            </span>
          )}
          {healthQuery.data && typeof healthQuery.data.host == 'undefined' && healthQuery.data.status === 'UP' && (
            <span
              className="badge badge-success mr-1"
              style={{
                fontSize: '1rem',
              }}
            >
              ONLINE
            </span>
          )}
          {healthQuery.data && typeof healthQuery.data.host == 'undefined' && healthQuery.data.status === 'DOWN' &&  (
            <span
              className="badge badge-danger mr-1"
              style={{
                fontSize: '1rem',
              }}
            >
              OFFLINE
            </span>
          )}
          { /* Device health status flag */}
          {healthQuery.data && healthQuery.data.host === 'UP' && (
            <span
              className="badge badge-success"
              style={{
                fontSize: '1rem',
              }}
            >
              OLT: ONLINE
            </span>
          )}
          {healthQuery.data && healthQuery.data.host === 'DOWN' && (
            <span
              className="badge badge-danger mr-1"
              style={{
                fontSize: '1rem',
              }}
            >
              OLT: OFFLINE
            </span>
          )}
          { /* Device health status flag */}
          {healthQuery.data && healthQuery.data.host === 'UP' && healthQuery.data.status === 'UP' && (
            <span
              className="badge badge-success"
              style={{
                fontSize: '1rem',
              }}
            >
              CPE: ONLINE
            </span>
          )}
          {healthQuery.data && healthQuery.data.host === 'DOWN' && healthQuery.data.status === 'UP' && (
            <span
              className="badge badge-secondary"
              style={{
                fontSize: '1rem',
              }}
            >
              👈 ⚠️ CPE: ONLINE ⚠️
            </span>
          )}
          {healthQuery.data && healthQuery.data.host && healthQuery.data.status === 'DOWN' && (
            <span
              className="badge badge-danger"
              style={{
                fontSize: '1rem',
              }}
            >
              CPE: OFFLINE
            </span>
          )}
        </div>
      </div>
      {modem.deviceModel && !modem.deviceModel.isPON && (
        <div className="card-body">
          <div className="row">
            <div className="col-2">
              <strong>Apparaat type:</strong>
              <br />
              <strong>Apparaat Naam:</strong>
              <br />
              {modemDetailsPermissions.read && (
                <>
                  <strong>SNMP Community:</strong>
                  <br />
                </>
              )}
              {modemDetailsPermissions.read && (
                <>
                  <strong>Management IP:</strong>
                  <br />
                </>
              )}
            </div>
            <div className="col-10">
              {modem.deviceModel.deviceType}
              <br />
              {modem.deviceName}
              <br />
              {modemDetailsPermissions.read && (
                <>
                  {modem.snmpCommunity}
                  <br />
                </>

              )}
              {modemDetailsPermissions.read && (
                <>
                  {modem.managementAddress.address}
                  /
                  {modem.managementAddress.block.netmask}
                  <br />
                </>
              )}
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-12">
              <ModemBandwidthCard modem={modem} maximimumBandwidth={deviceQuery.data?.connection.speed} />
            </div>
          </div>
          <Row>
            <Col>
              <EVCTable evcs={deviceQuery.data?.evc} />
            </Col>
          </Row>
        </div>
      )}
      {modem.deviceModel && modem.deviceModel.isPON && (
        <div className="card-body">
          <div className="row">
            <div className="col-2">
              <strong>Apparaat type:</strong>
              <br />
              <strong>Apparaat Naam:</strong>
              <br />
              <strong>Serienummer:</strong>
              <br />
            </div>
            <div className="col-10">
              {modem.deviceModel.deviceType}
              <br />
              {modem.deviceName}
              <br />
              {modem.serialNumber}
              <br />
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-12">
              <ModemBandwidthCard modem={modem} />
            </div>
          </div>
          <Row>
            <Col>
              <EVCTable evcs={deviceQuery.data?.evc} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

function EVCTable({ evcs }: { evcs: EVC[] | undefined }) {
  const navigate = useNavigate()

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>EVC ID</th>
          <th>EVC Naam</th>
          <th>EVC Snelheid</th>
          <th>Bedrijf</th>
          <th>Service Provider</th>
        </tr>
      </thead>
      <tbody>
        {(evcs ?? []).map(evc => (
          <tr key={evc.id} onDoubleClick={() => { navigate(`/netwerkbeheer/evcs/${evc.id}`) }}>
            <td>{evc.vpls_id}</td>
            <td>{evc.name}</td>
            <td>{prettySpeed(evc.speed)}</td>
            <td>{evc.company.name}</td>
            <td>{evc.serviceProvider.name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
