import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import proj4 from 'proj4'
import * as Mapbox from 'react-map-gl'
import { getAccessToken } from '../../services/auth.service'
import { epsg28992Projection } from './constants'
import type { HoverInfo } from './Map'

export interface ConnectionMarkerProps {
  hoverInfo: HoverInfo | null
}

// Define EPSG:28992 (RD Coordinates) Rijksdriehoekstelsel
proj4.defs('EPSG:28992', epsg28992Projection)

interface Aansluiting {
  adres: {
    id: string
    streetName: string
    homeNumber: number
    homeLetter: string
    homeAddition: string
    street: string
    city: string
    zipcode: string
  }
  company: {
    id: string
    name: string
  }
  connection: {
    id: string
    type: string
    speed: number
    active: number
  }
}

function ConnectionMarker({ hoverInfo }: ConnectionMarkerProps) {
  const [connection, setConnection] = useState<Aansluiting | null>(null)

  const connectionQuery = useQuery({
    queryKey: ['map', 'getAansluiting', hoverInfo?.id],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/aansluitingen/${hoverInfo?.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },

    enabled: !!hoverInfo?.id,
  })

  useEffect(() => {
    if (connectionQuery.data) {
      setConnection(connectionQuery.data)
    }
  }, [connectionQuery.data])

  return (
    <Mapbox.Popup
      longitude={hoverInfo?.longitude || 0}
      latitude={hoverInfo?.latitude || 0}
      closeButton={false}
      closeOnClick={false}
      offset={40}
      anchor="bottom"
      style={{
        opacity: 1,
      }}
    >
      {connection && (
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div
              className="flex flex-col"
              style={{
                color: 'black',
              }}
            >
              {connection.connection.id == hoverInfo?.id
                ? (
                    <>
                      <div className="text-sm font-bold">{connection.company.name}</div>
                      <div className="text-xs">
                        {connection.adres.streetName}
                        {' '}
                        {connection.adres.homeNumber}
                        {connection.adres.homeLetter}
                        {connection.adres.homeAddition}
                      </div>
                      <div className="text-xs">
                        {connection.adres.zipcode}
                        {' '}
                        {connection.adres.city}
                      </div>
                    </>
                  )
                : (
                    <div className="text-sm font-bold">Loading...</div>
                  )}

            </div>
          </div>
        </div>
      )}
    </Mapbox.Popup>
  )
}

export default ConnectionMarker
