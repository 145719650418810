import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAccessToken } from '../../services/auth.service'

interface Adres {
  id: string
  street: string
  homeNumber: string
  homeLetter: string
  homeAddition: string
  zipcode: string
  city: string
  connections: number
}

interface ApiResponse {
  total_rows: number
  total_pages: number
  page: number
  result: Adres[]
}

export function BagAddresses() {
  const [ready, setReady] = React.useState(false)
  const [presentFilter, setPresentFilter] = React.useState(false)
  const [zipcodeFilter, setZipcodeFilter] = React.useState('')
  const [homeNumberFilter, setHomeNumberFilter] = React.useState('')
  const [homeLetterFilter, setHomeLetterFilter] = React.useState('')
  const [homeAdditionFilter, setHomeAdditionFilter] = React.useState('')
  const [streetFilter, setStreetFilter] = React.useState('')
  const [cityFilter, setCityFilter] = React.useState('')
  const [limit, setLimit] = React.useState(25)
  const [offset, setOffset] = React.useState(0)
  const [oldData, setOldData] = React.useState<ApiResponse>({
    total_rows: 0,
    total_pages: 0,
    page: 0,
    result: [],
  })

  // const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()

  // Update navigation whenever filters, limit, offset, or oldData change
  React.useEffect(() => {
    navigate(location.pathname, {
      state: {
        filters: {
          zipcodeFilter,
          homeNumberFilter,
          homeLetterFilter,
          homeAdditionFilter,
          streetFilter,
          cityFilter,
          presentFilter,
        },
        limit,
        offset,
        oldData,
      },
      replace: true,
    })
  }, [zipcodeFilter, homeNumberFilter, homeLetterFilter, homeAdditionFilter, streetFilter, cityFilter, presentFilter, limit, offset, oldData, navigate, location.pathname])

  // Parse filters, limit, offset, and oldData from location state
  React.useEffect(() => {
    if (location.state) {
      const { filters, limit: savedLimit, offset: savedOffset, oldData: savedOldData } = location.state as {
        filters: {
          zipcodeFilter: string
          homeNumberFilter: string
          homeLetterFilter: string
          homeAdditionFilter: string
          streetFilter: string
          cityFilter: string
          presentFilter: boolean
        }
        limit: number
        offset: number
        oldData: ApiResponse
      }
      if (filters) {
        const { zipcodeFilter, homeNumberFilter, homeLetterFilter, homeAdditionFilter, streetFilter, cityFilter, presentFilter } = filters
        setZipcodeFilter(zipcodeFilter || '')
        setHomeNumberFilter(homeNumberFilter || '')
        setHomeLetterFilter(homeLetterFilter || '')
        setHomeAdditionFilter(homeAdditionFilter || '')
        setStreetFilter(streetFilter || '')
        setCityFilter(cityFilter || '')
        setPresentFilter(presentFilter || false)
      }
      if (savedLimit) {
        setLimit(savedLimit)
      }
      if (savedOffset) {
        setOffset(savedOffset)
      }
      if (savedOldData) {
        setOldData(savedOldData)
      }
    }

    // Set ready after 500ms to prevent update logic from running before initial state is set
    setTimeout(() => {
      setReady(true)
    }, 500)
  }, [])

  const { isLoading, error, data } = useQuery({
    queryKey: ['adressen', {
      zipcode: zipcodeFilter,
      homeNumber: homeNumberFilter,
      homeLetter: homeLetterFilter,
      homeAddition: homeAdditionFilter,
      street: streetFilter,
      city: cityFilter,
      present: presentFilter,
      limit,
      offset,
    }],

    queryFn: async () => {
      let queryString = `/api/bag/addresses?limit=${limit}&page=${offset}`

      if (zipcodeFilter) {
        queryString += `&zipcode=${encodeURIComponent(zipcodeFilter)}`
      }
      if (homeNumberFilter) {
        queryString += `&homeNumber=${encodeURIComponent(homeNumberFilter)}`
      }
      if (homeLetterFilter) {
        queryString += `&homeLetter=${encodeURIComponent(homeLetterFilter)}`
      }
      if (homeAdditionFilter) {
        queryString += `&homeAddition=${encodeURIComponent(homeAdditionFilter)}`
      }
      if (streetFilter) {
        queryString += `&street=${encodeURIComponent(streetFilter)}`
      }
      if (cityFilter) {
        queryString += `&city=${encodeURIComponent(cityFilter)}`
      }
      if (presentFilter) {
        queryString += `&present=true`
      }

      const response = await fetch(queryString, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  const onLimitChange = (event: { target: { value: string } }) => {
    setLimit(Number.parseInt(event.target.value))

    // Calculate current index
    const currentIndex = offset * limit

    // Calculate new offset
    const newOffset = Math.floor(currentIndex / Number.parseInt(event.target.value))

    setOffset(newOffset)
  }

  useEffect(() => {
    if (data) {
      setOldData(data)
    }
  }, [data])

  // Reset pagination when filters change
  useEffect(() => {
    if (!ready)
      return
    setOffset(0)
  }, [zipcodeFilter, homeNumberFilter, homeLetterFilter, homeAdditionFilter, streetFilter, cityFilter, presentFilter, limit])

  return (
    (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            Adressen (
            {oldData.total_rows}
            )
          </h3>
        </div>
        <div className="card-body table-responsive p-0">
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>Aanwezig</th>
                <th>Straatnaam</th>
                <th>Huisnummer</th>
                <th>Letter</th>
                <th>Toevoeging</th>
                <th>Postcode</th>
                <th>Woonplaats</th>
              </tr>
            </thead>
            <tbody>
              {/* Filter fields */}
              <tr>
                <td>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="presentFilter" checked={presentFilter} onChange={e => setPresentFilter(e.target.checked)} />
                  </div>
                </td>
                <td>
                  <input type="text" className="form-control" value={streetFilter} onChange={e => setStreetFilter(e.target.value)} />
                </td>
                <td>
                  <input type="text" className="form-control" value={homeNumberFilter} onChange={e => setHomeNumberFilter(e.target.value)} />
                </td>
                <td>
                  <input type="text" className="form-control" value={homeLetterFilter} onChange={e => setHomeLetterFilter(e.target.value)} />
                </td>
                <td>
                  <input type="text" className="form-control" value={homeAdditionFilter} onChange={e => setHomeAdditionFilter(e.target.value)} />
                </td>
                <td>
                  <input type="text" className="form-control" value={zipcodeFilter} onChange={e => setZipcodeFilter(e.target.value)} />
                </td>
                <td>
                  <input type="text" className="form-control" value={cityFilter} onChange={e => setCityFilter(e.target.value)} />
                </td>
              </tr>
              {/* Data */}
              {/* If the page is loading and has no data / old data */}
              {isLoading && !data && !oldData && (
                <tr>
                  <td colSpan={7}>Loading...</td>
                </tr>
              )}
              {/* If error */}
              {error && (
                <tr>
                  <td colSpan={7}>Error</td>
                </tr>
              )}
              {/* If no data */}
              {!isLoading && !error && oldData && oldData.result.length === 0 && (
                <tr>
                  <td colSpan={7}>No data</td>
                </tr>
              )}
              {/* If data but loading, show rows greyed out */}
              {isLoading && !error && oldData && oldData.result.length > 0 && (
                oldData.result.map((adres: Adres) => (
                  <tr key={adres.id}>
                    {/* Pad ID to 16 digits */}
                    <td>
                      {adres.connections > 0
                        ? (
                            <>
                              <FontAwesomeIcon icon={faStar} />
                            </>
                          )
                        : <></>}
                    </td>
                    <td>{adres.street}</td>
                    <td>{adres.homeNumber}</td>
                    <td>{adres.homeLetter}</td>
                    <td>{adres.homeAddition}</td>
                    <td>{adres.zipcode}</td>
                    <td>{adres.city}</td>
                  </tr>
                ))
              )}

              {/* If data */}
              {!isLoading && !error && data && data.result.map((adres: Adres) => (
              // On double click, go to adres page
                (
                  <tr key={adres.id} onDoubleClick={() => navigate(`/bag/adressen/${adres.id}`)}>
                    <td>
                      {adres.connections > 0
                        ? (
                            <>
                              <FontAwesomeIcon icon={faStar} />
                            </>
                          )
                        : <></>}
                    </td>
                    <td>{adres.street}</td>
                    <td>{adres.homeNumber}</td>
                    <td>{adres.homeLetter}</td>
                    <td>{adres.homeAddition}</td>
                    <td>{adres.zipcode}</td>
                    <td>{adres.city}</td>
                  </tr>
                )
              ))}
            </tbody>
            {/* Table pagination / result limiter */}
            <tfoot>
              <tr>
                <td colSpan={7}>
                  <div className="float-right">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        {/*

                        Previous button, disabled if offset is 0
                        -2 button, hidden if offset is 0 or 1, or oldData is not available
                        -1 button, hidden if offset is 0, or oldData is not available
                        Current page button, active
                        +1 button, hidden if offset is last page, or oldData is not available
                        +2 button, hidden if offset is last page or last page - 1, or oldData is not available
                        Next button, disabled if offset is last page, or oldData is not available
                      */}
                        <li className={`page-item ${offset === 0 ? 'disabled' : ''}`}>
                          <button className="page-link" onClick={() => setOffset(offset - 1)}>&laquo;</button>
                        </li>
                        <li className={`page-item ${offset === 0 || offset === 1 || !oldData ? 'd-none' : ''}`}>
                          <button className="page-link" onClick={() => setOffset(offset - 2)}>{offset - 1}</button>
                        </li>
                        <li className={`page-item ${offset === 0 || !oldData ? 'd-none' : ''}`}>
                          <button className="page-link" onClick={() => setOffset(offset - 1)}>{offset}</button>
                        </li>
                        <li className="page-item active">
                          <button className="page-link">{offset + 1}</button>
                        </li>
                        <li className={`page-item ${offset === Math.ceil(oldData?.total_rows / limit) - 1 || !oldData ? 'd-none' : ''}`}>
                          <button className="page-link" onClick={() => setOffset(offset + 1)}>{offset + 2}</button>
                        </li>
                        <li className={`page-item ${offset === Math.ceil(oldData?.total_rows / limit) - 1 || offset === Math.ceil(oldData?.total_rows / limit) - 2 || !oldData ? 'd-none' : ''}`}>
                          <button className="page-link" onClick={() => setOffset(offset + 2)}>{offset + 3}</button>
                        </li>
                        <li className={`page-item ${offset === Math.ceil(oldData?.total_rows / limit) - 1 || !oldData ? 'disabled' : ''}`}>
                          <button className="page-link" onClick={() => setOffset(offset + 1)}>&raquo;</button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="float-left">
                    <div className="form-group">
                      <label htmlFor="limit">Resultaten per pagina</label>
                      <select className="form-control" id="limit" onChange={onLimitChange} value={limit}>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    )
  )
}

export default BagAddresses
