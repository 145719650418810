import React from 'react'

function RecoverPassword() {
  return <></>

  // const {handleChange, values, handleSubmit, touched, errors} = useFormik({
  //   initialValues: {
  //     password: '',
  //     confirmPassword: ''
  //   },
  //   validationSchema: Yup.object({
  //     password: Yup.string()
  //       .min(5, 'Must be 5 characters or more')
  //       .max(30, 'Must be 30 characters or less')
  //       .required('Required'),
  //     confirmPassword: Yup.string()
  //       .min(5, 'Must be 5 characters or more')
  //       .max(30, 'Must be 30 characters or less')
  //       .required('Required')
  //       .when('password', {
  //         is: (val: string) => !!(val && val.length > 0),
  //         then: Yup.string().oneOf(
  //           [Yup.ref('password')],
  //           'Both password need to be the same'
  //         )
  //       })
  //   }),
  //   onSubmit: (values) => {
  //     toast.warn('Not yet functional');
  //     // eslint-disable-next-line no-console
  //     console.log(values);
  //   }
  // });

  // setWindowClass('hold-transition login-page');
  // return (
  //   <div className="login-box">
  //     <div className="card card-outline card-primary">
  //       <div className="card-header text-center">
  //         <Link to="/" className="h1">
  //           <b>B</b>
  //           <span>ONMI</span>
  //         </Link>
  //       </div>
  //       <div className="card-body">
  //         <p className="login-box-msg">Stel een nieuw wachtwoord in</p>
  //         <form onSubmit={handleSubmit}>
  //           <div className="mb-3">
  //             <InputGroup className="mb-3">
  //               <Form.Control
  //                 id="password"
  //                 name="password"
  //                 type="password"
  //                 placeholder="Password"
  //                 onChange={handleChange}
  //                 value={values.password}
  //                 isValid={touched.password && !errors.password}
  //                 isInvalid={touched.password && !!errors.password}
  //               />
  //               {touched.password && errors.password ? (
  //                 <Form.Control.Feedback type="invalid">
  //                   {errors.password}
  //                 </Form.Control.Feedback>
  //               ) : (
  //                 <InputGroup.Text>
  //                   <FontAwesomeIcon icon={faLock} />
  //                 </InputGroup.Text>
  //               )}
  //             </InputGroup>
  //           </div>
  //           <div className="mb-3">
  //             <InputGroup className="mb-3">
  //               <Form.Control
  //                 id="confirmPassword"
  //                 name="confirmPassword"
  //                 type="password"
  //                 placeholder="Confirm password"
  //                 onChange={handleChange}
  //                 value={values.confirmPassword}
  //                 isValid={touched.confirmPassword && !errors.confirmPassword}
  //                 isInvalid={
  //                   touched.confirmPassword && !!errors.confirmPassword
  //                 }
  //               />
  //               {touched.confirmPassword && errors.confirmPassword ? (
  //                 <Form.Control.Feedback type="invalid">
  //                   {errors.confirmPassword}
  //                 </Form.Control.Feedback>
  //               ) : (
  //                 <InputGroup.Text>
  //                   <FontAwesomeIcon icon={faLock} />
  //                 </InputGroup.Text>
  //               )}
  //             </InputGroup>
  //           </div>
  //           <div className="row">
  //             <div className="col-12">
  //               <Button type="submit" block>
  //                 Wachtwoord Veranderen
  //               </Button>
  //             </div>
  //           </div>
  //         </form>
  //         <p className="mt-3 mb-1">
  //           <Link to="/login">Terug naar Login</Link>
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default RecoverPassword
