import * as React from 'react'
import Select from 'react-select'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { selectTheme } from '../../../util/helpers'
import { DarkModeContext } from '../../../providers/DarkModeProvider'
import { getAccessToken } from '../../../services/auth.service'

enum VoipPackageType {
  MAATWERK = 'maatwerk',
  COMPLEET = 'compleet',
}

interface VoipCustomer {
  id: string
  packageType: VoipPackageType
  name: string
}

interface ApiResponse {
  total_rows: number
  total_pages: number
  page: number
  result: VoipCustomer[]
}

interface selectTypeVoipCustomer {
  value: string
  label: string
}

export function VoipKlantToevoegenModal({
  show,
  setShowModal,
}: {
  show: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [nameFilter, setNameFilter] = React.useState('')
  const [selectedBedrijf, setSelectedBedrijf] = React.useState<VoipCustomer | null>(null)
  const [selectedPhonebook, setSelectedPhonebook] = React.useState<boolean>(true)
  const [klanten, setKlanten] = React.useState<ApiResponse>({
    total_rows: 0,
    total_pages: 0,
    page: 0,
    result: [],
  })

  const [processing, setProcessing] = React.useState(false)

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const toggleShow = () => setShowModal(prev => !prev)

  const bedrijvenQuery = useQuery({
    queryKey: ['bedrijven', {
      nameFilter,
    }],

    queryFn: async () => {
      let queryString = '/api/voip/klanten?limit=5&managed=false'

      if (nameFilter) {
        queryString += `&naam=${encodeURIComponent(nameFilter)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (bedrijvenQuery.data) {
      setKlanten(bedrijvenQuery.data)
    }
  }, [bedrijvenQuery.data])

  const onSubmit = async () => {
    if (!selectedBedrijf) {
      return
    }

    setProcessing(true)

    const response = await fetch(`/api/voip/klanten/${selectedBedrijf.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        managed: true,
        phonebook: selectedPhonebook,
      }),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het toevoegen van de klant. Probeer het later opnieuw.')
      setProcessing(false)
      return
    }

    await queryClient.invalidateQueries({
      queryKey: ['voipCustomers'],
    })

    setProcessing(false)
    setNameFilter('')
    setSelectedBedrijf(null)
    setSelectedPhonebook(true)
    toggleShow()

    navigate(`/voip/klanten/${selectedBedrijf.id}`)
  }

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Provisioning klant toevoegen</Modal.Title>
        <FontAwesomeIcon icon={faTimes} />
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Naam</label>
          <Select
            options={klanten.result.map(klant => ({
              value: klant,
              label: klant.name,
            }))}
            noOptionsMessage={
              () => 'Geen bedrijven gevonden'
            }
            theme={selectStyle}
            placeholder="Zoek een bedrijf"
            isLoading={bedrijvenQuery.isLoading}
            onInputChange={value => setNameFilter(value)}
            onChange={value => setSelectedBedrijf(value?.value || null)}
            menuPlacement="auto"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phonebook" className="form-label">Telefoonboek</label>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="phonebook" checked={selectedPhonebook} onChange={() => setSelectedPhonebook(!selectedPhonebook)} />
            <label className="form-check-label" htmlFor="phonebook">Maak een telefoonboek aan voor dit account</label>
          </div>
        </div>
        {/* If packageType == compleet && selectedPhonebook == true display warning banner */}
        {selectedBedrijf?.packageType === VoipPackageType.COMPLEET && selectedPhonebook && (
          <div className="alert alert-warning" role="alert">
            <div className="row">
              <div className="col-1">
                <i
                  className="fas fa-2x fa-exclamation-triangle"
                  style={{
                    position: 'relative',
                    top: '50%',
                    transform: 'translatey(-50%)',
                  }}
                >
                </i>
              </div>
              <div
                className="col-11"
                style={{
                  paddingLeft: '20px',
                }}
              >
                <h3 className="">Waarschuwing</h3>
                <p
                  className=""
                  style={{
                    margin: '0',
                  // }}>Er is iets misgegaan bij het ophalen van de modems. Probeer het later opnieuw.</p>
                  }}
                >
                  Het activeren van het telefoonboek op Vialer Complete brengt kosten met zich mee. Neem contact op met VOIPGRID voor creditering van het gebruikersaccount.
                </p>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={toggleShow}>Annuleren</button>
        <button type="button" className="btn btn-primary" onClick={onSubmit}>Opslaan</button>
      </Modal.Footer>
      {processing && (
        <div className="overlay">
          <div className="spinner-border" role="status" />
          <span className="visually-hidden">Een moment geduld...</span>
        </div>
      )}
    </Modal>
  )
}
