import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { getAccessToken, logoutUser } from '../services/auth.service'
import LoadingScreen from '../components/LoadingScreen'

function PrivateRoute(props: any) {
  const [loading, setLoading] = React.useState(true)
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const token = getAccessToken()

  React.useEffect(() => {
    setLoading(true)
    const validateToken = async (token: string) => {
      const response = await fetch('/api/auth/validate', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      if (response.ok) {
        setIsLoggedIn(true)
      }
      else {
        if (response.status === 401) {
          logoutUser()
        }
        else {
          setIsError(true)
        }
      }
      setLoading(false)
    }

    if (token) {
      validateToken(token)
    }
    else {
      setLoading(false)
    }
  }, [token])

  return (
    <React.Fragment>
      {loading && <LoadingScreen loadingText="" />}
      {isError && <div>Fout! Er is een onbekende fout opgetreden. Probeer het later nog een keer.</div>}
      {!loading && !isLoggedIn && !isError && <Navigate to="/login" />}
      {!loading && isLoggedIn && <Outlet />}
    </React.Fragment>
  )
}

export default PrivateRoute
