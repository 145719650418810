import React, { useState } from 'react'
import Select from 'react-select'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap'
import { getAccessToken } from '../../../../services/auth.service'
import { DarkModeContext } from '../../../../providers/DarkModeProvider'
import { selectTheme } from '../../../../util/helpers'

interface DeviceModel {
  id: string
  deviceType: string
  deviceName: string
  vendor: string
  isPON: boolean
  isCPE: boolean
  defaultMonitoredPortIndex: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

interface ExistingBlock {
  id: string
  network: string
  netmask: number
  version: string
  name: string | null
  note: string | null
  autoSelect: boolean
  isPublic: boolean
  isInternal: boolean
  isManagement: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

interface CreateDeviceDto {
  deviceModel: string
  connectionId: string
  deviceName: string
  snmpCommunity?: string
  note?: string
  ipAddress?: {
    automatic?: boolean
    manual?: {
      create?: {
        address: string
        mask: number
      }
      existing?: {
        blockID: string
        address: string
      }
    }
  }
  serialNumber?: string
}

interface ExistingBlockSelectType {
  value: ExistingBlock
  label: string
}

const ipAddressOptions = [
  { value: 'automatic', label: 'Automagisch' },
  { value: 'manual_create', label: 'Handmatig: Maak nieuw blok' },
  { value: 'manual_existing', label: 'Handmatig: Gebruik bestaand blok' },
]

function generateRandomSnmpCommunity() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''

  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
  }

  return result
}

export function ModemToevoegenModal({
  aansluitingId,
  show,
  setShowModal,
}: {
  aansluitingId: string
  show: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [modemType, setModemType] = useState<DeviceModel>()
  const [modemName, setModemName] = useState('')
  const [serialNumber, setSerialNumber] = useState('')
  const [ipAddressType, setIpAddressType] = useState(ipAddressOptions[0].value)
  const [selectedBlock, setSelectedBlock] = useState<ExistingBlockSelectType>()
  const [hostAddress, setHostAddress] = useState('')
  const [subnetMask, setSubnetMask] = useState('')
  const [snmpCommunity, setSnmpCommunity] = useState(generateRandomSnmpCommunity())
  const [editName, setEditName] = useState(false)

  const queryClient = useQueryClient()

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const deviceModelQuery = useQuery({
    queryKey: ['deviceModel', aansluitingId],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devicemodels`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  const aansluitingQuery = useQuery({
    queryKey: ['aansluiting', aansluitingId],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/aansluitingen/${aansluitingId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      return await response.json()
    },
  })

  React.useEffect(() => {
    const aansluiting = aansluitingQuery.data

    let modemName = `CPE-${aansluiting.adres.zipcode}-${aansluiting.adres.homeNumber}`
    if (aansluiting.adres.homeLetter) {
      modemName += aansluiting.adres.homeLetter
    }
    if (aansluiting.adres.homeAddition) {
      modemName += `-${aansluiting.adres.homeAddition}`
    }

    setModemName(modemName)
  }, [aansluitingQuery.data])

  const existingBlocksQuery = useQuery({
    queryKey: ['existingBlocks', aansluitingId],
    enabled: ipAddressType === 'manual_existing',
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/ip/block?isManagement=true&autoselect=false&isPublic=false&limit=10000`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  const toggleShow = () => setShowModal(prev => !prev)

  const handleModemTypeChange = (selectedOption: any) => {
    const selectedModemType = selectedOption.value
    setModemType(selectedModemType)
    setSerialNumber('')
    setIpAddressType(ipAddressOptions[0].value)
    setSelectedBlock(undefined)
    setHostAddress('')
    setSubnetMask('')
  }

  const handleModemNameChange = (event: any) => {
    setModemName(event.target.value)
  }

  const handleSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(event.target.value)
  }

  const handleIpAddressTypeChange = (selectedOption: any) => {
    const selectedIpAddressType = selectedOption.value
    setIpAddressType(selectedIpAddressType)
    setSelectedBlock(undefined)
    setHostAddress('')
    setSubnetMask('')
  }

  const handleBlockChange = (selectedOption: any) => {
    setSelectedBlock(selectedOption)
    setHostAddress('')
    setSubnetMask('')
  }

  const handleHostAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHostAddress(event.target.value)
  }

  const handleSubnetMaskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubnetMask(event.target.value)
  }

  const handleSnmpCommunityChange = (event: any) => {
    setSnmpCommunity(event.target.value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!modemType || !modemName) {
      return
    }

    if (modemType.deviceType !== 'NOCPE') {
      if (!modemType.isPON && !snmpCommunity && !serialNumber) {
        return
      }

      if (modemType.isPON && !serialNumber) {
        return
      }

      if (ipAddressType === 'manual_create' && (!hostAddress || !subnetMask)) {
        return
      }

      if (ipAddressType === 'manual_existing' && (!selectedBlock || !hostAddress)) {
        return
      }
    }

    const postObject: CreateDeviceDto = {
      deviceModel: modemType.id,
      connectionId: aansluitingId,
      deviceName: modemName,
    }

    if (!modemType.isPON && modemType.deviceType !== 'NOCPE') {
      postObject.serialNumber = serialNumber
      postObject.snmpCommunity = snmpCommunity

      if (ipAddressType === 'automatic') {
        postObject.ipAddress = {
          automatic: true,
        }
      }

      if (ipAddressType === 'manual_create') {
        postObject.ipAddress = {
          manual: {
            create: {
              address: hostAddress,
              mask: Number.parseInt(subnetMask),
            },
          },
        }
      }

      console.log(selectedBlock)
      if (ipAddressType === 'manual_existing') {
        if (!selectedBlock)
          return // Already checking upstairs but typescript is complaining
        postObject.ipAddress = {
          manual: {
            existing: {
              blockID: selectedBlock.value.id,
              address: hostAddress,
            },
          },
        }
      }
    }

    if (modemType.isPON) {
      postObject.serialNumber = serialNumber
    }

    const response = await fetch(`/api/netwerk/devices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(postObject),
    })

    if (!response.ok) {
      const errorData = await response.json()
      toast.error('Er is iets misgegaan bij het opslaan van de verbinding')
      toast.error(`${errorData.message}`)
      console.log(response)
      throw new Error(response.statusText)
    }
    else {
      toast.success('Modem is succesvol toegevoegd!')
      queryClient.invalidateQueries({
        queryKey: ['modems', aansluitingId],
      })
      toggleShow()
    }
  }

  let modemTypeOptions = []
  let blockOptions = []

  if (deviceModelQuery.data && deviceModelQuery.data.length > 0) {
    modemTypeOptions = deviceModelQuery.data.map((modem: DeviceModel) => ({
      value: modem,
      label: `${modem.vendor} ${modem.deviceName}`,
    }))
  }

  if (existingBlocksQuery.data && existingBlocksQuery.data.result.length > 0) {
    blockOptions = existingBlocksQuery.data.result.map((block: ExistingBlock) => ({
      value: block,
      label: `${block.network}/${block.netmask}`,
    }))
  }

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Modem Toevoegen</Modal.Title>
        <button className="btn btn-close" color="none" onClick={toggleShow}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>

          <div className="form-group">
            <label htmlFor="modemType">Modem Model</label>
            <Select
              id="modemType"
              options={modemTypeOptions}
              value={modemType ? { value: modemType.id, label: `${modemType.vendor} ${modemType.deviceName}` } : null}
              onChange={handleModemTypeChange}
              theme={selectStyle}
            />
          </div>
          <div className="form-group">
            <label htmlFor="modemName">Modem Naam</label>
            <div className="input-group">
              <input
                type="text"
                id="modemName"
                className="form-control"
                value={modemName}
                onChange={handleModemNameChange}
                disabled={!editName}
              />
              {!editName && (
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setEditName(true)}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </button>
                </div>
              )}
            </div>
          </div>
          {modemType && modemType.deviceType !== 'NOCPE' && modemType.isPON && (
            <div className="form-group">
              <label htmlFor="serialNumber">Serienummer</label>
              <input type="text" id="serialNumber" className="form-control" value={serialNumber} onChange={handleSerialNumberChange} />
            </div>
          )}
          {modemType && modemType.deviceType !== 'NOCPE' && !modemType.isPON && (
            <div>
              <div className="form-group">
                <label>Serienummer</label>
                <input type="text" className="form-control" value={serialNumber} onChange={handleSerialNumberChange} />
              </div>
              <div className="form-group">
                <label htmlFor="snmpCommunity">SNMP Community</label>
                <input
                  type="text"
                  id="snmpCommunity"
                  className="form-control"
                  value={snmpCommunity}
                  onChange={handleSnmpCommunityChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ipAddressType">IP Address</label>
                <Select
                  id="ipAddressType"
                  options={ipAddressOptions}
                  value={ipAddressOptions.find(option => option.value === ipAddressType)}
                  onChange={handleIpAddressTypeChange}
                  theme={selectStyle}
                />
              </div>
              {ipAddressType === 'manual_create' && (
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="hostAddress">Host Adres</label>
                    <input type="text" id="hostAddress" className="form-control" value={hostAddress} onChange={handleHostAddressChange} />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="subnetMask">Subnet Mask</label>
                    <input type="text" id="subnetMask" className="form-control" value={subnetMask} onChange={handleSubnetMaskChange} />
                  </div>
                </div>
              )}
              {ipAddressType === 'manual_existing' && (
                <div className="form-group">
                  <label>Selecteer Blok</label>
                  <Select
                    options={blockOptions}
                    value={selectedBlock}
                    onChange={handleBlockChange}
                    isSearchable
                    theme={selectStyle}
                  />
                </div>
              )}
              {ipAddressType === 'manual_existing' && selectedBlock && (
                <div className="form-group">
                  <label htmlFor="hostAddress">Host Adres</label>
                  <input type="text" id="hostAddress" className="form-control" value={hostAddress} onChange={handleHostAddressChange} />
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">Submit</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
