import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlus, faRecycle } from '@fortawesome/free-solid-svg-icons'
import { getAccessToken } from '../../../services/auth.service'

export function RouterProvisioning() {
  const navigate = useNavigate()

  const { isLoading, error, data } = useQuery({
    queryKey: ['provisioning/router'],

    queryFn: async () => {
      const response = await fetch('/api/provisioning/router', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  const queryClient = useQueryClient()

  if (isLoading)
    return <div>Loading...</div>

  console.log(isLoading, error)

  if (error)
    return <div>Unable to load data...</div>

  console.log(data)

  return (
    (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            Router Provisioning
          </h3>
          <div className="card-tools">
            {/* Refresh button */}
            <button
              type="button"
              className="btn btn-primary m-1"
              onClick={() => queryClient.invalidateQueries({
                queryKey: ['provisioning/router'],
              })}
            >
              <FontAwesomeIcon icon={faRecycle} />
            </button>
            {/* Add button */}
            <button type="button" className="btn btn-primary m-1" onClick={() => navigate('/provisioning/routers/toevoegen')}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        <div className="card-body table-responsive p-0">
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>Serienummer</th>
                <th>Model</th>
                <th>Wifi SSID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data[0].map((router: any) => (
                <tr key={router.id} onDoubleClick={() => navigate(`/provisioning/routers/${router.id}`)}>
                  <td>{router.serialNumber}</td>
                  <td>{router.model}</td>
                  <td>{router.wifiSSID}</td>
                  <td>{router.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  )
}

export default RouterProvisioning
