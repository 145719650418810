import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface Project {
  id: string
  name: string
  description: string
  usergroup: {
    id: string
    name: string
  }
  active: string
  created_at: string
  updated_at: string
  children: number
  connections: number
}

interface ApiResponse {
  total_rows: number
  total_pages: number
  page: number
  result: Project[]
}

export function Projects() {
  const [nameFilter, setNameFilter] = React.useState('')
  const [limit, setLimit] = React.useState(25)
  const [offset, setOffset] = React.useState(0)
  const [oldData, setOldData] = React.useState<ApiResponse>({
    total_rows: 0,
    total_pages: 0,
    page: 0,
    result: [],
  })

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { isLoading, error, data } = useQuery({
    queryKey: ['Projects', {
      nameFilter,
      limit,
      offset,
    }],

    queryFn: async () => {
      console.log(limit)
      let queryString = `/api/projects?limit=${limit}&page=${offset}`

      if (nameFilter) {
        queryString += `&naam=${encodeURIComponent(nameFilter)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }),
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  const onLimitChange = (event: { target: { value: string } }) => {
    setLimit(Number.parseInt(event.target.value))

    // Calculate current index
    const currentIndex = offset * limit

    // Calculate new offset
    const newOffset = Math.floor(currentIndex / Number.parseInt(event.target.value))

    setOffset(newOffset)
  }

  useEffect(() => {
    if (data) {
      setOldData(data)
    }
  }, [data])

  // Reset pagination when filters change
  useEffect(() => {
    setOffset(0)
  }, [nameFilter])

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">
          <h3>Projecten</h3>
        </div>
        <div className="card-tools">
          <button className="btn btn-primary" onClick={() => navigate('/projecten/toevoegen')}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
      <div className="card-body table-responsive p-0">
        <table className="table table-hover text-nowrap table-striped">
          <thead>
            <tr>
              <th>Projectnaam</th>
              <th>Project Eigenaar</th>
              <th>Aantal Subprojecten</th>
              <th>Aantal Aansluitingen</th>
            </tr>
          </thead>
          <tbody>
            {/* Filter fields */}
            <tr>
              <td>
                <input type="text" className="form-control" value={nameFilter} onChange={event => setNameFilter(event.target.value)} />
              </td>
              <td></td>
              <td></td>
            </tr>
            {/* Data */}
            {/* If the page is loading and has no data / old data */}
            {isLoading && !data && !oldData && (
              <tr>
                <td colSpan={7}>Loading...</td>
              </tr>
            )}
            {/* If error */}
            {error && (
              <tr>
                <td colSpan={7}>Error</td>
              </tr>
            )}
            {/* If no data */}
            {!isLoading && !error && oldData && oldData.result.length === 0 && (
              <tr>
                <td colSpan={7}>No data</td>
              </tr>
            )}
            {/* If data but loading, show rows greyed out */}
            {isLoading && !error && oldData && oldData.result.length > 0 && (
              oldData.result.map((project: Project) => (
                <tr key={project.id}>
                  <td>{project.name}</td>
                  <td>{project.usergroup.name}</td>
                  <td>{project.children}</td>
                  <td>{project.connections}</td>
                </tr>
              ))
            )}

            {/* If data */}
            {!isLoading && !error && data && data.result.map((project: Project) => (
              <React.Fragment key={project.id}>
                <tr key={project.id} onDoubleClick={() => navigate(`/projecten/${project.id}`)}>
                  <td>{project.name}</td>
                  <td>{project.usergroup.name}</td>
                  <td>{project.children}</td>
                  <td>{project.connections}</td>
                </tr>
              </React.Fragment>
            ))}

          </tbody>
          {/* Table pagination / result limiter */}
          <tfoot>
            <tr>
              <td colSpan={7}>
                <div className="float-right">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {/*

                        Previous button, disabled if offset is 0
                        -2 button, hidden if offset is 0 or 1, or oldData is not available
                        -1 button, hidden if offset is 0, or oldData is not available
                        Current page button, active
                        +1 button, hidden if offset is last page, or oldData is not available
                        +2 button, hidden if offset is last page or last page - 1, or oldData is not available
                        Next button, disabled if offset is last page, or oldData is not available
                      */}
                      <li className={`page-item ${offset === 0 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setOffset(offset - 1)}>&laquo;</button>
                      </li>
                      <li className={`page-item ${offset === 0 || offset === 1 || !oldData ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setOffset(offset - 2)}>{offset - 1}</button>
                      </li>
                      <li className={`page-item ${offset === 0 || !oldData ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setOffset(offset - 1)}>{offset}</button>
                      </li>
                      <li className="page-item active">
                        <button className="page-link">{offset + 1}</button>
                      </li>
                      <li className={`page-item ${offset === Math.ceil(oldData?.total_rows / limit) - 1 || !oldData ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setOffset(offset + 1)}>{offset + 2}</button>
                      </li>
                      <li className={`page-item ${offset === Math.ceil(oldData?.total_rows / limit) - 1 || offset === Math.ceil(oldData?.total_rows / limit) - 2 || !oldData ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setOffset(offset + 2)}>{offset + 3}</button>
                      </li>
                      <li className={`page-item ${offset === Math.ceil(oldData?.total_rows / limit) - 1 || !oldData ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setOffset(offset + 1)}>&raquo;</button>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="float-left">
                  <div className="form-group">
                    <label htmlFor="limit">Resultaten per pagina</label>
                    <select className="form-control" id="limit" onChange={onLimitChange} value={limit}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {/* if loading, overlay */}
      {isLoading && (
        <div className="overlay">
          <i className="fas fa-2x fa-sync-alt fa-spin" />
        </div>
      )}
    </div>
  )
}

export default Projects
