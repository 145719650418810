import React, { useRef } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import Select, { createFilter } from 'react-select'
import Creatable from 'react-select/creatable'
import { toast } from 'react-toastify'
import { selectTheme } from '../../../util/helpers'
import { DarkModeContext } from '../../../providers/DarkModeProvider'
import { getAccessToken } from '../../../services/auth.service'

interface adres {
  id: string
  street: string
  homeNumber: string
  homeLetter: string
  homeAddition: string
  zipcode: string
  city: string
  connections: string
}

interface selectType {
  value: string
  label: string
}

const speedOptions = [
  { value: '-5', label: 'Cable Only' },
  { value: '-1', label: 'FTU Only' },
  { value: '0', label: 'Inactief' },
  { value: '2', label: '2 Mbit/s (Alarm)' },
  { value: '25', label: '25 Mbit/s' },
  { value: '50', label: '50 Mbit/s' },
  { value: '100', label: '100 Mbit/s' },
  { value: '200', label: '200 Mbit/s' },
  { value: '250', label: '250 Mbit/s' },
  { value: '500', label: '500 Mbit/s' },
  { value: '1000', label: '1000 Mbit/s' },
  { value: '-4', label: 'DWDM Wave' },
  { value: '-3', label: 'CWDM Wave' },
  { value: '-2', label: 'Dark Fiber' },
]

const typeOptions = [
  { value: 'fiber', label: 'Glasvezel' },
  { value: 'air', label: 'Straalzender' },
  { value: 'vdsl', label: 'VDSL' },
  { value: 'starlink', label: 'Starlink' },
]

interface aansluiting {
  adres: adres
  speed: string
  company: selectType
  created: boolean
  error: boolean
}

export function BulkAansluitingToevoegen() {
  const [workflows, setWorkflows] = React.useState<selectType[]>([])
  const [projecten, setProjecten] = React.useState([])

  const [selectedProject, setSelectedProject] = React.useState<selectType | null>(null)
  const [selectedWorkflow, setSelectedWorkflow] = React.useState<selectType | null>(null)
  const [selectedType, setSelectedType] = React.useState('')

  const [projectSearch, setProjectSearch] = React.useState('')
  const [workflowSearch, setWorkflowSearch] = React.useState('')

  const contentWrapperRef = useRef(null)

  const [aansluitingen, setAansluitingen] = React.useState<aansluiting[]>([{
    adres: {
      id: '',
      street: '',
      homeNumber: '',
      homeLetter: '',
      homeAddition: '',
      zipcode: '',
      city: '',
      connections: '',
    },
    speed: '-1',
    company: {
      value: '519716000004318003',
      label: '--',
    },
    created: false,
    error: false,
  }])

  const [ready, setReady] = React.useState(false)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const projectenQuery = useQuery({
    queryKey: ['projecten', {
      projectSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/projects?limit=5'

      if (projectSearch) {
        queryString += `&naam=${encodeURIComponent(projectSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (projectenQuery.data) {
      setProjecten(projectenQuery.data.result.map((project: any) => {
        return {
          label: `${project.usergroup.name} | ${project.name}`,
          value: project.id,
        }
      }))
    }
  }, [projectenQuery.data])

  const workflowQuery = useQuery({
    queryKey: ['workflow', {
      workflowSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/connections/workflow?limit=5'

      if (workflowSearch) {
        queryString += `&naam=${encodeURIComponent(workflowSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (workflowQuery.data) {
      setWorkflows(workflowQuery.data.map((workflow: any) => {
        return {
          label: workflow.name,
          value: workflow.id,
        }
      }))
    }
  }, [workflowQuery.data])

  React.useEffect(() => {
    let ready = true
    if (!selectedProject || !selectedWorkflow || !selectedType) {
      ready = false
    }
    aansluitingen.forEach((aansluiting) => {
      if (!aansluiting.adres.id || !aansluiting.speed || !aansluiting.company.value) {
        ready = false
      }
    })
    setReady(ready)
  }, [aansluitingen, selectedProject, selectedWorkflow, selectedType])

  const submitAansluitingen = async () => {
    if (!ready) {
      toast.error('Niet alle velden zijn ingevuld.')
      return
    }

    if (!selectedProject || !selectedWorkflow || !selectedType) {
      toast.error('Niet alle velden zijn ingevuld.')
      return
    }

    // Async for each
    for (let i = 0; i < aansluitingen.length; i++) {
      const aansluiting = aansluitingen[i]
      if (aansluiting.created) {
        toast.info(`Aansluiting ${i + 1} is al toegevoegd.`)
        continue
      }

      const response = await fetch('/api/netwerk/aansluitingen', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          bag_num_id: aansluiting.adres.id,
          project_id: selectedProject.value,
          company_id: aansluiting.company.value,
          workflow_id: selectedWorkflow.value,
          type: selectedType,
          speed: aansluiting.speed,
        }),
      })

      if (!response.ok) {
        toast.error(`Fout bij het toevoegen van aansluiting ${i + 1}.`)
        setAansluitingen((aansluitingen) => {
          const newAansluitingen = [...aansluitingen]
          newAansluitingen[i].error = true
          return newAansluitingen
        })
      }
      else {
        setAansluitingen((aansluitingen) => {
          const newAansluitingen = [...aansluitingen]
          newAansluitingen[i].created = true
          newAansluitingen[i].error = false
          return newAansluitingen
        })
      }
    }

    toast.success('Aansluitingen toegevoegd.')
  }

  return (
    <div
      ref={contentWrapperRef}
    >
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Bulk aansluitingen toevoegen</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Project</label>
                <Select
                  options={projecten}
                  theme={selectStyle}
                  value={selectedProject}
                  onChange={(e: any) => {
                    setSelectedProject(e)
                  }}
                  onInputChange={(e: any) => {
                    setProjectSearch(e)
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Workflow</label>
                <Select
                  options={workflows}
                  theme={selectStyle}
                  value={selectedWorkflow}
                  onChange={(e: any) => {
                    setSelectedWorkflow(e)
                  }}
                  onInputChange={(e: any) => {
                    setWorkflowSearch(e)
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Type</label>
                <Select
                  options={typeOptions}
                  theme={selectStyle}
                  value={typeOptions.find(option => option.value === selectedType)}
                  onChange={(e: any) => {
                    setSelectedType(e.value)
                  }}
                />
              </div>
            </div>
          </div>

          {/* Seperator */}
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>

          {/* Input Table */}
          <div className="card-body table-responsive p-0 table-condensed">
            <table className="table table-hover text-nowrap table-striped">
              <thead>
                <tr>
                  <th>
                    Aantal rijen
                    {' '}
                    {aansluitingen.length}
                    {' '}
&nbsp;
                    <button
                      className="btn btn-primary btn-xs"
                      onClick={() => {
                        setAansluitingen([
                          ...aansluitingen,
                          {
                            adres: {
                              id: '',
                              street: '',
                              homeNumber: '',
                              homeLetter: '',
                              homeAddition: '',
                              zipcode: '',
                              city: '',
                              connections: '',
                            },
                            speed: '-1',
                            company: {
                              value: '519716000004318003',
                              label: '--',
                            },
                            created: false,
                            error: false,
                          },
                        ])
                      }}
                    >
                      <i className="fas fa-plus">&nbsp;Toevoegen</i>
                    </button>
                  </th>
                  <th>Postcode</th>
                  <th>Huisnummer</th>
                  <th>Toevoeging</th>
                  <th>Bedrijf</th>
                  <th>Snelheid</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {aansluitingen.map((aansluiting, index) => (
                  <BulkAansluitingRow
                    key={index}
                    index={index}
                    aansluiting={aansluiting}
                    setAansluiting={setAansluitingen}
                    contentWrapperRef={contentWrapperRef}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <div className="float-right">
            <button
              className="btn btn-primary"
              disabled={!ready}
              onClick={() => {
                submitAansluitingen()
              }}
            >
              <i className="fas fa-save"></i>
              {' '}
              Opslaan
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function BulkAansluitingRow({
  index,
  aansluiting,
  setAansluiting,
  contentWrapperRef,
}: {
  index: number
  aansluiting: aansluiting
  setAansluiting: React.Dispatch<React.SetStateAction<aansluiting[]>>
  contentWrapperRef: any
}) {
  const [toevoegingOptions, setToevoegingOptions] = React.useState<selectType[]>([])

  const [bedrijven, setBedrijven] = React.useState([])
  const [bedrijfSearch, setBedrijfSearch] = React.useState('')

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const adresQuery = useQuery({
    queryKey: ['adres', {
      aansluiting,
    }],

    queryFn: async () => {
      let queryString = `/api/bag/addresses?limit=100`

      if (aansluiting.adres.zipcode) {
        queryString += `&zipcode=${encodeURIComponent(aansluiting.adres.zipcode)}`
      }

      if (aansluiting.adres.homeNumber) {
        queryString += `&homeNumber=${encodeURIComponent(aansluiting.adres.homeNumber)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (adresQuery.data) {
      const toevoegingen: selectType[] = []

      adresQuery.data.result.forEach((adres: adres) => {
        // If number is not an exact match
        if (adres.homeNumber == aansluiting.adres.homeNumber) {
          if (!adres.homeAddition && !adres.homeLetter) {
            toevoegingen.push({
              value: adres.id,
              label: 'Geen toevoeging',
            } as selectType)
          }
          if (adres.homeAddition && !adres.homeLetter) {
            toevoegingen.push({
              value: adres.id,
              label: adres.homeAddition,
            } as selectType)
          }
          if (adres.homeLetter && !adres.homeAddition) {
            toevoegingen.push({
              value: adres.id,
              label: adres.homeLetter,
            } as selectType)
          }
          if (adres.homeLetter && adres.homeAddition) {
            toevoegingen.push({
              value: adres.id,
              label: `${adres.homeLetter}-${adres.homeAddition}`,
            } as selectType)
          }
        }
      })

      setToevoegingOptions(toevoegingen)
    }

    // FIX: Race condition; stop if search parameters have changed
    if (aansluiting.adres.homeNumber != adresQuery.data?.result[0]?.homeNumber || aansluiting.adres.zipcode != adresQuery.data?.result[0]?.zipcode) {
      return
    }

    // If length is 1, set the address
    if (adresQuery.data && adresQuery.data.result.length === 1) {
      setAansluiting((aansluitingen) => {
        const newAansluitingen = [...aansluitingen]
        newAansluitingen[index].adres = adresQuery.data.result[0]
        return newAansluitingen
      })
    }
    // If length is > 1, and no additions are available and postcode is fully filled in, Find the exact match and set the address
    if (adresQuery.data && adresQuery.data.result.length > 1 && toevoegingOptions.length === 1) {
      const exactMatch = adresQuery.data.result.find((adres: adres) => {
        return (adres.homeNumber == aansluiting.adres.homeNumber && !adres.homeAddition && !adres.homeLetter)
      })
      if (exactMatch) {
        setAansluiting((aansluitingen) => {
          const newAansluitingen = [...aansluitingen]
          newAansluitingen[index].adres = exactMatch
          return newAansluitingen
        })
      }
    }

    // If length is > 1, additions are available, but not set and postcode is fully filled in, Find the exact match and set the address
    if (adresQuery.data && adresQuery.data.result.length > 1 && toevoegingOptions.length > 0 && !(aansluiting.adres.homeAddition || aansluiting.adres.homeLetter)) {
      const exactMatch = adresQuery.data.result.find((adres: adres) => {
        return (adres.homeNumber == aansluiting.adres.homeNumber && !adres.homeAddition && !adres.homeLetter)
      })
      if (exactMatch) {
        setAansluiting((aansluitingen) => {
          const newAansluitingen = [...aansluitingen]
          newAansluitingen[index].adres = exactMatch
          return newAansluitingen
        })
      }
    }
  }, [adresQuery.data, toevoegingOptions.length])

  const bedrijvenQuery = useQuery({
    queryKey: ['bedrijven', {
      bedrijfSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/crm/companies?limit=50'

      if (bedrijfSearch) {
        queryString += `&naam=${encodeURIComponent(bedrijfSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (bedrijvenQuery.data) {
      setBedrijven(bedrijvenQuery.data.data.map((bedrijf: any) => {
        return {
          label: bedrijf.Account_Name,
          value: bedrijf.id,
        } as selectType
      }))
    }
  }, [bedrijvenQuery.data])

  let toevoegingLabel = {}

  if (aansluiting.adres.id && toevoegingOptions.length > 0) {
    toevoegingLabel = toevoegingOptions.find(option => option.value === aansluiting.adres.id) || {}
  }

  return (
    <>
      <tr style={{
        backgroundColor: aansluiting.created ? '#28a745' : aansluiting.error ? '#dc3545' : '',
      }}
      >
        <td>
          {aansluiting.adres.id && (
            <>
              {Number(aansluiting.adres.connections) > 0 && (
                <div
                  className="alert alert-warning alert-sm"
                  style={{
                    marginBottom: '0px',
                    height: '38px',
                    padding: '6px',
                  }}
                >
                  <i className="fas fa-exclamation-triangle"></i>
                  {' '}
                  {aansluiting.adres.street}
                  {' '}
                  {aansluiting.adres.homeNumber}
                  {aansluiting.adres.homeLetter}
                  {aansluiting.adres.homeAddition ? `-${aansluiting.adres.homeAddition}` : ``}
                  ,
                  {' '}
                  {aansluiting.adres.zipcode}
                  {' '}
                  {aansluiting.adres.city}
                  {' '}
                  (
                  {aansluiting.adres.connections}
                  {' '}
                  aansluitingen)
                </div>
              ) || (
                <div
                  className="alert alert-success alert-sm"
                  style={{
                    marginBottom: '0px',
                    height: '38px',
                    padding: '6px',
                  }}
                >
                  <i className="fas fa-check"></i>
                  {' '}
                  {aansluiting.adres.street}
                  {' '}
                  {aansluiting.adres.homeNumber}
                  {aansluiting.adres.homeLetter}
                  {aansluiting.adres.homeAddition ? `-${aansluiting.adres.homeAddition}` : ``}
                  ,
                  {' '}
                  {aansluiting.adres.zipcode}
                  {' '}
                  {aansluiting.adres.city}
                </div>
              )}
            </>
          ) || (
            <div
              className="alert alert-danger alert-sm"
              style={{
                marginBottom: '0px',
                height: '38px',
                padding: '6px',
              }}
            >
              <i className="fas fa-times"></i>
              {' '}
              Geen adres gevonden
            </div>
          )}
        </td>
        <td>
          <div
            className="form-group"
            style={{
              marginBottom: 0,
            }}
          >
            <input
              type="text"
              className="form-control"
              value={aansluiting.adres.zipcode}
              onChange={(e) => {
                setAansluiting((aansluitingen) => {
                  const newAansluitingen = [...aansluitingen]
                  newAansluitingen[index].adres = {
                    id: '',
                    street: '',
                    homeNumber: newAansluitingen[index].adres.homeNumber,
                    homeLetter: '',
                    homeAddition: '',
                    zipcode: e.target.value.replace(/\s/g, '').toUpperCase(),
                    city: '',
                    connections: '',
                  }
                  return newAansluitingen
                })
              }}
            />
          </div>
        </td>
        <td>
          <div
            className="form-group"
            style={{
              marginBottom: 0,
            }}
          >
            <input
              type="text"
              className="form-control"
              value={aansluiting.adres.homeNumber}
              onChange={(e) => {
                setAansluiting((aansluitingen) => {
                  const newAansluitingen = [...aansluitingen]
                  newAansluitingen[index].adres = {
                    id: '',
                    street: '',
                    homeNumber: e.target.value,
                    homeLetter: '',
                    homeAddition: '',
                    zipcode: newAansluitingen[index].adres.zipcode,
                    city: '',
                    connections: '',
                  }
                  return newAansluitingen
                })
              }}
            />
          </div>
        </td>
        <td>
          <div
            className="form-group"
            style={{
              marginBottom: 0,
            }}
          >
            <Select
              options={toevoegingOptions}
              filterOption={createFilter({
                matchFrom: 'any',
                stringify: option => `${option.label}`,
              })}
              theme={selectStyle}
              menuPlacement="auto"
              value={toevoegingLabel}
              onChange={(e: any) => {
                setAansluiting((aansluitingen) => {
                  const newAansluitingen = [...aansluitingen]
                  newAansluitingen[index].adres = adresQuery.data?.result.find((adres: adres) => adres.id == e.value)
                  return newAansluitingen
                })
              }}
              menuPortalTarget={contentWrapperRef.current}
            />
          </div>
        </td>
        <td>
          <div
            className="form-group"
            style={{
              marginBottom: 0,
            }}
          >
            <Select
              options={bedrijven}
              theme={selectStyle}
              value={aansluiting.company}
              menuPlacement="auto"
              onChange={(e: any) => {
                setAansluiting((aansluitingen) => {
                  const newAansluitingen = [...aansluitingen]
                  newAansluitingen[index].company = e
                  return newAansluitingen
                })
              }}
              onInputChange={(e: any) => {
                setBedrijfSearch(e)
              }}
              menuPortalTarget={contentWrapperRef.current}
            />
          </div>
        </td>
        <td>
          <div
            className="form-group"
            style={{
              marginBottom: 0,
              zIndex: 0,
            }}
          >
            <Creatable
              theme={selectStyle}
              options={speedOptions}
              value={speedOptions.filter(option => option.value === aansluiting.speed)}
              onChange={(value) => {
                setAansluiting((aansluitingen) => {
                  const newAansluitingen = [...aansluitingen]
                  newAansluitingen[index].speed = value ? value.value : ''
                  return newAansluitingen
                })
              }}
              onCreateOption={(value) => {
                speedOptions.push({ label: `Anders: ${value} Mbit/s`, value })
                setAansluiting((aansluitingen) => {
                  const newAansluitingen = [...aansluitingen]
                  newAansluitingen[index].speed = value
                  return newAansluitingen
                })
              }}
              placeholder="Selecteer een snelheid"
              formatCreateLabel={value => `Anders: ${value} Mbit/s`}
              menuPlacement="auto"
              createOptionPosition="first"
              menuPortalTarget={contentWrapperRef.current}
            />
          </div>
        </td>
        <td>
          <div
            className="form-group"
            style={{
              marginBottom: 0,
            }}
          >
            <button
              className="btn btn-danger"
            >
              <i
                className="fas fa-trash"
                onClick={() => {
                  setAansluiting((aansluitingen) => {
                    const updatedAansluitingen = [...aansluitingen]
                    updatedAansluitingen.splice(index, 1)
                    return updatedAansluitingen
                  })
                }}
              >
              </i>
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}
