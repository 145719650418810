import * as React from 'react'
import { Card, Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export function AansluitingDeleteModal({
  showModal,
  setShowModal,
}: {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { id } = useParams()
  const [permanentDelete, setPermanentDelete] = React.useState(false)

  const handleDelete = async () => {
    toast.info('Not yet implemented')
  }

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Verwijder aansluiting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Weet je zeker dat je deze aansluiting wilt verwijderen?</p>
        <p>
          Verwijderen van een aansluiting is alleen bedoeld voor aansluitingen geleverd door een 3e partij. Bijvoorbeeld Eurofiber of ODIDO. Aansluitingen die door ons geleverd zijn moeten inactief gemaakt worden i.p.v. verwijderd.
        </p>
        <p>
          Het verwijderen van een aansluiting beinvloed enkel de zichtbaarheid en heeft geen effect op de status van gekoppelde modems, evc&apos;s e.d.
        </p>
        <Card bg="danger">
          <Card.Body>
            <Card.Title>Permanent Verwijderen</Card.Title>
            <Card.Text className="pl-3 pt-3">
              <p>
                Let op: Permanent verwijderen is alleen bedoeld voor dubbele en/of fout ingevoerde aansluitingen. Er mogen geen gegevens aan deze aansluiting gekoppeld zijn.
              </p>
              <input
                type="checkbox"
                checked={permanentDelete}
                onChange={e => setPermanentDelete(e.target.checked)}
                className="form-check-input"
              />
              <label className="form-check-label" onClick={() => { setPermanentDelete((old) => { return !old }) }}>
                Aansluiting permanent verwijderen
              </label>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => setShowModal(false)}
        >
          Annuleren
        </button>
        <button
          className="btn btn-danger"
          onClick={handleDelete}
        >
          Verwijderen
        </button>
      </Modal.Footer>
    </Modal>
  )
}
