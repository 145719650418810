import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import proj4 from 'proj4'
import type { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { useNavigate } from 'react-router-dom'
import { Layer, Source, useMap } from 'react-map-gl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNodes } from '@fortawesome/pro-solid-svg-icons'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { getAccessToken } from '../../services/auth.service'
import { epsg28992Projection } from './constants'

import faMarkerIcon from './marker-icon.png'
import faMarkerShadow from './marker-shadow.png'

export interface ConnectionPoint {
  id: string
  geometry: string
}

// Define EPSG:28992 (RD Coordinates) Rijksdriehoekstelsel
proj4.defs('EPSG:28992', epsg28992Projection)

export function ConnectionsLayer() {
  const [connections, setConnections] = useState<ConnectionPoint[]>([])
  const [selectedPoint, setSelectedPoint] = useState(null)
  const [isClusteringEnabled, setIsClusteringEnabled] = useState(true)

  const isMobile = window.innerWidth <= 768

  const navigate = useNavigate()
  const map = useMap()

  const connectionsQuery = useQuery({
    queryKey: ['map', 'getAansluitingenPoints'],

    queryFn: async () => {
      const response = await fetch(`/api/map/getAansluitingenPoints`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json() as ConnectionPoint[]
    },
  })

  useEffect(() => {
    if (connectionsQuery.data) {
      setConnections(connectionsQuery.data)
    }
  }, [connectionsQuery.data])

  const connectionsGeoJson = {
    type: 'FeatureCollection',
    features: connections.map((connection) => {
      const coordinates = connection.geometry.replace('POINT(', '').replace(')', '').split(' ')
      const [x, y] = [Number.parseFloat(coordinates[0]), Number.parseFloat(coordinates[1])]
      const [longitude, latitude] = proj4('EPSG:28992', 'WGS84', [x, y + 0.2])

      return {
        type: 'Feature',
        properties: {
          id: connection.id,
          // Include other properties you might need
        },
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      }
    }) || [],
  }

  useEffect(() => {
    if (map && map.current) {
      map.current.loadImage(faMarkerIcon, (error, image) => {
        if (error)
          throw error
        if (!map.current)
          return
        if (image && !map.current.hasImage('marker-icon')) {
          map.current.addImage('marker-icon', image)
        }
      })
      map.current.loadImage(faMarkerShadow, (error, image) => {
        if (error)
          throw error
        if (!map.current)
          return
        if (image && !map.current.hasImage('marker-shadow')) {
          map.current.addImage('marker-shadow', image)
        }
      })
    }
  }, [map])

  return (
    <>
      {isClusteringEnabled && (
        <Source
          id="connections_cluster"
          type="geojson"
          data={connectionsGeoJson as FeatureCollection<Geometry, GeoJsonProperties>}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
          clusterMinPoints={10}
        >
          <>
            {/* Layer for non-clustered points */}
            <Layer
              id="unclustered-point-shadow"
              source="connections_cluster"
              type="symbol"
              filter={['!', ['has', 'point_count']]}
              layout={{
                'icon-image': 'marker-shadow', // Replace with your icon
                'icon-size': 1,
                'icon-allow-overlap': true,
                'icon-anchor': 'bottom',
                'icon-offset': [8, 0],
              }}
            />
            <Layer
              id="unclustered-point"
              source="connections_cluster"
              type="symbol"
              filter={['!', ['has', 'point_count']]}
              layout={{
                'icon-image': 'marker-icon', // Replace with your icon
                'icon-size': 1,
                'icon-allow-overlap': true,
                'icon-anchor': 'bottom',
              }}
            />

            {/* Layer for cluster circles */}
            <Layer
              id="clusters"
              source="connections_cluster"
              type="circle"
              filter={['has', 'point_count']}
              paint={{
                'circle-color': [
                  'step',
                  ['get', 'point_count'],
                  '#51bbd6', // Color for clusters with count < 100
                  100,
                  '#f1f075', // Color for clusters with count < 750
                  750,
                  '#f28cb1', // Color for clusters with count >= 750
                ],
                'circle-radius': [
                  'step',
                  ['get', 'point_count'],
                  20, // Radius for clusters with count < 100
                  100,
                  30, // Radius for clusters with count < 750
                  750,
                  40, // Radius for clusters with count >= 750
                ],
              }}
            />

            {/* Layer for cluster counts */}
            <Layer
              id="cluster-count"
              source="connections_cluster"
              type="symbol"
              filter={['has', 'point_count']}
              layout={{
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
              }}
            />
          </>
        </Source>
      )}

      {!isClusteringEnabled && (
        <Source
          id="connections"
          type="geojson"
          data={connectionsGeoJson as FeatureCollection<Geometry, GeoJsonProperties>}
        >
          <Layer
            id="connections-shadow"
            source="connections"
            type="symbol"
            layout={{
              'icon-image': 'marker-shadow', // Replace with your icon
              'icon-size': 1,
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-offset': [8, 0],
            }}
          />
          <Layer
            id="connections"
            source="connections"
            type="symbol"
            layout={{
              'icon-image': 'marker-icon', // Replace with your icon
              'icon-size': 1,
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
            }}
          />
        </Source>
      )}

      <div style={{
        position: 'absolute',
        top: isMobile ? 70 : 55,
        left: 10,
        zIndex: 1,
      }}
      >
        <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
          <button
            type="button"
            onClick={() => {
              setIsClusteringEnabled(prev => !prev)
            }}
          >
            <FontAwesomeIcon icon={faCircleNodes as IconProp} />
          </button>
        </div>
      </div>
    </>
  )
}
