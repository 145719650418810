import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { getAccessToken } from '../../services/auth.service'
import { DarkModeContext } from '../../providers/DarkModeProvider'
import { selectTheme } from '../../util/helpers'

interface Connection {
  id: string
  address: {
    id: string
    zipcode: string
    homeNumber: number
    homeLetter: string
    homeAddition: string
    street: string
    city: string
  }
  speed: number
  type: string
}

interface EVC {
  id: string
  name: string
  vpls_id: string
}

interface Customer {
  id: string
  Account_Name: string
  connections: Connection[]
  evcs: EVC[]
}

interface ApiInfo {
  per_page: number
  next_page_token: string
  count: number
  sort_by: string
  page: number
  previous_page_token: string
  page_token_expiry: string
  sort_order: string
  more_records: boolean
}

interface ApiResponse {
  data: Customer[]
  info?: ApiInfo
}

interface selectType {
  value: string
  label: string
}

export function CustomerLostAndFound() {
  const [companies, setCompanies] = React.useState<Customer[]>([])

  const contentWrapperRef = React.useRef(null)

  const lostAndFoundQuery = useQuery({
    queryKey: ['lostAndFound'],

    queryFn: async () => {
      const response = await fetch('/api/crm/companies/lostAndFound', {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      const data = await response.json()

      return data
    },
  })

  React.useEffect(() => {
    if (lostAndFoundQuery.data) {
      setCompanies(lostAndFoundQuery.data.data)
    }
  }, [lostAndFoundQuery.data])

  if (lostAndFoundQuery.isLoading) {
    return <div>Loading...</div>
  }

  if (lostAndFoundQuery.isError) {
    return <div>Error</div>
  }

  return (
    <div ref={contentWrapperRef}>
      <div className="card card-primary card-outline">
        <div className="card-header">
          <h3 className="card-title">Gevonden Voorwerpen</h3>
        </div>
        <div className="card-body table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Oude bedrijfsnaam</th>
                <th>Objecten</th>
                <th>Nieuwe bedrijfsnaam</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => {
                return <LostAndFoundRow company={company} contentWrapperRef={contentWrapperRef} key={company.id} />
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>

  )
}

function LostAndFoundRow({ company, contentWrapperRef }: { company: Customer, contentWrapperRef: any }) {
  const [companyNameFilter, setCompanyNameFilter] = React.useState('')
  const [selectedCompany, setSelectedCompany] = React.useState<selectType | null>(null)
  const [foundCompanies, setFoundCompanies] = React.useState([])

  const queryClient = useQueryClient()

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const companyQuery = useQuery({
    queryKey: ['company', {
      companyNameFilter,
    }],

    queryFn: async () => {
      let queryString = '/api/crm/companies?limit=5'

      if (companyNameFilter) {
        queryString += `&naam=${encodeURIComponent(companyNameFilter)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (companyQuery.data) {
      setFoundCompanies(companyQuery.data.data.map((company: any) => {
        return {
          value: company.id,
          label: company.Account_Name,
        }
      }))
    }
  }, [companyQuery.data])

  const handleSubmit = async () => {
    const response = await fetch(`/api/crm/companies/lostAndFound`, {
      method: 'PATCH',
      headers: new Headers({
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        old_id: company.id,
        new_id: selectedCompany?.value,
      }),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het opslaan')
      throw new Error(response.statusText)
    }

    toast.success('Bedrijf succesvol opgeslagen')

    queryClient.invalidateQueries({
      queryKey: ['lostAndFound'],
    })
  }

  return (
    <tr>
      <td>{company.Account_Name}</td>
      <td>
        <ul>
          {company.connections.length > 0 && (
            <>
              <li>Aansluitingen:</li>
              <ul>
                {company.connections.map((connection) => {
                  return (
                    <li key={connection.id}>
                      {connection.address.street}
                      {' '}
                      {connection.address.homeNumber}
                      {connection.address.homeLetter}
                      {connection.address.homeAddition}
                      ,
                      {' '}
                      {connection.address.zipcode}
                      {' '}
                      {connection.address.city}
                    </li>
                  )
                })}
              </ul>
            </>
          )}
          {company.evcs.length > 0 && (
            <>
              <li>EVC&apos;s:</li>
              <ul>
                {company.evcs.map((evc) => {
                  return (
                    <li key={evc.id}>
                      {evc.name}
                      {' '}
                      (
                      {evc.vpls_id}
                      )
                    </li>
                  )
                })}
              </ul>
            </>
          )}
        </ul>
      </td>
      <td>
        <Select
          options={foundCompanies}
          noOptionsMessage={
            () => 'Geen bedrijven gevonden'
          }
          theme={selectStyle}
          placeholder="Zoek een bedrijf"
          isLoading={companyQuery.isLoading}
          onInputChange={value => setCompanyNameFilter(value)}
          onChange={value => setSelectedCompany(value)}
          menuPlacement="auto"
          menuPortalTarget={contentWrapperRef.current}
        />
      </td>
      <td>
        <button
          className="btn btn-sm btn-primary"
          onClick={handleSubmit}
          disabled={!selectedCompany}
        >
          Opslaan
        </button>
      </td>
    </tr>
  )
}
