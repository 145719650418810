import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Card, Form, Pagination, Table } from 'react-bootstrap'
import { getAccessToken } from '../../services/auth.service'
import type { ApiResponse } from '../../util/helpers'

enum IPVersion {
  IPv4 = 'IPv4',
  IPv6 = 'IPv6',
}

interface IPBlok {
  id: string
  network: string
  netmask: string
  version: IPVersion
  name: string
  note: string | null
  autoSelect: boolean
  isPublic: boolean
  isInternal: boolean
  isManagement: boolean
  createdAt: Date
  updatedAt: Date
  deletedAt: string | null
}

interface BlockApiResponse extends ApiResponse {
  result: IPBlok[]
}

export function IPAdressen() {
  const [limit, setLimit] = React.useState(25)
  const [page, setPage] = React.useState(0)
  const [ipAddressFilter, setIpAddressFilter] = React.useState('')

  const [totalRows, setTotalRows] = React.useState(0)
  const [totalPages, setTotalPages] = React.useState(0)
  const [ipblokken, setIPBlokken] = React.useState<BlockApiResponse['result']>([])

  const ipBlockQuery = useQuery({
    queryKey: ['ipblok', {
      ipAddressFilter,
      limit,
      page,
    }],

    queryFn: async () => {
      let queryString = `/api/netwerk/ip/block?limit=${limit}&page=${page}`

      if (ipAddressFilter)
        queryString += `&ipAddress=${encodeURIComponent(ipAddressFilter)}`

      const response = await fetch(queryString, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return await response.json() as BlockApiResponse
    },
  })

  React.useEffect(() => {
    if (ipBlockQuery.data) {
      setIPBlokken(ipBlockQuery.data.result)
      setTotalRows(ipBlockQuery.data.total_rows)
      setTotalPages(ipBlockQuery.data.total_pages)
    }
  }, [ipBlockQuery.data])

  return (
  // <div>
  //     <h1>IP Adressen</h1>
  //     {/* List all blocks */}
  //     <Table striped bordered hover responsive>
  //         <thead>
  //             <tr>
  //                 <th>Netwerk</th>
  //                 <th>Versie</th>
  //                 <th>Type</th>
  //             </tr>
  //         </thead>
  //         <tbody>
  //             <tr>
  //                 <td><input type="text" className="form-control" placeholder="Zoek op IP adres" value={ipAddressFilter} onChange={(e) => setIpAddressFilter(e.target.value)} /></td>
  //                 <td></td>
  //                 <td></td>
  //             </tr>
  //             {ipblokken.map((block) => (
  //                 <tr key={block.id}>
  //                     <td>{block.network}/{block.netmask}</td>
  //                     <td>{block.version}</td>
  //                     <td>
  //                         {block.isPublic ? 'Publiek' : ''}
  //                         {block.isManagement ? 'Management' : ''}
  //                     </td>
  //                 </tr>
  //             ))}
  //         </tbody>
  //     </Table>
  // </div>
    (
      <Card>
        <Card.Header>
          <Card.Title>IP Adressen</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Netwerk</th>
                <th>Versie</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type="text" className="form-control" placeholder="Zoek op IP adres" value={ipAddressFilter} onChange={e => setIpAddressFilter(e.target.value)} /></td>
                <td></td>
                <td></td>
              </tr>
              {ipblokken.map(block => (
                <tr key={block.id}>
                  <td>
                    {block.network}
                    /
                    {block.netmask}
                  </td>
                  <td>{block.version}</td>
                  <td>
                    {block.isPublic ? 'Publiek' : ''}
                    {block.isManagement ? 'Management' : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          {/* Float left result selector, float right pagination */}
          <div className="float-left">
            <Form.Control as="select" value={limit} onChange={e => setLimit(Number.parseInt(e.target.value))}>
              <option value={25}>25 resultaten</option>
              <option value={50}>50 resultaten</option>
              <option value={100}>100 resultaten</option>
            </Form.Control>
          </div>
          <div className="float-right">
            <Pagination>
              <Pagination.Item onClick={() => setPage(0)} disabled={page === 0}>
                {`<<`}
              </Pagination.Item>
              <Pagination.Item onClick={() => setPage(page - 1)} disabled={page === 0}>
                {`<`}
              </Pagination.Item>
              {page > 1 && <Pagination.Item onClick={() => setPage(page - 2)}>{page - 1}</Pagination.Item>}
              {page > 0 && <Pagination.Item onClick={() => setPage(page - 1)}>{page}</Pagination.Item>}
              <Pagination.Item active activeLabel="">{page + 1}</Pagination.Item>
              {page < totalPages - 1 && <Pagination.Item onClick={() => setPage(page + 1)}>{page + 2}</Pagination.Item>}
              {page < totalPages - 2 && <Pagination.Item onClick={() => setPage(page + 2)}>{page + 3}</Pagination.Item>}
              <Pagination.Item onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}>
                {`>`}
              </Pagination.Item>
              <Pagination.Item onClick={() => setPage(totalPages - 1)} disabled={page === totalPages - 1}>
                {`>>`}
              </Pagination.Item>
            </Pagination>
          </div>

        </Card.Footer>
      </Card>
    )
  )
}
