import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Card, Table } from 'react-bootstrap'
import { getAccessToken } from '../../../../../../services/auth.service'

interface VoipToestelApiResponse {
  id: string
  customer: {
    id: number
    name: string
  }
  model: {
    id: string
    vendor: string
    name: string
  }
  lines: {
    index: number
    id: number
    extension: number
    label: string
  }[]
  keys: {
    index: number
    line: number
    type: number
    value: string
    label: string
  }[]
  keyTemplate: {
    id: string | null
    name: string | null
    vars: {
      index: number
      line: number
      type: number
      value: string
      label: string
    }[] | null
  }
  firmware: {
    id: string | null
    version: string | null
  }
}

export function VoipToestel() {
  const { accountid, macaddress } = useParams()

  const [voipToestel, setVoipToestel] = React.useState<VoipToestelApiResponse | null>(null)

  const voipToestelQuery = useQuery({
    queryKey: ['voipToestel', macaddress],

    queryFn: async () => {
      const response = await fetch(`/api/voip/toestellen/${accountid}/${macaddress}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok)
        throw new Error(response.statusText)

      const json = await response.json()
      return json as VoipToestelApiResponse
    },
  })

  React.useEffect(() => {
    if (voipToestelQuery.data) {
      setVoipToestel(voipToestelQuery.data)
    }
  }, [voipToestelQuery.data])

  return (
    <Card>
      <Card.Header>
        <h5 className="card-title">VoIP toestel</h5>
      </Card.Header>
      <Card.Body>
        {voipToestel
          ? (
              <div>
                <p>
                  <strong>Account ID:</strong>
                  {' '}
                  {voipToestel.customer.id}
                </p>
                <p>
                  <strong>Account name:</strong>
                  {' '}
                  {voipToestel.customer.name}
                </p>
                <p>
                  <strong>Model:</strong>
                  {' '}
                  {voipToestel.model.vendor}
                  {' '}
                  {voipToestel.model.name}
                </p>
                <p>
                  <strong>Firmware:</strong>
                  {' '}
                  {voipToestel.firmware.version}
                </p>
              </div>
            )
          : (
              <div className="alert alert-info" role="alert">
                Loading...
              </div>
            )}
        <Card>
          <Card.Header>
            <h5 className="card-title">Lijnen</h5>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Lijn</th>
                  <th>Extensie</th>
                  <th>Account</th>
                  <th>Label</th>
                </tr>
              </thead>
              <tbody>
                {voipToestel?.lines.map(line => (
                  <tr key={line.index}>
                    <td>{line.index}</td>
                    <td>{line.extension}</td>
                    <td>{line.id}</td>
                    <td>{line.label}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <h5 className="card-title">Keys</h5>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Lijn</th>
                  <th>Type</th>
                  <th>Value</th>
                  <th>Label</th>
                  <th>Acties</th>
                </tr>
              </thead>
              <tbody>
                {voipToestel?.keys.map(key => (
                  <tr key={key.index}>
                    <td>{key.index}</td>
                    <td>{key.line}</td>
                    <td>{key.type}</td>
                    <td>{key.value}</td>
                    <td>{key.label}</td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Card.Body>

    </Card>
  )
}
