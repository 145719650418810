import { useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from '../../../services/auth.service'
import { DarkModeContext } from '../../../providers/DarkModeProvider';
import { selectTheme } from '../../../util/helpers';

interface InterconnectOwner {
  id: string
  name: string
}

interface InterconnectOwnerOption {
  value: InterconnectOwner
  label: string
}

export function InterconnectToevoegen() {
  const [name, setName] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')
  const [interconnectOwner, setInterconnectOwner] = React.useState<InterconnectOwner | null>(null)
  const [interconnectOwnerOptions, setInterconnectOwnerOptions] = React.useState<InterconnectOwnerOption[]>([])

  const qrueryClient = useQueryClient()
  const navigate = useNavigate()

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const interconnectOwnerQuery = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const response = await fetch(`/api/users/groups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  React.useEffect(() => {
    if (interconnectOwnerQuery.data) {
      setInterconnectOwnerOptions(interconnectOwnerQuery.data.map((interconnectOwner: InterconnectOwner) => {
        return {
          value: interconnectOwner,
          label: interconnectOwner.name,
        }
      }))
    }
  }, [interconnectOwnerQuery.data])

  const addInterconnect = async () => {
    if (name === '' || description === '' || interconnectOwner === null) {
      toast.error('Ongeldige invoer')
      return
    }

    const response = await fetch('/api/netwerk/interconnects', {
      method: 'post',
      headers: new Headers({
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        name,
        description,
        group_id: interconnectOwner.id,
      }),
    })

    if (!response.ok) {
      toast.error('Interconnect toevoegen mislukt')
      return
    }

    toast.success('Interconnect toegevoegd')
    qrueryClient.invalidateQueries({
      queryKey: ['interconnects'],
    })
    navigate('/netwerkbeheer/interconnects')
  }

  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">Interconnect Toevoegen</h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Interconnect Naam</label>
              <input type="text" className="form-control" onChange={(e) => { setName(e.target.value) }} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Interconnect Notities</label>
              <textarea className="form-control" onChange={(e) => { setDescription(e.target.value) }} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Interconnect Relatie</label>
              <Select
                options={interconnectOwnerOptions}
                onChange={(event: any) => { setInterconnectOwner(event.value) }}
                isLoading={false}
                isDisabled={false}
                placeholder="Zoek een project eigenaar"
                theme={selectStyle}
                noOptionsMessage={
                  () => 'Geen groepen gevonden'
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <button type="button" className="btn btn-primary" onClick={addInterconnect}>Toevoegen</button>
      </div>
    </div>
  )
}
