import * as React from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { getAccessToken } from '../../services/auth.service'

interface UrenRegistratie {
  id: string
  company: {
    id: string
    name: string
  }
  users: [
    {
      id: string
      name: string
    },
  ]
  category: {
    id: string
    name: string
  }
  start_time: Date
  end_time: Date
  time_spent: number
  notes: string
  billable: boolean
}

export function UrenRegistratie() {
  const navigate = useNavigate()

  const [registraties, setRegistraties] = React.useState<UrenRegistratie[]>([])

  const registratiesQuery = useQuery({
    queryKey: ['urenregistraties'],

    queryFn: async () => {
      const response = await fetch('/api/administratie/hour-registration', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  React.useEffect(() => {
    if (registratiesQuery.data) {
      // Fix date from string to Date
      const data = registratiesQuery.data.map((registratie: UrenRegistratie) => {
        // from locale string to date
        registratie.start_time = new Date(registratie.start_time)
        registratie.end_time = new Date(registratie.end_time)
        return registratie
      })

      setRegistraties(data)
    }
  }, [registratiesQuery.data])

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Urenregistratie</h3>
        <div className="card-tools">
          {/* Add button */}
          <button type="button" className="btn btn-primary" onClick={() => navigate('/administratie/urenregistratie/toevoegen')}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
      <div className="card-body table-responsive p-0">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Relatie</th>
              <th>Werknemer</th>
              <th>Categorie</th>
              <th>Start tijd</th>
              <th>Eind tijd</th>
              <th>Tijd gespendeerd</th>
              <th>Notities</th>
              <th>Facturabel</th>
            </tr>
          </thead>
          <tbody>
            {registraties.map((registratie: UrenRegistratie) => (
              <tr key={registratie.id}>
                <td>{registratie.company.name}</td>
                <td>
                  {registratie.users.map(user => (
                    <div key={user.id}>{user.name}</div>
                  ))}
                </td>
                <td>{registratie.category?.name}</td>
                <td>{registratie.start_time.toLocaleString()}</td>
                <td>{registratie.end_time.toLocaleString()}</td>
                <td>
                  {registratie.time_spent / 60}
                  {' '}
                  uur
                </td>
                <td>{registratie.notes}</td>
                <td>{registratie.billable ? 'Ja' : 'Nee'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
