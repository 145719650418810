import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button } from 'react-bootstrap'
import { getAccessToken } from '../../../../services/auth.service'
import { selectTheme } from '../../../../util/helpers'
import { DarkModeContext } from '../../../../providers/DarkModeProvider'
import type { EVC, Endpoint } from './EVC'
import { EndpointType } from './EVC'

interface EVCRequestedProps {
  evc: EVC
}

export function EVCRequested(props: EVCRequestedProps) {
  const { evc } = props

  const queryClient = useQueryClient()

  let evcReady = true

  evc.endpoints?.forEach((endpoint) => {
    if (endpoint.type == EndpointType.ADDRESS) {
      evcReady = false
    }
    if (endpoint.type == EndpointType.CONNECTION) {
      evcReady = false
    }
  })

  const handleSubmit = async () => {
    console.log(`Submitting EVC ${evc.id} for acceptance`)

    const response = await fetch(`/api/netwerk/evcs/${evc.id}/accept`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het accepteren van de EVC')
      throw new Error(response.statusText)
    }

    queryClient.invalidateQueries({
      queryKey: ['evcs', evc.id],
    })

    toast.success('EVC succesvol geaccepteerd')
  }

  return (
    <>
      {/* General info card */}
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">
            [
            {evc.state}
            ] EVC:
            {evc.name}
          </h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <strong>VPLS ID:</strong>
              {' '}
              {evc.vpls_id == -1 ? 'Automatisch toekennen' : evc.vpls_id}
              <br />
              Aangevraagd:
              {' '}
              {evc.created.toLocaleDateString()}
              <br />
              Laatst gewijzigd:
              {' '}
              {evc.updated.toLocaleDateString()}
              <br />

            </div>
          </div>
        </div>
      </div>

      {/* Card per endpoint */}
      {evc.endpoints?.map(endpoint => (
        <EVCCard key={endpoint.id} evc={evc} endpoint={endpoint} />
      ))}

      {/* Accept if conditions are met */}
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">EVC accepteren</h3>
        </div>
        <div className="card-body">
          <Button variant="success" disabled={!evcReady} onClick={handleSubmit}>Accepteer EVC</Button>
        </div>
      </div>
    </>
  )
}

function EVCCard({
  evc,
  endpoint,
}: {
  evc: EVC
  endpoint: Endpoint
}) {
  switch (endpoint.type) {
    case EndpointType.ADDRESS:
      return EndpointAddress(evc, endpoint)
    case EndpointType.CONNECTION:
      return EndpointConnection(evc, endpoint)
    case EndpointType.INTERCONNECT:
      return EndpointInterconnect(endpoint)
    case EndpointType.DEVICE:
      return EndpointDevice(endpoint)
  }

  return (
    <div>
      Onbekend endpoint type
    </div>
  )
}

function EndpointAddress(evc: EVC, endpoint: Endpoint) {
  const endpointReady = false
  const endpointName = `${endpoint.address?.street}`

  const [connections, setConnections] = React.useState<[]>([])
  const [selectedConnection, setSelectedConnection] = React.useState<string>()

  const queryClient = useQueryClient()

  const connectionsQuery = useQuery({
    queryKey: ['evc', 'endpoint', endpoint.id],

    queryFn: async () => {
      const response = await fetch(`/api/bag/num/${endpoint.address?.id}/connections`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  React.useEffect(() => {
    if (connectionsQuery.data) {
      setConnections(connectionsQuery.data)
    }
  }, [connectionsQuery.data])

  const handleSubmit = async () => {
    console.log(`Submitting connection ${selectedConnection} for endpoint ${endpoint.id}`)

    const response = await fetch(`/api/netwerk/evcs/endpoint/${endpoint.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        connection_id: selectedConnection,
      }),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het koppelen van de aansluiting')
      throw new Error(response.statusText)
    }

    toast.success('Aansluiting succesvol gekoppeld')

    queryClient.invalidateQueries({
      queryKey: ['evcs', evc.id],
    })
  }

  return (
    <div className="card card-outline card-primary" key={endpoint.id}>
      <div className="card-header">
        <h3 className="card-title">
          Adres:
          {`${endpointName}`}
        </h3>
        {/* Card tools to display status labels */}
        <div className="card-tools">
          {endpointReady ? <span className="badge badge-success">Gereed</span> : <span className="badge badge-warning">Niet gekoppeld aan aansluiting</span>}
        </div>

      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <strong>Adres:</strong>
            {' '}
            {endpoint.address?.street}
            {' '}
            {endpoint.address?.homeNumber}
            {' '}
            {endpoint.address?.homeLetter}
            {' '}
            {endpoint.address?.homeAddition}
            ,
            {' '}
            {endpoint.address?.zipcode}
            {' '}
            {endpoint.address?.city}
            <br />
          </div>
        </div>

        {/* Select a connection to bind to */}
        <div>
          De volgende aansluitingen zijn beschikbaar op dit adres:
        </div>
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th></th>
              <th>Bedrijf</th>
              <th>Aansluiting</th>
            </tr>
          </thead>
          <tbody>
            {connections.map((connection: any) => (
              <tr key={connection.id}>
                <td>
                  <input type="radio" name="connection" value={connection.id} onChange={e => setSelectedConnection(e.target.value)} />
                </td>
                <td>{connection.company.name}</td>
                <td>{`${connection.type} ${connection.speed} Mbit/s`}</td>
              </tr>
            ))}
            {connections.length == 0 && <tr><td colSpan={3}>Geen aansluitingen beschikbaar</td></tr>}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>
                <button className="btn btn-success" disabled={!selectedConnection} onClick={handleSubmit}>Koppel aansluiting</button>
                <button className="btn btn-info">Maak nieuwe aansluiting op dit adres</button>
              </td>
            </tr>
          </tfoot>
        </table>

      </div>
    </div>
  )
}

interface SelectedPorts {
  [key: string]: object[]
}

function EndpointConnection(evc: EVC, endpoint: Endpoint) {
  const [selectedDevice, setSelectedDevice] = React.useState<string>()
  const [selectedPort, setSelectedPort] = React.useState<SelectedPorts>({})

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const queryClient = useQueryClient()

  const endpointQuery = useQuery({
    queryKey: ['evc', 'endpoint', endpoint.id],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/aansluitingen/${endpoint.connection?.id}/devices`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  if (endpointQuery.isLoading) {
    return <div>Loading...</div>
  }

  const handleSubmit = async () => {
    if (!selectedDevice || !selectedPort[selectedDevice]) {
      toast.error('Onjuiste invoer.')
      return
    }
    console.log(`Submitting connection ${selectedDevice} with port ${selectedPort[selectedDevice]} for endpoint ${endpoint.id}`)

    const response = await fetch(`/api/netwerk/evcs/endpoint/${endpoint.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        device_id: selectedDevice,
        port_id: selectedPort[selectedDevice],
      }),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het koppelen van het modem')
      throw new Error(response.statusText)
    }

    toast.success('Modem succesvol gekoppeld')

    queryClient.invalidateQueries({
      queryKey: ['evcs', evc.id],
    })
  }

  return (
    <div className="card card-outline card-primary" key={endpoint.id}>
      <div className="card-header">
        <h3 className="card-title">
          Aansluiting:
          {`${endpoint.connection?.type} ${endpoint.connection?.speed} Mbit/s`}
        </h3>
        {/* Card tools to display status labels */}
        <div className="card-tools">
          <span className="badge badge-warning">Koppel aan een modem / poort</span>
        </div>
      </div>
      <div className="card-body">
        {/* Select a device port to bind to */}
        <div>
          De volgende modems zijn beschikbaar op deze aansluiting:
        </div>
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th></th>
              <th>CPE</th>
              <th>Model</th>
              <th>Poort</th>
            </tr>
          </thead>
          <tbody>
            {endpointQuery.data.map((device: any) => (
              <tr key={device.id}>
                <td>
                  <input
                    type="radio"
                    name="device"
                    value={device.id}
                    onChange={(e) => {
                      setSelectedDevice(e.target.value)
                    }}
                  />
                </td>
                <td>{device.name}</td>
                <td>{device.model.name}</td>
                {/* Port dropdown */}
                <td>
                  <Select
                    theme={selectStyle}
                    options={device.model.ports.map((port: any) => ({
                      value: port.id,
                      label: port.name,
                    }))}
                    onChange={(e: any) => {
                      setSelectedPort({
                        ...selectedPort,
                        [device.id]: e.value,
                      })
                    }}
                    isSearchable={true}
                    placeholder="Selecteer een poort"
                  />

                </td>
              </tr>
            ))}
            {endpointQuery.data.length == 0 && <tr><td colSpan={4}>Geen aansluitingen beschikbaar</td></tr>}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>
                <button className="btn btn-success" disabled={!(selectedDevice && selectedPort[selectedDevice])} onClick={handleSubmit}>Koppel Modem</button>
                <button className="btn btn-info">Maak nieuw modem op dit adres</button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

function EndpointInterconnect(endpoint: Endpoint) {
  const endpointName = endpoint.interconnect?.name ?? ''
  const endpointReady = true

  return (
    <div className="card card-outline card-primary" key={endpoint.id}>
      <div className="card-header">
        <h3 className="card-title">
          Interconnect:
          {`${endpointName}`}
        </h3>
        {/* Card tools to display status labels */}
        <div className="card-tools">
          {endpointReady ? <span className="badge badge-success">Gereed</span> : <span className="badge badge-warning">Niet Gereed</span>}
        </div>

      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <strong>Vlan type:</strong>
            {' '}
            {endpoint.mode}
            <br />
            <strong>Vlan:</strong>
            {' '}
            {endpoint.vlan.inner ? `${endpoint.vlan.outer}.${endpoint.vlan.inner}` : endpoint.vlan.outer}
            <br />
            <strong>Poort:</strong>
            {' '}
            {endpoint.interconnect?.port}
          </div>
        </div>
      </div>
    </div>
  )
}

function EndpointDevice(endpoint: Endpoint) {
  if (!endpoint.device)
    throw new Error('Device endpoint has no device')

  return (
    <div className="card card-outline card-primary" key={endpoint.id}>
      <div className="card-header">
        <h3 className="card-title">
          Modem:
          {`${endpoint.device.deviceName}`}
        </h3>
        {/* Card tools to display status labels */}
        <div className="card-tools">
          <span className="badge badge-success">Gereed</span>
        </div>

      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <strong>Vlan type:</strong>
            {' '}
            {endpoint.mode}
            <br />
            <strong>Vlan:</strong>
            {' '}
            {endpoint.vlan.inner ? `${endpoint.vlan.outer}.${endpoint.vlan.inner}` : endpoint.vlan.outer}
            <br />
            <strong>Poort:</strong>
            {' '}
            {endpoint.device_port}
          </div>
        </div>
      </div>
    </div>
  )
}
