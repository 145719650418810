import React from 'react'
import { Outlet } from 'react-router-dom'
import LoadingScreen from '../components/LoadingScreen'

function BackendOnlineGuard() {
  // Send a request to the backend to check if it is online
  const [loading, setLoading] = React.useState(true)
  const [isOnline, setIsOnline] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  /*
  * Make a call to /api/ping to check if the backend is online
  * If the backend is offline, Retry every 30 seconds
  */
  const checkBackendOnline = async () => {
    // Fetch the ping endpoint with no cache
    const response = await fetch('/api/ping', {
      method: 'GET',
      cache: 'no-cache',
    })

    if (response.ok) {
      setIsOnline(true)
      setIsError(false)
    }
    else {
      setIsOnline(false)
      setIsError(true)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    setInterval(() => {
      checkBackendOnline()
    }, 30000)
    checkBackendOnline()
  }, [])

  return (
    <React.Fragment>
      {loading && <LoadingScreen loadingText="" />}
      {isError && (
        <div
          className="dark-mode"
          style={{
            width: '100vw',
            height: '100vh',
          }}
        >
          <div className="">
            <div className="pt-3" />
            <section className="content m-3">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Backend offline</h3>
                </div>
                <div className="card-body">
                  <div className="">
                    <div className="row">
                      <div className="col-1">
                        <i
                          className="fas fa-2x fa-exclamation-triangle text-danger"
                          style={{
                            position: 'relative',
                            top: '50%',
                            transform: 'translatey(-50%)',
                          }}
                        >
                        </i>
                      </div>
                      <div
                        className="col-11"
                        style={{
                          paddingLeft: '20px',
                        }}
                      >
                        <h3 className="text-danger">Er is iets misgegaan</h3>
                        <p
                          className="text-danger"
                          style={{
                            margin: '0',
                          // }}>Er is iets misgegaan bij het ophalen van de modems. Probeer het later opnieuw.</p>
                          }}
                        >
                          Het systeem is tijdelijk niet bereikbaar... Probeer het later nog een keer.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      {!loading && isOnline && <Outlet />}
    </React.Fragment>
  )
}

export default BackendOnlineGuard
