import * as React from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import Select from 'react-select'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getAccessToken } from '../../services/auth.service'
import { DarkModeContext } from '../../providers/DarkModeProvider'
import { selectTheme } from '../../util/helpers'

interface ProjectOwner {
  id: string
  name: string
}

interface ProjectOwnerOption {
  value: ProjectOwner
  label: string
}

export function ProjectToevoegen() {
  const [name, setName] = React.useState<string>('')
  const [projectOwnerOptions, setProjectOwnerOptions] = React.useState<ProjectOwnerOption[]>([])
  const [projectOwner, setProjectOwner] = React.useState<ProjectOwner | null>(null)

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const addProject = async (name: string) => {
    if (name === '' || projectOwner === null) {
      toast.error('Ongeldige invoer')
      return
    }

    const response = await fetch('/api/projects', {
      method: 'post',
      headers: new Headers({
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        naam: name,
        group_id: projectOwner.id,
      }),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan')
      return
    }

    queryClient.invalidateQueries({
      queryKey: ['aansluitingen'],
    })

    toast.success('Project toegevoegd')
    navigate('/projecten')
  }

  const projectOwnerQuery = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const response = await fetch(`/api/users/groups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  React.useEffect(() => {
    if (projectOwnerQuery && projectOwnerQuery.data) {
      setProjectOwnerOptions(projectOwnerQuery.data.map((projectOwner: ProjectOwner) => {
        return {
          value: projectOwner,
          label: projectOwner.name,
        }
      }))
    }
  }, [projectOwnerQuery.data])

  return (
    <div>
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Project toevoegen</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Projectnaam</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => { setName(e.target.value) }}
                  placeholder="Plaatsnaam - Straatnaam (Projectnaam)"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Project Eigenaar</label>
                <Select
                  options={projectOwnerOptions}
                  onChange={(event: any) => { setProjectOwner(event.value) }}
                  isLoading={false}
                  isDisabled={false}
                  placeholder="Zoek een project eigenaar"
                  theme={selectStyle}
                  noOptionsMessage={
                    () => 'Geen groepen gevonden'
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12 ml-auto mt-1 col-xl-2">
          <button
            className="btn btn-block btn-primary"
            onClick={async () => {
              await addProject(name)
            }}
          >
            Opslaan
          </button>
        </div>
      </div>
    </div>
  )
}
