import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import md5 from 'md5'
import { logoutUser } from '../../../../services/auth.service'
import Dropdown from '../../../../components/Dropdown'
import { useUser } from '../../../../providers/UserProvider'

const StyledUserImage = styled.img`
  height: 1.6rem !important;
  width: 1.6rem !important;
  margin-right: 0 !important;
  margin-left: -8px !important;
`

function UserDropdown() {
  const { user } = useUser()
  const navigate = useNavigate()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const logOut = (event: any) => {
    event.preventDefault()
    setDropdownOpen(false)
    logoutUser()

    navigate('/login')
  }

  const navigateToProfile = (event: any) => {
    event.preventDefault()
    setDropdownOpen(false)
    navigate('/profile')
  }

  return (
    <Dropdown
      isOpen={dropdownOpen}
      onChange={(open: boolean) => setDropdownOpen(open)}
      className="user-menu"
      menuContainerTag="ul"
      buttonTemplate={(
        <StyledUserImage
          src={`https://www.gravatar.com/avatar/${md5(user.email ? user.email : 'operations@datafiber.nl')}?s=160&d=retro&r=pg`}
          className="user-image img-circle elevation-2"
          alt="User"
        />
      )}
      menuTemplate={(
        <>
          <li className="user-header bg-primary">
            <img
              src={`https://www.gravatar.com/avatar/${md5(user.email ? user.email : 'operations@datafiber.nl')}?s=160&d=retro&r=pg`}
              className="img-circle elevation-2"
              alt="User"
            />
            <p>
              {user.firstName}
              {' '}
              {user.lastName}
              <small>
                <span>
                  {user.email}
                </span>
              </small>
            </p>
          </li>
          {/* <li className="user-body">
            <div className="row">
              <div className="col-4 text-center">
                <Link to="/">header.user.followers</Link>
              </div>
              <div className="col-4 text-center">
                <Link to="/">header.user.sales</Link>
              </div>
              <div className="col-4 text-center">
                <Link to="/">header.user.friends</Link>
              </div>
            </div>
          </li> */}
          <li className="user-footer">
            <button
              type="button"
              className="btn btn-default btn-flat"
              onClick={navigateToProfile}
            >
              Mijn Profiel
            </button>
            <button
              type="button"
              className="btn btn-default btn-flat float-right"
              onClick={logOut}
            >
              Uitloggen
            </button>
          </li>
        </>
      )}
    />
  )
}

export default UserDropdown
