import axios from 'axios'

const API_URL = '/api/auth/'

export function logoutUser() {
  localStorage.removeItem('accessToken')
}
export function getAccessToken() {
  const accessToken = localStorage.getItem('accessToken')
  if (typeof accessToken === 'string') {
    return accessToken
  }
  return null
}

export async function authLogin(username: string, password: string) {
  const response = await axios.post(`${API_URL}login`, {
    username,
    password,
  })

  if (response.status === 201) {
    localStorage.setItem('accessToken', response.data.access_token)
    return response.data
  }
}
