import * as React from 'react'
import Papa from 'papaparse'

interface RowData { [key: string]: string | number } // or any type you expect your CSV rows to contain

const BLOCKED_IDS = ['410013'] // You can easily add more IDs to block

const BESTELD_DOOR_TRANSFORMATIONS: { [id: string]: string } = {
  3001046: '3001053',
  // Add more transformations as needed. E.g.,
  // "12345": "54321",
}

const IGNORE_NATIONAL_ID = ['41000999']

const ARTIKEL_CODE: { [id: string]: string } = {
  'Mobiel': 'D30-00050',
  'Nationaal': 'D30-00050',
  'Internationaal': 'D30-00052',
  'Internationaal mobiel': 'D30-00052',
  'Service': 'D30-00053',
  'Internationaal service': 'D30-00053',
  'Machine-to-machine (M2M)': 'D30-00053',
}

function getDutchMonthAndYear() {
  const currentDate = new Date()
  const year = currentDate.getFullYear()

  const monthsInDutch = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ]

  const month = monthsInDutch[currentDate.getMonth()]

  return `${month} ${year}`
}

export function VialerCsvProcessor() {
  const [data, setData] = React.useState<RowData[]>([])
  const [originalData, setOriginalData] = React.useState<RowData[]>([])
  const [csvData, setCsvData] = React.useState<string | null>(null)
  const [description, setDescription] = React.useState<string>(getDutchMonthAndYear())
  const [warnings, setWarnings] = React.useState<string[]>([])

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const file = files[0]
      Papa.parse(file, {
        complete: (result: { data: RowData[] }) => {
          const validData = (result.data as RowData[]).filter((row) => {
            const klantnummer = String(row['Intern Klantnummer'])
            const callType = row.Gesprekstype

            let filter = false

            if (klantnummer && BLOCKED_IDS.includes(klantnummer)) { filter = true }
            if (klantnummer && IGNORE_NATIONAL_ID.includes(klantnummer) && ARTIKEL_CODE[callType] == 'D30-00050') { filter = true }
            if (!row.Prijs) { filter = true }

            return !filter
          })
          setOriginalData(validData)
        },
        header: true,
      })
    }
  }

  const downloadProcessedCSV = () => {
    const transformed = transformData(originalData)

    // Delimit by ; for the export
    const csvContent = Papa.unparse(transformed, { delimiter: ';' })

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'processed_data.csv'
    link.click()
  }

  const transformData = (originalData: RowData[]) => {
    const warnings: string[] = []
    const parsedData = originalData.map((row) => {
      const klantnaam = row['Gefactureerde relatie']
      const klantnummer = row['Intern Klantnummer']
      const callType = row.Gesprekstype

      if (!klantnummer) {
        warnings.push(`Geen klantnummer voor klant: ${klantnaam}`)
      }

      // Apply special transformation for klantnummer if needed.
      // Use the transformation map, or default to the klantnummer itself.
      const besteldDoor = BESTELD_DOOR_TRANSFORMATIONS[klantnummer] ?? klantnummer

      // Convert , to . for parsing, round up, and then revert . to ,
      const rawPrice = Number.parseFloat(row.Prijs.toString().replace(',', '.')) * 100
      const roundedPriceValue = Math.ceil(rawPrice) / 100
      const roundedPrice = roundedPriceValue.toFixed(2).replace('.', ',')

      const artikelCode = ARTIKEL_CODE[callType]
      if (!artikelCode) {
        warnings.push(`Onbekend gesprekstype: ${callType}`)
      }

      return {
        'Code': besteldDoor,
        'Code (2)': klantnummer,
        'Omschrijving: Kopregel': description, // This comes from the useState description.
        'Artikelcode': artikelCode,
        'Aantal': '1',
        'Prijs per eenheid': roundedPrice,
      }
    })

    // Merge based on Code & artikelcode. Add up the prices.
    const mergedData = parsedData.reduce((acc, row) => {
      const key = `${row.Code}-${row.Artikelcode}`
      if (acc[key]) {
        acc[key] = {
          ...acc[key],
          'Prijs per eenheid': (Number.parseFloat(acc[key]['Prijs per eenheid'].toString().replace(',', '.')) + Number.parseFloat(row['Prijs per eenheid'].toString().replace(',', '.'))).toFixed(2).replace('.', ','),
        }
      }
      else {
        acc[key] = row
      }
      return acc
    }, {} as { [key: string]: RowData })

    // De-duplicate warnings
    setWarnings(warnings.reduce((acc: string[], warning) => {
      if (!acc.includes(warning)) {
        acc.push(warning)
      }
      return acc
    }, []))

    return Object.values(mergedData)
  }

  React.useEffect(() => {
    const transformedData = transformData(originalData)
    setData(transformedData)
  }, [originalData, description])

  return (
    <div>
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Vialer Belkosten CSV Processor</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="description">Omschrijving: Kopregel</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="file">Upload CSV</label>
                <input
                  type="file"
                  className="form-control"
                  id="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                />
              </div>
              <span>
                Totaliseer op Gefactureerde relatie & Gesprekstype.
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={downloadProcessedCSV}
          >
            Download CSV
          </button>
        </div>
      </div>

      {warnings.length > 0 && (
        <div className="card card-danger">
          <div className="card-header">
            <h3 className="card-title">Waarschuwingen</h3>
          </div>
          <div className="card-body">
            <ul>
              {warnings.map((warning, index) => (
                <li key={index}>{warning}</li>
              ))}
            </ul>
          </div>
        </div>

      )}

      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Voorbeeld</h3>
        </div>
        <div className="card-body table-responsive">
          {data.length > 0 && (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Besteld door</th>
                    <th>Factuur voor</th>
                    <th>Omschrijving: Kopregel</th>
                    <th>Artikelcode</th>
                    <th>Aantal</th>
                    <th>Prijs per eenheid</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.Code}</td>
                      <td>{row['Code (2)']}</td>
                      <td>{row['Omschrijving: Kopregel']}</td>
                      <td>{row.Artikelcode}</td>
                      <td>{row.Aantal}</td>
                      <td>{row['Prijs per eenheid']}</td>
                    </tr>
                  ))}
                </tbody>

              </table>
            </>
          ) || (
            <div className="alert alert-info">
              <h5 className="alert-heading">Geen data!</h5>
              <p>
                Upload een CSV om het voorbeeld te zien.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
