import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getAccessToken } from '../services/auth.service'

function PublicRoute() {
  const isLoggedIn = getAccessToken()

  return isLoggedIn ? <Navigate to="/" /> : <Outlet />
}

export default PublicRoute
