import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { getAccessToken } from '../../../../services/auth.service'
import { selectTheme } from '../../../../util/helpers'
import { DarkModeContext } from '../../../../providers/DarkModeProvider'
import { EndpointType } from './EVC'
import type { EVC, Endpoint } from './EVC'

interface EVCDraftProps {
  evc: EVC
}

export function EVCDraft(props: EVCDraftProps) {
  const { evc } = props

  const [vplsPeers, setVplsPeers] = React.useState<string[]>([])

  const queryClient = useQueryClient()

  React.useEffect(() => {
    const tempPeers: string[] = [...vplsPeers]

    evc.endpoints?.forEach((endpoint) => {
      if (endpoint.type === EndpointType.INTERCONNECT) {
        const managementAddress = endpoint.interconnect?.device?.managementAddressAddress
        if (managementAddress) {
          // Only add peer if it's not already in the list
          if (!tempPeers.includes(managementAddress)) {
            tempPeers.push(managementAddress)
          }
        }
      }
    })

    setVplsPeers(tempPeers)
  }, [])

  const handleSubmit = async () => {
    console.log(`Submitting EVC ${evc.id} for activation`)

    const response = await fetch(`/api/netwerk/evcs/${evc.id}/activate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het activeren van de EVC')
      throw new Error(response.statusText)
    }

    queryClient.invalidateQueries({
      queryKey: ['evcs', evc.id],
    })

    toast.success('EVC succesvol geactiveerd')
  }

  const sortedEvcEndpoints = evc.endpoints?.sort((a, b) => {
    if (a.type == EndpointType.INTERCONNECT && b.type == EndpointType.DEVICE) {
      return -1
    }
    if (a.type == EndpointType.DEVICE && b.type == EndpointType.INTERCONNECT) {
      return 1
    }
    // Sort by interconnect name
    if ((a.interconnect?.name ?? '') < (b.interconnect?.name ?? '')) {
      return -1
    }
    if ((a.interconnect?.name ?? '') > (b.interconnect?.name ?? '')) {
      return 1
    }
    // Sort by device name
    if ((a.device?.deviceName ?? '') < (b.device?.deviceName ?? '')) {
      return -1
    }
    if ((a.device?.deviceName ?? '') > (b.device?.deviceName ?? '')) {
      return 1
    }

    return 0
  })

  return (
    <>
      {/* General info card */}
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">
            [
            {evc.state}
            ] EVC:
            {evc.name}
          </h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <strong>VPLS ID:</strong>
              {' '}
              {evc.vpls_id == -1 ? 'Automatisch toekennen' : evc.vpls_id}
              <br />
            </div>
            <div className="col-md-6">
              <strong>Peers:</strong>
              <ul>
                {vplsPeers.map(peer => (
                  <li key={peer}>{peer}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Card per interconnect */}
      {sortedEvcEndpoints && sortedEvcEndpoints.map(endpoint => (
        <EVCCard key={endpoint.id} evc={evc} endpoint={endpoint} vplsPeers={vplsPeers} setVplsPeers={setVplsPeers} />
      ),
      )}

      {/* Submit button */}
      <div className="card card-outline card-primary">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-primary" onClick={handleSubmit}>Activeren</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function EVCCard({
  evc,
  endpoint,
  vplsPeers,
  setVplsPeers,
}: {
  evc: EVC
  endpoint: Endpoint
  vplsPeers: string[]
  setVplsPeers: React.Dispatch<React.SetStateAction<string[]>>
}) {
  switch (endpoint.type) {
    case EndpointType.INTERCONNECT:
      return EndpointInterconnect(evc, endpoint, vplsPeers)
    case EndpointType.DEVICE:
      return EndpointDevice(evc, endpoint, vplsPeers, setVplsPeers)
  }
  return (
    <div>
      WAARSCHUWING: INCORRECT ENDPOINT TYPE
    </div>
  )
}

function EndpointInterconnect(evc: EVC, endpoint: Endpoint, vplsPeers: string[]) {
  const darkMode = React.useContext(DarkModeContext).isDarkMode

  const endpointName = endpoint.interconnect?.name ?? ''

  const codeStyles: React.CSSProperties = {
    transition: 'all 0.1s ease-in-out',
    backgroundColor: darkMode ? '#2d2d2d' : '#f7f7f7',
    color: darkMode ? '#a8a8a8' : '#333',
    fontFamily: 'monospace',
    padding: '0.5rem',
    borderRadius: '4px',
    overflowX: 'auto',
  }

  const isMonitored = !!endpoint.monitoring?.application

  return (
    <div className="card card-outline card-primary" key={endpoint.id}>
      <div className="card-header">
        <h3 className="card-title">
          Interconnect:
          {`${endpointName}`}
        </h3>
        <div className="card-tools">
          {isMonitored && (
            <span className="badge badge-success">Gemonitord</span>
          )}
          {!isMonitored && (
            <button type="button" className="btn btn-primary btn-sm" style={{ marginLeft: '10px' }}>
              <i className="fas fa-plus"></i>
              {' '}
              Monitoring toevoegen
            </button>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <strong>Vlan type:</strong>
            {' '}
            {endpoint.mode}
            <br />
            <strong>Vlan:</strong>
            {' '}
            {endpoint.vlan.inner ? `${endpoint.vlan.outer}.${endpoint.vlan.inner}` : endpoint.vlan.outer}
            <br />
            <strong>Poort:</strong>
            {' '}
            {endpoint.interconnect?.port}
          </div>
        </div>
        {/* Code helper */}
        <div className="row">
          <div className="col-md-12">
            <strong>Code:</strong>
            <br />

            {endpoint.interconnect && endpoint.interconnect.device.deviceModel.vendor == 'Brocade' && (
              <div style={codeStyles}>
                <code>
                  configure terminal
                  <br />
                  router mpls
                  <br />
                  vpls
                  {' '}
                  {evc.name}
                  {' '}
                  {evc.vpls_id}
                  <br />
                  vpls-mtu 1550
                  <br />
                  {vplsPeers.map((peer) => {
                    if (peer == endpoint.interconnect?.device?.managementAddressAddress) {

                    }
                    else {
                      return (
                        <span key={`${endpoint.id}-${peer}`}>
                          vpls-peer
                          {' '}
                          {peer}
                          <br />
                        </span>
                      )
                    }
                  })}
                  {endpoint.vlan.inner
                    ? (
                        <>
                          vlan
                          {' '}
                          {endpoint.vlan.outer}
                          {' '}
                          inner
                          {' '}
                          {endpoint.vlan.inner}
                          <br />
                        </>
                      )
                    : (
                        <>
                          vlan
                          {' '}
                          {endpoint.vlan.outer}
                          <br />
                        </>
                      )}
                  {endpoint.mode == 'TAGGED' || endpoint.mode == 'QINQ'
                    ? (
                        <>
                          tagged e
                          {' '}
                          {endpoint.interconnect?.port}
                          <br />
                        </>
                      )
                    : (
                        <>
                          untagged e
                          {' '}
                          {endpoint.interconnect?.port}
                          <br />
                        </>
                      )}
                  end
                  <br />
                  write memory
                  <br />
                </code>
              </div>
            )}
            {endpoint.interconnect && endpoint.interconnect.device.deviceModel.vendor == 'Mikrotik' && (
              <MikrotikInterconnectCode endpoint={endpoint} evc={evc} vplsPeers={vplsPeers} />
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

function MikrotikInterconnectCode({ endpoint, evc, vplsPeers }: { endpoint: Endpoint, evc: EVC, vplsPeers: string[] }) {
  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const codeStyles: React.CSSProperties = {
    transition: 'all 0.1s ease-in-out',
    backgroundColor: darkMode ? '#2d2d2d' : '#f7f7f7',
    color: darkMode ? '#a8a8a8' : '#333',
    fontFamily: 'monospace',
    padding: '0.5rem',
    borderRadius: '4px',
    overflowX: 'auto',
  }

  const bridgeName = `bridge VPLS${evc.vpls_id} ${evc.name}`

  const vplsInterfaces = vplsPeers.map((peer) => {
    if (peer == endpoint.interconnect?.device?.managementAddressAddress) {

    }
    else {
      return {
        name: `vpls${evc.vpls_id} ${evc.name}-${peer}`,
        peer,
      }
    }
  })

  return (
    <div style={codeStyles}>
      <code>
        /interface/bridge/add name=&quot;
        {bridgeName}
        &quot; protocol-mode=none mtu=1550 vlan-filtering=yes arp=disabled
        <br />
        {vplsInterfaces.map((vplsInterface) => {
          if (vplsInterface) {
            return (
              <span key={vplsInterface.peer}>
                /interface/vpls/add name=&quot;
                {vplsInterface.name}
                &quot; disabled=no arp=disabled cisco-static-id=
                {evc.vpls_id}
                {' '}
                pw-l2mtu=1550 mtu=1550 peer=
                {vplsInterface.peer}
                <br />
                /interface/bridge/port/add bridge=&quot;
                {bridgeName}
                &quot; interface=&quot;
                {vplsInterface.name}
                &quot; horizon=1
                <br />
              </span>
            )
          }
        })}
        {endpoint.vlan.inner
          ? (
              <>
                /interface/vlan/add interface=&quot;
                {endpoint.interconnect?.port}
                &quot; name=&quot;
                {endpoint.interconnect?.port}
                .VLAN
                {endpoint.vlan.outer}
                &quot; vlan-id=
                {endpoint.vlan.outer}
                {' '}
                <br />
                /interface/vlan/add interface=&quot;
                {endpoint.interconnect?.port}
                .VLAN
                {endpoint.vlan.outer}
                &quot; name=&quot;
                {endpoint.interconnect?.port}
                .VLAN
                {endpoint.vlan.outer}
                .VLAN
                {endpoint.vlan.inner}
                {' '}
                VPLS
                {evc.vpls_id}
                {' '}
                {evc.name}
                &quot; vlan-id=
                {endpoint.vlan.inner}
                {' '}
                mtu=1550
                <br />
                /interface/bridge/port/add bridge=&quot;
                {bridgeName}
                &quot; interface=&quot;
                {endpoint.interconnect?.port}
                .VLAN
                {endpoint.vlan.outer}
                .VLAN
                {endpoint.vlan.inner}
                {' '}
                VPLS
                {evc.vpls_id}
                {' '}
                {evc.name}
                &quot;
                <br />
              </>
            )
          : (
              <>
                /interface/vlan/add interface=&quot;
                {endpoint.interconnect?.port}
                &quot; name=&quot;
                {endpoint.interconnect?.port}
                .VLAN
                {endpoint.vlan.outer}
                {' '}
                VPLS
                {evc.vpls_id}
                {' '}
                {evc.name}
                &quot; vlan-id=
                {endpoint.vlan.outer}
                {' '}
                mtu=1550
                <br />
                /interface/bridge/port/add bridge=&quot;
                {bridgeName}
                &quot; interface=&quot;
                {endpoint.interconnect?.port}
                .VLAN
                {endpoint.vlan.outer}
                {' '}
                VPLS
                {evc.vpls_id}
                {' '}
                {evc.name}
                &quot;
                <br />
              </>
            )}
      </code>
    </div>
  )
}

interface selectType {
  value: string
  label: string
}

function EndpointDevice(evc: EVC, endpoint: Endpoint, vplsPeers: string[], setVplsPeers: React.Dispatch<React.SetStateAction<string[]>>) {
  const [selectedVendor, setSelectedVendor] = React.useState('')
  const [selectedIp, setSelectedIp] = React.useState('')
  const [selectedPort, setSelectedPort] = React.useState('')
  const [tagged, setTagged] = React.useState(true)

  // Find the first evc endpoint inner vlan and use as default, use outer vlan if inner is not set, default to 1000 if no vlan is set
  const [vlan, setVlan] = React.useState(evc.endpoints?.find(endpoint => endpoint.vlan.inner)?.vlan.inner ?? evc.endpoints?.find(endpoint => endpoint.vlan.outer)?.vlan.outer ?? 1000)

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  if (!endpoint.device) {
    throw new Error('Endpoint device is undefined')
  }

  const selectOptions = [
    { value: 'mikrotik', label: 'Mikrotik' },
    { value: 'brocade', label: 'Brocade' },
  ] as selectType[]

  const handleIPChange = async (e: any) => {
    setVplsPeers((peers) => {
      const tempPeers = [...peers]
      const index = tempPeers.indexOf(selectedIp)
      if (index > -1 && evc.endpoints && !evc.endpoints.some(endpoint => endpoint.interconnect?.device?.managementAddressAddress === selectedIp)) {
        tempPeers.splice(index, 1)
      }
      if (!tempPeers.includes(e.target.value)) {
        tempPeers.push(e.target.value)
      }
      return tempPeers
    })
    setSelectedIp(e.target.value)
  }

  const codeStyles: React.CSSProperties = {
    transition: 'all 0.1s ease-in-out',
    backgroundColor: darkMode ? '#2d2d2d' : '#f7f7f7',
    color: darkMode ? '#a8a8a8' : '#333',
    fontFamily: 'monospace',
    padding: '0.5rem',
    borderRadius: '4px',
    overflowX: 'auto',
  }

  return (
    <div className="card card-outline card-primary" key={endpoint.id}>
      <div className="card-header">
        <h3 className="card-title">
          Modem:
          {`${endpoint.device.deviceName}`}
        </h3>
      </div>
      <div className="card-body">
        {/* Config helper, 1 select box with options Mikrotik and Brocade, 1 field for VPLS endpoint ip */}
        <div className="row">
          <div className="col-md">
            <strong>Vendor:</strong>
            <Select
              theme={selectStyle}
              options={selectOptions}
              onChange={e => setSelectedVendor(e?.value ?? '')}
              value={selectOptions.find(option => option.value == selectedVendor)}
            />
            <br />
          </div>
          <div className="col-md">
            <strong>IP:</strong>
            <input
              type="text"
              className="form-control"
              placeholder="10.39.0.1"
              value={selectedIp}
              onChange={handleIPChange}
            />
          </div>
          <div className="col-md">
            <strong>Poort:</strong>
            <input
              type="text"
              className="form-control"
              placeholder="1/1"
              value={selectedPort}
              onChange={e => setSelectedPort(e.target.value)}
            />
          </div>
          <div className="col-md">
            <strong>Vlan:</strong>
            <input
              type="number"
              className="form-control"
              placeholder="1000"
              value={vlan}
              onChange={e => setVlan(Number.parseInt(e.target.value))}
            />
          </div>
          <div className="col-md">
            <strong>Tagged:</strong>
            <br />
            <input
              type="checkbox"
              checked={tagged}
              onChange={e => setTagged(e.target.checked)}
            />
          </div>
        </div>
        {/* Code helper */}
        <div className="row">
          <div className="col-md-12">
            <strong>Code:</strong>
            <br />
            <div style={codeStyles}>
              {selectedVendor == 'mikrotik' && (
                <code>
                  {`/interface/bridge/add name="bridge VPLS${evc.vpls_id} ${evc.name}" protocol-mode=none mtu=1550 vlan-filtering=yes arp=disabled`}
                  <br />
                  {tagged
                    ? (
                        <>
                          {`/interface/vlan/add interface="${selectedPort}" name="${selectedPort.split(' ')[0]}.vlan${vlan} VPLS${evc.vpls_id} ${evc.name}" vlan-id=${vlan} mtu=1550`}
                          <br />
                          {`/interface/bridge/port/add bridge="bridge VPLS${evc.vpls_id} ${evc.name}" interface="${selectedPort.split(' ')[0]}.vlan${vlan} VPLS${evc.vpls_id} ${evc.name}"`}
                          <br />
                        </>
                      )
                    : (
                        <>
                          {`/interface/bridge/port/add bridge="bridge VPLS${evc.vpls_id} ${evc.name}" interface="${selectedPort}"`}
                          <br />
                        </>
                      )}

                  {/* For each vpls peer */}
                  {vplsPeers.map((peer) => {
                    if (peer == selectedIp) {

                    }
                    else {
                      return (
                        <span key={`${endpoint.id}-${peer}`}>
                          {`/interface/vpls/add peer="${peer}" disabled=no arp=disabled cisco-static-id=${evc.vpls_id} pw-l2mtu=1550 mtu=1550 name="vpls${evc.vpls_id} ${evc.name}-${peer}"`}
                          <br />
                          {`/interface/bridge/port/add bridge="bridge VPLS${evc.vpls_id} ${evc.name}" interface="vpls${evc.vpls_id} ${evc.name}-${peer}" horizon=1`}
                          <br />
                        </span>
                      )
                    }
                  })}

                </code>
              )}
              {selectedVendor == 'brocade' && (
                <code>
                  configure terminal
                  <br />
                  router mpls
                  <br />
                  vpls
                  {' '}
                  {evc.name}
                  {' '}
                  {evc.vpls_id}
                  <br />
                  vpls-mtu 1600
                  <br />
                  {vplsPeers.map((peer) => {
                    if (peer == selectedIp) {

                    }
                    else {
                      return (
                        <span key={`${endpoint.id}-${peer}`}>
                          vpls-peer
                          {' '}
                          {peer}
                          <br />
                        </span>
                      )
                    }
                  },
                  )}
                  vlan
                  {' '}
                  {vlan}
                  <br />
                  {tagged
                    ? (
                        <>
                          tagged e
                          {' '}
                          {selectedPort}
                          <br />
                        </>
                      )
                    : (
                        <>
                          untagged e
                          {' '}
                          {selectedPort}
                          <br />
                        </>
                      )}
                  end
                  <br />
                  write memory
                  <br />
                </code>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
