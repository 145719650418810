import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getAccessToken } from '../../../../services/auth.service'

/*
id	3
zipFileName	"719e81f6b142417310971c4fecdd333725b467044e0c5fb82e8232ffbcf3f54b6.zip"
workingDirectory	"/cephfs/bagimport/3/"
status	"running"
extractionStatus	"complete"
analysisStatus	"complete"
processingStatus	"running"
optimizeStatus	"pending"
bagExtractVersion	"20231008"
WPL_count	1
OPR_count	35
NUM_count	1237
PND_count	2173
LIG_count	2
STA_count	7
VBO_count	2286
WPL_progress	1
OPR_progress	35
NUM_progress	1237
PND_progress	2173
LIG_progress	2
STA_progress	7
VBO_progress	2286
WPL_extract	0
OPR_extract	0
NUM_extract	0
PND_extract	0
LIG_extract	0
STA_extract	0
VBO_extract	0
WPL_optimize_count	2621
WPL_optimize_progress	2621
OPR_optimize_count	292143
OPR_optimize_progress	292143
NUM_search_count	486760
NUM_search_progress	8264
createdAt	"2023-11-06T13:36:45.072Z"
updatedAt	"2023-11-13T13:03:17.000Z"
deletedAt	null
 */

export function ImportJob() {
  const { id } = useParams()

  const progressQuery = useQuery({
    queryKey: ['bag', 'import', id],
    queryFn: async () => {
      const response = await fetch(`/api/bag/import/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
    refetchInterval: 1000,
  })

  if (progressQuery.isLoading) {
    return <div>Loading...</div>
  }

  if (progressQuery.isError) {
    return <div>Error</div>
  }

  const progress = progressQuery.data

  // Card color:
  // - pending: gray
  // - running: primary
  // - complete: success
  // - error: danger
  let analysisCardStatus
  let extractionCardStatus
  let processingCardStatus
  let optimizeCardStatus

  switch (progress.analysisStatus) {
    case 'pending':
      analysisCardStatus = 'card card-gray'
      break
    case 'running':
      analysisCardStatus = 'card card-primary'
      break
    case 'complete':
      analysisCardStatus = 'card card-success'
      break
    case 'failed':
      analysisCardStatus = 'card card-danger'
      break
  }

  switch (progress.extractionStatus) {
    case 'pending':
      extractionCardStatus = 'card card-gray'
      break
    case 'running':
      extractionCardStatus = 'card card-primary'
      break
    case 'complete':
      extractionCardStatus = 'card card-success'
      break
    case 'failed':
      extractionCardStatus = 'card card-danger'
      break
  }

  switch (progress.processingStatus) {
    case 'pending':
      processingCardStatus = 'card card-gray'
      break
    case 'running':
      processingCardStatus = 'card card-primary'
      break
    case 'complete':
      processingCardStatus = 'card card-success'
      break
    case 'failed':
      processingCardStatus = 'card card-danger'
      break
  }

  switch (progress.optimizeStatus) {
    case 'pending':
      optimizeCardStatus = 'card card-gray'
      break
    case 'running':
      optimizeCardStatus = 'card card-primary'
      break
    case 'complete':
      optimizeCardStatus = 'card card-success'
      break
    case 'failed':
      optimizeCardStatus = 'card card-danger'
      break
  }

  return (
    <>
      {/* General information card */}
      <div className="card card-primary card-outline">
        <div className="card-header">
          <h3 className="card-title">Algemene informatie</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Volgnummer</td>
                    <td>{progress.id}</td>
                  </tr>
                  <tr>
                    <td>Zip bestand</td>
                    <td>{progress.zipFileName}</td>
                  </tr>
                  <tr>
                    <td>Werkmap</td>
                    <td>{progress.workingDirectory}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{progress.status}</td>
                  </tr>
                  <tr>
                    <td>Aangemaakt</td>
                    <td>{new Date(progress.createdAt).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>Laatst bijgewerkt</td>
                    <td>{new Date(progress.updatedAt).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Analysis card */}
      <div className={analysisCardStatus}>
        <div className="card-header">
          <h3 className="card-title">Analyse</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              Analyseren van bestand...
            </div>
          </div>
        </div>
      </div>

      {/* Extraction card */}
      <div className={extractionCardStatus}>
        <div className="card-header">
          <h3 className="card-title">Extractie</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Bestand</th>
                    <th style={{ width: '85%' }}>Voortgang</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Teller</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>WPL</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.WPL_extract / progress.WPL_count * 100}%` }}>{progress.WPL_extract / progress.WPL_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.WPL_extract}
                      {' '}
                      /
                      {progress.WPL_count}
                    </td>
                  </tr>
                  <tr>
                    <td>OPR</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.OPR_extract / progress.OPR_count * 100}%` }}>{progress.OPR_extract / progress.OPR_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.OPR_extract}
                      {' '}
                      /
                      {progress.OPR_count}
                    </td>
                  </tr>
                  <tr>
                    <td>NUM</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.NUM_extract / progress.NUM_count * 100}%` }}>{progress.NUM_extract / progress.NUM_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.NUM_extract}
                      {' '}
                      /
                      {progress.NUM_count}
                    </td>
                  </tr>
                  <tr>
                    <td>LIG</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.LIG_extract / progress.LIG_count * 100}%` }}>{progress.LIG_extract / progress.LIG_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.LIG_extract}
                      {' '}
                      /
                      {progress.LIG_count}
                    </td>
                  </tr>
                  <tr>
                    <td>STA</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.STA_extract / progress.STA_count * 100}%` }}>{progress.STA_extract / progress.STA_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.STA_extract}
                      {' '}
                      /
                      {progress.STA_count}
                    </td>
                  </tr>
                  <tr>
                    <td>PND</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.PND_extract / progress.PND_count * 100}%` }}>{progress.PND_extract / progress.PND_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.PND_extract}
                      {' '}
                      /
                      {progress.PND_count}
                    </td>
                  </tr>
                  <tr>
                    <td>VBO</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.VBO_extract / progress.VBO_count * 100}%` }}>{progress.VBO_extract / progress.VBO_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.VBO_extract}
                      {' '}
                      /
                      {progress.VBO_count}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Processing card */}
      <div className={processingCardStatus}>
        <div className="card-header">
          <h3 className="card-title">Verwerking</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Bestand</th>
                    <th style={{ width: '85%' }}>Voortgang</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Teller</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>WPL</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.WPL_progress / progress.WPL_count * 100}%` }}>{progress.WPL_progress / progress.WPL_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.WPL_progress}
                      {' '}
                      /
                      {progress.WPL_count}
                    </td>
                  </tr>
                  <tr>
                    <td>OPR</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.OPR_progress / progress.OPR_count * 100}%` }}>{progress.OPR_progress / progress.OPR_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.OPR_progress}
                      {' '}
                      /
                      {progress.OPR_count}
                    </td>
                  </tr>
                  <tr>
                    <td>NUM</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.NUM_progress / progress.NUM_count * 100}%` }}>{progress.NUM_progress / progress.NUM_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.NUM_progress}
                      {' '}
                      /
                      {progress.NUM_count}
                    </td>
                  </tr>
                  <tr>
                    <td>LIG</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.LIG_progress / progress.LIG_count * 100}%` }}>{progress.LIG_progress / progress.LIG_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.LIG_progress}
                      {' '}
                      /
                      {progress.LIG_count}
                    </td>
                  </tr>
                  <tr>
                    <td>STA</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.STA_progress / progress.STA_count * 100}%` }}>{progress.STA_progress / progress.STA_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.STA_progress}
                      {' '}
                      /
                      {progress.STA_count}
                    </td>
                  </tr>
                  <tr>
                    <td>PND</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.PND_progress / progress.PND_count * 100}%` }}>{progress.PND_progress / progress.PND_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.PND_progress}
                      {' '}
                      /
                      {progress.PND_count}
                    </td>
                  </tr>
                  <tr>
                    <td>VBO</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.VBO_progress / progress.VBO_count * 100}%` }}>{progress.VBO_progress / progress.VBO_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.VBO_progress}
                      {' '}
                      /
                      {progress.VBO_count}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Optimize card */}
      <div className={optimizeCardStatus}>
        <div className="card-header">
          <h3 className="card-title">Optimalisatie</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Bestand</th>
                    <th style={{ width: '85%' }}>Voortgang</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Teller</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>WPL</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.WPL_optimize_progress / progress.WPL_optimize_count * 100}%` }}>{progress.WPL_optimize_progress / progress.WPL_optimize_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.WPL_optimize_progress}
                      {' '}
                      /
                      {progress.WPL_optimize_count}
                    </td>
                  </tr>
                  <tr>
                    <td>OPR</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.OPR_optimize_progress / progress.OPR_optimize_count * 100}%` }}>{progress.OPR_optimize_progress / progress.OPR_optimize_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.OPR_optimize_progress}
                      {' '}
                      /
                      {progress.OPR_optimize_count}
                    </td>
                  </tr>
                  <tr>
                    <td>NUM</td>
                    <td>
                      <div className="progress progress-xl">
                        <div className="progress-bar bg-primary" style={{ width: `${progress.NUM_search_progress / progress.NUM_search_count * 100}%` }}>{progress.NUM_search_progress / progress.NUM_search_count * 100}</div>
                      </div>
                    </td>
                    <td>
                      {progress.NUM_search_progress}
                      {' '}
                      /
                      {progress.NUM_search_count}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
