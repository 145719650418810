import { useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap'
import { faComment, faDownload, faPlus, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MDBTooltip } from 'mdb-react-ui-kit'
import { toast } from 'react-toastify'
import { useUser } from '../../../providers/UserProvider'
import { getAccessToken } from '../../../services/auth.service'
import type { PermissionsObject } from '../../../util/checkPermission'
import { getPermissions } from '../../../util/checkPermission'
import { FileUploadModal, RelationType } from '../../../components/FileUploadModal'
import { formatBytes } from '../../../util/helpers'
import { DeleteConfirmationModal } from '../../../components/DeleteConfirmationModal'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'

export function ProjectDocuments({
  projectId,
}: {
  projectId: string
}) {
  const { permissions } = useUser()

  const documentsPermissions: PermissionsObject = getPermissions('/frontend/modules/projecten/documents', permissions)

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [documentToDelete, setDocumentToDelete] = React.useState(null)

  // New state for DocViewer
  const [isDocViewerOpen, setIsDocViewerOpen] = React.useState(false)
  const [docUrl, setDocUrl] = React.useState('')

  const [documents, setDocuments] = React.useState([])

  const queryClient = useQueryClient()

  const documentsQuery = useQuery({
    queryKey: ['documents', projectId],

    queryFn: async () => {
      const response = await fetch(`/api/documents?projectId=${projectId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  React.useEffect(() => {
    if (documentsQuery.data) {
      setDocuments(documentsQuery.data)
    }
  }, [documentsQuery.data])

  const openDocument = async (documentId: string) => {
    try {
      const response = await fetch(`/api/documents/${documentId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const blob = await response.blob()

      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = () => {
        setDocUrl(reader.result as string)
        setIsDocViewerOpen(true) // Open the DocViewer overlay
      }
      reader.onerror = () => {
        throw new Error('Fail to load the file')
      }
    }
    catch (error) {
      console.error('Error opening document:', error)
    }
  }

  const deleteDocument = async () => {
    try {
      const response = await fetch(`/api/documents/${documentToDelete}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      queryClient.invalidateQueries({
        queryKey: ['documents', projectId],
      })

      // Close the modal
      setShowDeleteModal(false)
    }
    catch (error) {
      toast.error('Fout: Document niet verwijderd')
    }
  }

  const isMobile = window.innerWidth <= 768

  return (
    <>
      <Card border="primary">
        <Card.Header>
          <Card.Title>Documenten</Card.Title>
          <div className="card-tools">
            {documentsPermissions.create && (
              <button
                className="btn btn-primary"
                onClick={() => setIsModalOpen(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          <Table striped hover responsive className="text-nowrap">
            <thead>
              <tr>
                <th>Omschrijving</th>
                <th>Document</th>
                <th>Omvang</th>
                <th>Toegevoegd op</th>
                <th>Uploader</th>
                <th>Tools</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((document: any) => (
                <tr
                  key={document.id}
                  onDoubleClick={() => {
                    openDocument(document.id)
                  }}
                >
                  <td>
                    {document.description}
                    {document.note && (
                      <MDBTooltip
                        title={(
                          <span style={{ whiteSpace: 'pre-line' }}>
                            {document.note}
                          </span>
                        )}
                        tag="span"
                      >
                        <FontAwesomeIcon icon={faComment} className="ml-1" />
                      </MDBTooltip>
                    )}
                  </td>
                  <td>{document.filename}</td>
                  <td>{formatBytes(document.size)}</td>
                  <td>{new Date(document.created).toLocaleDateString()}</td>
                  <td>{`${document.createdBy.firstName} ${document.createdBy.lastName}`}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        setDocumentToDelete(document.id)
                        setShowDeleteModal(true)
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>

      </Card>

      <FileUploadModal
        relationType={RelationType.PROJECT}
        relationId={projectId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />

      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={deleteDocument}
      />

      {/* DocViewer overlay */}
      {isDocViewerOpen && (
        <Modal
          show
          onHide={() => setIsDocViewerOpen(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Document</Modal.Title>
            {/* Float right download button */}
            <Button
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                const link = document.createElement('a')
                link.href = docUrl
                link.download = 'document'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
              }}
              onClick={() => setIsDocViewerOpen(false)}
            >
              <FontAwesomeIcon icon={faX} style={{ color: 'black' }} />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <DocViewer
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false,
                },
              }}
              documents={[{ uri: docUrl }]}
              pluginRenderers={DocViewerRenderers}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
