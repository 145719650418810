import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHouseCircleExclamation, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { getAccessToken } from '../../services/auth.service'

import importGIF from './melding.gif'

interface Customer {
  id: string
  Account_Name: string
}

interface ApiInfo {
  per_page: number
  next_page_token: string
  count: number
  sort_by: string
  page: number
  previous_page_token: string
  page_token_expiry: string
  sort_order: string
  more_records: boolean
}

interface ApiResponse {
  data: Customer[]
  info?: ApiInfo
}

export function Customers() {
  const [nameFilter, setNameFilter] = React.useState('')
  const [loadingAnimation, setLoadingAnimation] = React.useState(false)
  const [oldData, setOldData] = React.useState<ApiResponse>({
    data: [],
    info: undefined,
  })

  const { isLoading, error, data } = useQuery({
    queryKey: ['companies', {
      nameFilter,
    }],

    queryFn: async () => {
      let queryString = '/api/crm/companies?limit=25'

      if (nameFilter) {
        queryString += `&naam=${encodeURIComponent(nameFilter)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return await response.json()
    },
  })

  React.useEffect(() => {
    if (data) {
      setOldData(data)
    }
  }, [data])

  const navigate = useNavigate()

  const handleRefresh = async () => {
    setLoadingAnimation(true)
    // Execute a get to /api/crm
    const response = await fetch('/api/crm', {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${getAccessToken()}`,
      }),
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }
  }

  React.useEffect(() => {
    if (loadingAnimation) {
      setTimeout(() => { // 1 Minute
        setLoadingAnimation(false)
      }, 60000)
    }
  }, [loadingAnimation])

  return (
    (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            Companies
          </h3>
          <div className="card-tools">
            {/* Add button */}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                navigate('/customers/lostAndFound')
              }}
            >
              <FontAwesomeIcon icon={faHouseCircleExclamation} />
&nbsp;&nbsp;Lost & Found
            </button>
          &nbsp;
            <button type="button" className="btn btn-primary" onClick={handleRefresh}>
              <FontAwesomeIcon icon={faRefresh} />
            </button>
          </div>
        </div>
        <div className="card-body table-responsive p-0">
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>Naam</th>
              </tr>
            </thead>
            <tbody>
              {/* Filter fields */}
              <tr>
                <td></td>
                <td>
                  <input type="text" className="form-control" value={nameFilter} onChange={e => setNameFilter(e.target.value)} />
                </td>
              </tr>
              {/* Data */}
              {/* If the page is loading and has no data / old data */}
              {isLoading && !data && !oldData && (
                <tr>
                  <td colSpan={7}>Loading...</td>
                </tr>
              )}
              {/* If error */}
              {error && (
                <tr>
                  <td colSpan={7}>Error</td>
                </tr>
              )}
              {/* If no data */}
              {!isLoading && !error && oldData && oldData.data.length === 0 && (
                <tr>
                  <td colSpan={7}>No data</td>
                </tr>
              )}
              {/* If data but loading, show rows greyed out */}
              {isLoading && !error && oldData && oldData.data.length > 0 && (
                oldData.data.map((customer: Customer) => (
                  <tr key={customer.id}>
                    {/* Pad ID to 16 digits */}
                    <td>{customer.id}</td>
                    <td>{customer.Account_Name}</td>
                  </tr>
                ))
              )}

              {/* If data */}
              {!isLoading && !error && data && data.data.map((customer: Customer) => (
              // On double click, go to adres page
                (
                  <tr key={customer.id} onDoubleClick={() => navigate(`/customers/${customer.id}`)}>
                    <td>{customer.id}</td>
                    <td>{customer.Account_Name}</td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
        {loadingAnimation && (
          <div id="modal-loading" className="modal fade show" style={{ display: 'block' }} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center">
                    <img src={importGIF} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  )
}

export default Customers
