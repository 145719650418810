import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getAccessToken } from '../../../../services/auth.service'

interface NotesType {
  id: string
  text: string
  createdBy: {
    id: string
    name: string
  }
  editedBy: {
    id: string
    name: string
  } | null
  created: Date
  updated: Date
}

export function AansluitingNotes({
  containerSizeRef,
}: {
  containerSizeRef: React.MutableRefObject<HTMLDivElement | null>
}) {
  const { id } = useParams()

  const notesContainerRef = React.useRef<HTMLDivElement>(null)

  const [notes, setNotes] = React.useState<NotesType[]>([])

  React.useEffect(() => {
    if (containerSizeRef.current && notesContainerRef.current) {
      // Initially set the height to 0
      notesContainerRef.current.style.maxHeight = `0px`

      // Set a timeout or requestAnimationFrame to allow initial render before resizing
      setTimeout(() => {
        const maxHeight = containerSizeRef.current!.offsetHeight
        notesContainerRef.current!.style.maxHeight = `${maxHeight}px`
      }, 0) // or use requestAnimationFrame for smoother UI
    }
  }, [])

  const notesQuery = useQuery({
    queryKey: ['notes', id],
    queryFn: async () => {
      const response = await fetch(`/api/notes?connectionId=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      // Parse JSON and convert dates
      const json = await response.json()

      return json.map((note: any) => ({
        ...note,
        created: new Date(note.created),
        updated: new Date(note.updated),
      }))
    },
  })

  React.useEffect(() => {
    if (notesQuery.data) {
      setNotes(notesQuery.data)
    }
  }, [notesQuery.data])

  return (
    <div className="notes-container" style={{ overflowY: 'scroll' }} ref={notesContainerRef}>
      {notes.map(note => (
        <div key={note.id} className="card note-card">
          <div className="card-body">
            <p className="card-text">{note.text}</p>
          </div>
          <div className="card-footer d-flex justify-content-between">
            <small className="text-muted">
              {`Aangemaakt door:  ${note.createdBy.name} ${note.created.toLocaleString()}`}
              { note.editedBy && note.editedBy.name && note.editedBy.name !== note.createdBy.name ? `| Bijgewerkt door: ${note.editedBy.name} ${note.updated.toLocaleString()}` : '' }
            </small>
          </div>
        </div>
      ))}
    </div>
  )
}
