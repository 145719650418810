import * as React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { getAccessToken } from '../../../services/auth.service'
import { DarkModeContext } from '../../../providers/DarkModeProvider'
import { selectTheme } from '../../../util/helpers'

interface selectType {
  value: string
  label: string
}

export function EditProjectModal({ project, show, setEditModalOpen }: {
  project: any
  show: boolean
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()

  const [selectedUserGroup, setSelectedUserGroup] = React.useState<selectType | null>({
    value: project.group.id,
    label: project.group.name,
  })
  const [selectedProjectName, setSelectedProjectName] = React.useState(project.name)

  const [userGroupOptions, setUserGroupOptions] = React.useState<selectType[]>([])

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(false, theme)

  const userGroupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const response = await fetch(`/api/users/groups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  React.useEffect(() => {
    if (userGroupQuery.data) {
      setUserGroupOptions(userGroupQuery.data.map((group: any) => {
        return {
          label: group.name,
          value: group.id,
        }
      }))
    }
  }, [userGroupQuery.data])

  const handleEditProject = async () => {
    const response = await fetch(`/api/projects/${project.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        name: selectedProjectName,
        group_id: selectedUserGroup?.value,
      }),
    })

    if (!response.ok) {
      const errorObject = await response.json()
      toast.error(errorObject.error)
    }

    toast.success('Project updated successfully')

    setEditModalOpen(false)

    queryClient.invalidateQueries({
      queryKey: ['project', project.id],
    })
  }

  return (
    <Modal show={show} onHide={() => setEditModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="projectName">
            <Form.Label>Project Name</Form.Label>
            <Form.Control type="text" value={selectedProjectName} onChange={e => setSelectedProjectName(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="userGroup">
            <Form.Label>User Group</Form.Label>
            <Select
              options={userGroupOptions}
              value={selectedUserGroup}
              onChange={(selectedOption: any) => setSelectedUserGroup(selectedOption)}
              theme={selectStyle}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setEditModalOpen(false)}>Close</Button>
        <Button variant="primary" onClick={handleEditProject}>Save changes</Button>
      </Modal.Footer>
    </Modal>
  )
}
