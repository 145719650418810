import React from 'react'
import type { ButtonProps } from 'react-bootstrap/Button'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

export interface AppButtonProps extends ButtonProps {
  children: any
  block?: boolean
  isLoading?: boolean
  disabled?: boolean
  icon?: string
  theme?: string
}

function AppButton({
  children,
  isLoading,
  theme = 'primary',
  disabled,
  ...otherProps
}: AppButtonProps): JSX.Element {
  let spinnerTemplate

  if (isLoading === true) {
    spinnerTemplate = (
      <Spinner
        className="ml-2"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    (
      <Button {...otherProps} variant={theme} disabled={isLoading || disabled} className={`${otherProps.className ? otherProps.className : ''} ${otherProps.block ? 'btn-block' : ''}`}>
        {children}
        {spinnerTemplate}
      </Button>
    )
  )
}

export default AppButton
