import React from 'react'

// Define the prop types using TypeScript
interface DeleteConfirmationModalProps {
  show: boolean
  handleClose: () => void
  handleConfirm: () => void
}

export function DeleteConfirmationModal({ show, handleClose, handleConfirm }: DeleteConfirmationModalProps) {
  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Deletion</h5>
            <button type="button" className="close" onClick={handleClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Are you sure you want to delete this document?
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Cancel</button>
            <button type="button" className="btn btn-danger" onClick={handleConfirm}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}
