import React from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { toast } from 'react-toastify'
import Button from '../../../../components/Button'
import { getAccessToken } from '../../../../services/auth.service'
import { DarkModeContext } from '../../../../providers/DarkModeProvider'
import { selectTheme } from '../../../../util/helpers'

interface selectType {
  value: string
  label: string
}

interface AansluitingEditModalProps {
  aansluitingId: string
  defaultProject: {
    id: string
    name: string
  }
  defaultCompany: {
    id: string
    name: string
  }
  defaultSpeed: string
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const speedOptions = [
  { value: '-5', label: 'Cable Only' },
  { value: '-1', label: 'FTU Only' },
  { value: '0', label: 'Inactief' },
  { value: '2', label: '2 Mbit/s (Alarm)' },
  { value: '25', label: '25 Mbit/s' },
  { value: '50', label: '50 Mbit/s' },
  { value: '100', label: '100 Mbit/s' },
  { value: '200', label: '200 Mbit/s' },
  { value: '250', label: '250 Mbit/s' },
  { value: '500', label: '500 Mbit/s' },
  { value: '1000', label: '1000 Mbit/s' },
  { value: '-2', label: 'Dark Fiber' },
  { value: '-4', label: 'DWDM Wave' },
  { value: '-3', label: 'CWDM Wave' },
]

export const AansluitingEditModal: React.FC<AansluitingEditModalProps> = ({
  aansluitingId,
  defaultProject,
  defaultCompany,
  defaultSpeed,
  setEditModalOpen,
}) => {
  const [projecten, setProjecten] = React.useState([])
  const [bedrijven, setBedrijven] = React.useState([])
  const [bedrijfSearch, setBedrijfSearch] = React.useState('')
  const [projectSearch, setProjectSearch] = React.useState('')
  const [selectedProject, setSelectedProject] = React.useState<selectType | null>({
    label: defaultProject.name,
    value: defaultProject.id,
  })
  const [selectedBedrijf, setSelectedBedrijf] = React.useState<selectType | null>({
    label: defaultCompany.name,
    value: defaultCompany.id,
  })
  const [selectedSpeed, setSelectedSpeed] = React.useState<string>(`${defaultSpeed}`)

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const queryClient = useQueryClient()

  const projectenQuery = useQuery({
    queryKey: ['projecten', {
      projectSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/projects?limit=5'

      if (projectSearch) {
        queryString += `&naam=${encodeURIComponent(projectSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (projectenQuery.data) {
      setProjecten(projectenQuery.data.result.map((project: any) => {
        return {
          label: `${project.usergroup.name} | ${project.name}`,
          value: project.id,
        }
      }))
    }
  }, [projectenQuery.data])

  const bedrijvenQuery = useQuery({
    queryKey: ['bedrijven', {
      bedrijfSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/crm/companies?limit=5'

      if (bedrijfSearch) {
        queryString += `&naam=${encodeURIComponent(bedrijfSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (bedrijvenQuery.data) {
      setBedrijven(bedrijvenQuery.data.data.map((bedrijf: any) => {
        return {
          label: bedrijf.Account_Name,
          value: bedrijf.id,
        } as selectType
      }))
    }
  }, [bedrijvenQuery.data])

  const handleSubmit = async () => {
    const response = await fetch(`/api/netwerk/aansluitingen/${aansluitingId}`, {
      method: 'PATCH',
      headers: new Headers({
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        project_id: selectedProject?.value,
        company_id: selectedBedrijf?.value,
        speed: selectedSpeed,
      }),
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het opslaan')
      throw new Error(response.statusText)
    }

    toast.success('Bedrijf succesvol opgeslagen')

    queryClient.invalidateQueries({
      queryKey: ['aansluiting', aansluitingId],
    })

    setEditModalOpen(false)
  }

  return (
    <Modal show onHide={() => { setEditModalOpen(false) }}>
      <Modal.Header closeButton>
        <Modal.Title>Aansluiting bewerken</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Bedrijf
            </Form.Label>
            <Col sm="10">
              <Select
                options={bedrijven}
                noOptionsMessage={
                  () => 'Geen bedrijven gevonden'
                }
                theme={selectStyle}
                value={selectedBedrijf}
                placeholder="Zoek een bedrijf"
                isLoading={bedrijvenQuery.isLoading}
                onInputChange={value => setBedrijfSearch(value)}
                onChange={value => setSelectedBedrijf(value)}
                menuPlacement="auto"
              />
            </Col>
          </Form.Group>
          <br />
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Project
            </Form.Label>
            <Col sm="10">
              <Select
                options={projecten}
                onChange={(value) => { setSelectedProject(value) }}
                value={selectedProject}
                onInputChange={(e) => { setProjectSearch(e) }}
                isLoading={projectenQuery.isLoading}
                placeholder="Zoek een project"
                theme={selectStyle}
                noOptionsMessage={
                  () => 'Geen project gevonden'
                }
                menuPlacement="auto"
              />
            </Col>
          </Form.Group>
          <br />
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Snelheid
            </Form.Label>
            <Col sm="10">
              <Creatable
                theme={selectStyle}
                options={speedOptions}
                value={speedOptions.filter(option => option.value == selectedSpeed)[0]}
                onChange={value => setSelectedSpeed(value ? value.value : '')}
                onCreateOption={(value) => {
                  speedOptions.push({ label: `Anders: ${value} Mbit/s`, value })
                  console.log(`Anders: ${value} Mbit/s`)
                  setSelectedSpeed(value)
                }}
                placeholder="Selecteer een snelheid"
                formatCreateLabel={value => `Anders: ${value} Mbit/s`}
                menuPlacement="auto"
                createOptionPosition="first"
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setEditModalOpen(false)}>Annuleren</Button>
        <Button variant="primary" onClick={handleSubmit}>Opslaan</Button>
      </Modal.Footer>
    </Modal>
  )
}
