interface PasswordRequirements {
  lowercase: boolean
  uppercase: boolean
  number: boolean
  specialCharacter: boolean
  length: number
}

export function validatePassword({
  password,
  requirements,
}: {
  password: string
  requirements: PasswordRequirements
}): boolean {
  const lowercaseRegex = /[a-z]/
  const uppercaseRegex = /[A-Z]/
  const numberRegex = /\d/
  const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/

  if (requirements.lowercase && !lowercaseRegex.test(password)) {
    return false
  }

  if (requirements.uppercase && !uppercaseRegex.test(password)) {
    return false
  }

  if (requirements.number && !numberRegex.test(password)) {
    return false
  }

  if (requirements.specialCharacter && !specialCharacterRegex.test(password)) {
    return false
  }

  if (password.length < requirements.length) {
    return false
  }

  return true
}

export function generatePassword(requirements: PasswordRequirements): string {
  const lowercase = 'abcdefghjkmnpqrstuvwxyz'
  const uppercase = 'ABCDEFGHJKMNPQRSTUVWXYZ'
  const numbers = '0123456789'
  const specialCharacters = '!@#$%^*?'

  let passwordSet = ''

  if (requirements.lowercase) {
    passwordSet += lowercase
  }

  if (requirements.uppercase) {
    passwordSet += uppercase
  }

  if (requirements.number) {
    passwordSet += numbers
  }

  if (requirements.specialCharacter) {
    passwordSet += specialCharacters
  }

  let password = ''

  while (!password) {
    password = Array(requirements.length)
      .fill(passwordSet)
      .map(x => x[Math.floor(Math.random() * x.length)])
      .join('')

    if (!validatePassword({ password, requirements })) {
      password = ''
    }
  }

  return password
}

export function validateSsid(ssid: string): boolean {
  return !ssid.includes('&')
}
