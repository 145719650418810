import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAccessToken } from '../../../services/auth.service'

interface Connections {
  /*
  {
      id: connection.id,
      address: {
        id: connection.bag_num.id,
        zipcode: connection.bag_num.zipcode,
        homeNumber: connection.bag_num.homeNumber,
        homeLetter: connection.bag_num.homeLetter,
        homeAddition: connection.bag_num.homeAddition,
        street: connection.bag_num.opr.name,
        city: connection.bag_num.opr.wpl.name,
      },
      speed: connection.speed,
      type: connection.type,
    }
  */
  id: string
  address: {
    id: string
    zipcode: string
    homeNumber: string
    homeLetter: string
    homeAddition: string
    street: string
    city: string
  }
  speed: number
  type: string
}

export function Customer() {
  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()

  const [customer, setCustomer] = React.useState(null)

  const [connections, setConnections] = React.useState<Connections>()

  const customerQuery = useQuery({
    queryKey: ['customer', id],

    queryFn: async () => {
      const response = await fetch(`/api/crm/companies/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  React.useEffect(() => {
    if (customerQuery.data) {
      setCustomer(customerQuery.data)
    }
  }, [customerQuery.data])

  const connectionsQuery = useQuery({
    queryKey: ['customer', 'connections', id],

    queryFn: async () => {
      const response = await fetch(`/api/crm/companies/${id}/connections`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  React.useEffect(() => {
    if (connectionsQuery.data) {
      setConnections(connectionsQuery.data)
    }
  }, [connectionsQuery.data])

  console.log(customer, connections)

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            {customerQuery.isLoading
              ? 'Loading...'
              : customerQuery.isError
                ? 'An error has occurred'
                : customerQuery.data.name}
          </h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              {/* Button open in zoho */}
              <button className="btn btn-primary btn-sm float-right" onClick={() => { window.open(`https://crm.zoho.eu/crm/org20086060573/tab/Accounts/${id}`, '_blank') }}>Open in Zoho</button>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Aansluitingen</h3>
        </div>
        <div className="card-body">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Address</th>
                <th>Speed</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {connectionsQuery.isLoading
                ? 'Loading...'
                : connectionsQuery.isError
                  ? 'An error has occurred'
                  : connectionsQuery.data.map((connection: Connections) => (
                    <tr key={connection.id} onDoubleClick={() => { navigate(`/netwerkbeheer/aansluitingen/${connection.id}`) }}>
                      <td>
                        {connection.address.street}
                        {' '}
                        {connection.address.homeNumber}
                        {connection.address.homeLetter}
                        {connection.address.homeAddition ? `-${connection.address.homeAddition}` : ``}
                        ,
                        {' '}
                        {connection.address.zipcode}
                        {' '}
                        {connection.address.city}
                      </td>
                      <td>{connection.speed}</td>
                      <td>{connection.type}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </>

  )
}

export default Customer
