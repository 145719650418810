import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from 'react-bootstrap'
import { getAccessToken } from '../../../services/auth.service'
import {
  getWorkflowStatus,
  prettyProduct,
  prettySpeed,
} from '../../../util/helpers'
import { EditProjectModal } from './EditProjectModal'
import { ProjectDocuments } from './ProjectDocuments'
import { ProjectNotes } from './ProjectNotes'
import { faNote } from '@fortawesome/pro-solid-svg-icons'
import { CreateNoteModal, NoteRelationType } from '../../../components/CreateNoteModal'

export function Project() {
  const { id } = useParams()

  const [editModalOpen, setEditModalOpen] = React.useState(false)
  const [showNoteModal, setShowNoteModal] = React.useState(false)

  const projectgegevensRef = React.useRef<HTMLDivElement>(null)

  const projectQuery = useQuery({
    queryKey: ['project', id],

    queryFn: async () => {
      const response = await fetch(`/api/projects/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  const statsQuery = useQuery({
    queryKey: ['stats', 'project', id],

    queryFn: async () => {
      const response = await fetch(
        `/api/netwerk/aansluitingen/stats?projectID=${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAccessToken()}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  const navigate = useNavigate()

  if (projectQuery.isLoading)
    return <div>Loading...</div>

  if (!id)
    return <div>No project found</div>

  return (
    <>
      <Card className="card-primary">
        <CardHeader>
          <h1 className="card-title">
            Project:
            {' '}
            {projectQuery.data.name}
            {' '}
            (
            {projectQuery.data.connections.length}
            )
          </h1>
          <div className="card-tools">
            <Button
              variant="secondary"
              onClick={() => {
                setEditModalOpen(true)
              }}
            >
              <FontAwesomeIcon icon={faPen} />
              {' '}
&nbsp; Edit
            </Button>
            <button
              type="button"
              className="btn btn-primary m-1"
              onClick={() => {
                setShowNoteModal(true)
              }}
            >
              <FontAwesomeIcon icon={faNote} />
            </button>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={12} lg={6} ref={projectgegevensRef}>
              <Row>
                <Col md={12} lg={6}>
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>{projectQuery.data.connections.length}</h3>
                      <p>Aansluitingen</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-network-wired" />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={6}>
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{statsQuery.data?.ftuCount}</h3>
                      <p>FTU / Cable only</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-network-wired" />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={6}>
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>{statsQuery.data?.inactiveCount}</h3>
                      <p>Inactieve aansluitingen</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-network-wired" />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={6}>
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>{statsQuery.data?.abboCount}</h3>
                      <p>Actieve aansluitingen</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-network-wired" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12} lg={6}>
              <ProjectNotes containerSizeRef={projectgegevensRef} />
            </Col>
          </Row>
          <Card>
            <CardHeader>
              <h4>Aansluitingen</h4>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Straat</th>
                    <th>Postcode</th>
                    <th>Plaats</th>
                    <th>Bedrijfsnaam</th>
                    <th>Product</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {projectQuery.data
                  && projectQuery.data.connections.map((connection: any) => (
                    <tr
                      key={connection.id}
                      onDoubleClick={() => {
                        navigate(
                            `/netwerkbeheer/aansluitingen/${connection.id}`,
                        )
                      }}
                    >
                      {(connection.address.homeAddition && (
                        <td>
                          {connection.address.street}
                          {' '}
                          {connection.address.number}
                          {connection.address.homeLetter}
                          -
                          {connection.address.homeAddition}
                        </td>
                      )) || (
                        <td>
                          {connection.address.street}
                          {' '}
                          {connection.address.number}
                          {connection.address.homeLetter}
                        </td>
                      )}
                      <td>{connection.address.zipcode}</td>
                      <td>{connection.address.city}</td>
                      <td>{connection.company.name}</td>
                      <td>
                        {prettyProduct(connection.product.type)}
                        {' '}
                        {prettySpeed(connection.product.speed)}
                      </td>
                      <td>
                        <span
                          className="badge badge-secondary"
                          style={{
                            fontSize: '1rem',
                          }}
                        >
                          {getWorkflowStatus(connection.status)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <ProjectDocuments projectId={id} />
        </CardBody>
      </Card>
      <EditProjectModal
        project={projectQuery.data}
        show={editModalOpen}
        setEditModalOpen={setEditModalOpen}
      />

      <CreateNoteModal
        show={showNoteModal}
        handleClose={() => setShowNoteModal(false)}
        relationType={NoteRelationType.PROJECT}
        relationId={id}
      />
    </>
  )
}
