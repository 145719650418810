import React, { useCallback, useEffect, useRef, useState } from 'react'
import 'leaflet/dist/leaflet.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'proj4leaflet'
import * as Mapbox from 'react-map-gl'
import { useLocation, useNavigate } from 'react-router-dom'
import { ConnectionsLayer } from './ConnectionsLayer'
import ConnectionMarker from './ConnectionMarker'
import GeocoderControl from './MapGeoCoder'

const MAPBOX_TOKEN = 'pk.eyJ1IjoibWV0cm90eXJhbm5vIiwiYSI6ImNsbnFvYms5ZTB5b3MycW1weGt2NGg0b2cifQ.qjvD0HaDEy4Gi4TSpFASRA'

export interface HoverInfo {
  longitude: number
  latitude: number
  id: string
}

// Define type for viewState
interface ViewState {
  longitude: number
  latitude: number
  zoom: number
}

let timeout: ReturnType<typeof setTimeout> | undefined

function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
  return (...args: Parameters<T>) => {
    if (timeout)
      clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

export function Map() {
  const [viewState, setViewState] = useState<ViewState>({
    longitude: 4.5044114149512655,
    latitude: 52.03862173728208,
    zoom: 14,
  })
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null)
  const map = useRef(null)

  const navigate = useNavigate()
  const location = useLocation()

  const saveData = useCallback(() => {
    navigate(location.pathname, {
      state: { viewState },
      replace: true,
    })
  }, [navigate, location.pathname, viewState])

  const debouncedSaveData = useCallback(debounce(saveData, 100), [saveData])

  useEffect(() => {
    debouncedSaveData()
  }, [viewState, location.pathname, debouncedSaveData])

  useEffect(() => {
    if (location.state && (location.state as { viewState: ViewState }).viewState) {
      setViewState((location.state as { viewState: ViewState }).viewState)
    }
  }, [])

  const onHover = (event: any) => {
    const feature = event.features[0]

    if (!feature) {
      setHoverInfo(null)
      return
    }

    const [longitude, latitude] = feature.geometry.coordinates

    setHoverInfo({
      longitude,
      latitude,
      id: feature.properties.id,
    })
  }

  const onDblClick = (event: any) => {
    const feature = event.features[0]

    if (!feature) {
      return
    }

    navigate(`/netwerkbeheer/aansluitingen/${feature.properties.id}`)
  }

  return (
    <>
      <Mapbox.Map
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/metrotyranno/clnqoglz6009001pa563c1zz0"
        style={{
          height: 'calc(100vh - 56px - 57px - 16px - 16px)',
        }}
        initialViewState={{
          longitude: 4.5044114149512655,
          latitude: 52.03862173728208,
          zoom: 14,
        }}
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        attributionControl={false}
        projection={{ name: 'globe' }}
        onMouseMove={onHover as any}
        interactiveLayerIds={['connections', 'unclustered-point']}
        onDblClick={onDblClick as any}
        ref={map}
      >
        <GeocoderControl mapboxAccessToken={MAPBOX_TOKEN} position="top-left" />
        <ConnectionsLayer />
        <ConnectionMarker hoverInfo={hoverInfo} />
      </Mapbox.Map>
    </>
  )
}
