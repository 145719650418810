import type { ErrorInfo, ReactNode } from 'react'
import React, { Component } from 'react'
import type { Location } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

function GoBackButton() {
  const navigate = useNavigate()
  return (
    <button
      className="btn btn-primary"
      onClick={() => {
        navigate(-1)
      }}
    >
      Ga terug
    </button>
  )
}

class ErrorBoundaryComponent extends Component<ErrorBoundaryProps & { location: Location }, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps & { location: Location }) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      error,
      errorInfo,
    })
    console.error('Uncaught error:', error, errorInfo)
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps & { location: Location }) {
    if (this.props.location !== prevProps.location) {
      if (this.state.hasError) {
        this.setState({ hasError: false, error: null, errorInfo: null })
      }
    }
  }

  render() {
    const isMobile = window.innerWidth <= 768
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className="error-page"
          style={{
            width: isMobile ? '90%' : '40%',
          }}
        >
          <h2 className="headline text-danger">
            <i className="fas fa-exclamation-triangle text-danger" />
          </h2>
          <div className="error-content">
            <h3>Frontend Error</h3>
            <p>
              Er is een fout opgetreden bij het weergeven van dit onderdeel.
              <br />
              <br />
              <GoBackButton />
              <br />
              <br />
              <details style={{ whiteSpace: 'pre-wrap' }}>
                <br />
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo?.componentStack}
              </details>
            </p>
          </div>
        </div>
      )
    }

    // Normally, just render children
    return this.props.children
  }
}

function ErrorBoundary(props: ErrorBoundaryProps) {
  const location = useLocation()

  return <ErrorBoundaryComponent {...props} location={location} />
}

export default ErrorBoundary
