import React from 'react'

export function Error403() {
  return (
    <div className="error-page">
      <h1>403</h1>
      <p>U heeft geen toegang tot deze bron</p>
    </div>
  )
}
