import React, { useEffect, useState } from 'react'
import type { Location } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { IMenuItem } from '../modules/main/menu-sidebar/MenuSidebar'
import { useUser } from '../providers/UserProvider'
import { checkPermission } from '../util/checkPermission'

function MenuItem({ menuItem }: { menuItem: IMenuItem }) {
  const [isMenuExtended, setIsMenuExtended] = useState(false)
  const [isExpandable, setIsExpandable] = useState(false)
  const [isMainActive, setIsMainActive] = useState(false)
  const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const { permissions } = useUser()

  const toggleMenu = () => {
    setIsMenuExtended(!isMenuExtended)
  }

  const handleMainMenuAction = () => {
    if (isExpandable) {
      toggleMenu()
      return
    }
    navigate(menuItem.path ? menuItem.path : '/')
  }

  const calculateIsActive = (url: Location) => {
    setIsMainActive(false)
    setIsOneOfChildrenActive(false)
    if (isExpandable && menuItem && menuItem.children) {
      menuItem.children.forEach((item) => {
        // If path is contained in url, then it is active, if a child is active, then the parent is active
        if (item.path && url.pathname.includes(item.path)) {
          setIsOneOfChildrenActive(true)
          setIsMainActive(true)
          setIsMenuExtended(true)
        }
      })
    }
    else {
      if (menuItem.exact) {
        setIsMainActive(url.pathname === menuItem.path)
      }
      else {
        setIsMainActive(url.pathname.includes(menuItem.path ? menuItem.path : '/'))
      }
    }
  }

  useEffect(() => {
    if (location) {
      calculateIsActive(location)
    }
  }, [location, isExpandable, menuItem])

  useEffect(() => {
    if (!isMainActive && !isOneOfChildrenActive) {
      setIsMenuExtended(false)
    }
  }, [isMainActive, isOneOfChildrenActive])

  useEffect(() => {
    setIsExpandable(
      Boolean(menuItem && menuItem.children && menuItem.children.length > 0),
    )
  }, [menuItem])

  return (
    <li className={`nav-item${isMenuExtended ? ' menu-open' : ''}`}>
      <a
        className={`nav-link${
          isMainActive || isOneOfChildrenActive ? ' active' : ''
        }`}
        role="link"
        onClick={handleMainMenuAction}
        style={{ cursor: 'pointer' }}
      >
        {menuItem.icon && (
          <FontAwesomeIcon icon={menuItem.icon} className="nav-icon" />
        )}
        <p>{menuItem.name}</p>
        {isExpandable ? <i className="right fas fa-angle-left" /> : null}
      </a>

      {isExpandable && (
        <ul className="nav nav-treeview">
          {
            menuItem
            && menuItem.children
            && menuItem.children.map((item: IMenuItem) => {
              if (item.permission && checkPermission(item.permission, 'read', permissions) === false) {
                return null
              }

              return (
                <MenuItem key={item.name} menuItem={item} />
              )
            })
          }
        </ul>
      )}
    </li>
  )
}

export default MenuItem
