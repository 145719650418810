import type { OperationType, PermissionType, Permissions } from '../providers/UserProvider'

export function checkPermission(nodePath: string, operation: OperationType, permissions: Permissions): boolean {
  // Recursive helper function to find permission.
  function findPermission(path: string, operation: OperationType): PermissionType | undefined {
    // Check user permission at this level
    const userPermission = permissions.user.find(p => p.node === path)
    if (userPermission && userPermission[operation] !== undefined) {
      return userPermission[operation]
    }

    // Check group permission at this level
    const groupPermission = permissions.group.find(p => p.node === path)
    if (groupPermission && groupPermission[operation] !== undefined) {
      return groupPermission[operation]
    }

    // If we are at the root level, return undefined.
    if (path === '/')
      return undefined

    // If no permission is found at this level, move up in the tree and try again.
    const lastSlashIndex = path.lastIndexOf('/')
    const parentPath = lastSlashIndex > 0 ? path.substring(0, lastSlashIndex) : '/'
    return findPermission(parentPath, operation)
  }

  // If permissions are not loaded yet, return false.
  if (!permissions.user.length && !permissions.group.length)
    return false

  // Start the permission check from the specified node path.
  const permission = findPermission(nodePath, operation)
  return permission === 'ACCEPT'
}

export interface PermissionsObject {
  create: boolean
  read: boolean
  update: boolean
  delete: boolean
}

export function getPermissions(nodePath: string, permissions: Permissions): PermissionsObject {
  return {
    create: checkPermission(nodePath, 'create', permissions),
    read: checkPermission(nodePath, 'read', permissions),
    update: checkPermission(nodePath, 'update', permissions),
    delete: checkPermission(nodePath, 'delete', permissions),
  }
}
