import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from './App'
import UpdateGuard from './app/guards/UpdateGuard'
import { DarkModeProvider } from './app/providers/DarkModeProvider'
import { UserProvider } from './app/providers/UserProvider'
import ErrorBoundary from './app/components/errors/ErrorBoundary'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

root.render(
  <HelmetProvider>
    <QueryClientProvider client={new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: true,
          retry: false,
          // staleTime: 1000 * 60 * 5,
        },
      },
    })}
    >
      <BrowserRouter>
        <React.StrictMode>
          <DarkModeProvider>
            <UpdateGuard>
              <UserProvider>
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              </UserProvider>
            </UpdateGuard>
          </DarkModeProvider>
        </React.StrictMode>
      </BrowserRouter>
    </QueryClientProvider>
    <ToastContainer />
  </HelmetProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
