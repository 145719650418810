import React, { createContext, useEffect, useState } from 'react'

interface DarkModeProviderProps {
  children: React.ReactNode
}

interface DarkModeContextType {
  isDarkMode: boolean
  toggleDarkMode: () => void
}

export const DarkModeContext = createContext<DarkModeContextType>({
  isDarkMode: false,
  toggleDarkMode: () => {},
})

export function DarkModeProvider({
  children,
}: DarkModeProviderProps): JSX.Element {
  // If local preference is not set, set it to true
  if (localStorage.getItem('dark-mode') === null) {
    localStorage.setItem('dark-mode', 'true')
  }

  // Get preference from local storage
  const localPreference = localStorage.getItem('dark-mode')

  const [isDarkMode, setIsDarkMode] = useState(localPreference === 'true')

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode)
  }

  useEffect(() => {
    const root = document.getElementById('body')
    if (root) {
      if (isDarkMode) {
        root.classList.add('dark-mode')
      }
      else {
        root.classList.remove('dark-mode')
      }
    }

    localStorage.setItem('dark-mode', (isDarkMode).toString())
  }, [isDarkMode])

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  )
}
