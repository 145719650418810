import React from 'react'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getAccessToken } from '../services/auth.service'

export enum NoteRelationType {
  AANSLUITING = 'AANSLUITING',
  PROJECT = 'PROJECT',
}

interface CreateNoteModalProps {
  show: boolean
  handleClose: () => void
  relationType: NoteRelationType
  relationId: string
}

export function CreateNoteModal({ show, handleClose, relationType, relationId }: CreateNoteModalProps) {
  const [noteText, setNoteText] = React.useState('')

  const queryClient = useQueryClient()

  const handleCreate = async () => {
    const response = await fetch('/api/notes', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text: noteText,
        connectionId: relationType === NoteRelationType.AANSLUITING ? relationId : undefined,
        projectId: relationType === NoteRelationType.PROJECT ? relationId : undefined,
      }),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het aanmaken van de notitie')
      throw new Error(response.statusText)
    }

    toast.success('Notitie aangemaakt')

    setNoteText('')

    queryClient.invalidateQueries({
      queryKey: ['notes'],
    })

    handleClose()
  }

  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Maak notitie aan</h5>
            <button type="button" className="close" onClick={handleClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <textarea
              className="form-control"
              value={noteText}
              onChange={e => setNoteText(e.target.value)}
              placeholder="Enter note text..."
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Annuleer</button>
            <button type="button" className="btn btn-primary" onClick={handleCreate}>Maak notitie</button>
          </div>
        </div>
      </div>
    </div>
  )
}