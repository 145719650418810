import React from 'react'
import { DateTime } from 'luxon'
import { default as packageInfo } from '../../../../../package.json'

function Footer() {
  return (
    <footer className="main-footer">
      <strong>
        <span>
          Copyright © 2022 -
          {DateTime.now().toFormat('y')}
        </span>
        Datafiber Telecom B.V.
        {/* Space */}
        &nbsp;
      </strong>
      <span>Ontwikkeld door Brandon van der Loo</span>
      <div className="float-right d-none d-sm-inline-block">
        <b>Versie:</b>
        <span>
&nbsp;
          {packageInfo.version}
        </span>
      </div>
    </footer>
  )
}

export default Footer
