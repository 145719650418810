import * as React from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'
import { getAccessToken } from '../../../services/auth.service'
import { renderPreview } from '../../../util/dymo'

interface Router {
  id: string
  serialNumber: string
  model: string
  pppoeUsername: string
  pppoePassword: string
  wifiSSID: string
  wifiPassword: string
  status: string
  configVersion: string
}

function validatePppoePassword(password: string): boolean {
  // 1 uppercase, 1 lowercase, 1 digit, min length 8
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
  return regex.test(password)
}

function validateSsid(ssid: string): boolean {
  return !ssid.includes('&')
}

export function RouterProvisioningToevoegen() {
  const [serialNumber, setSerialNumber] = React.useState<string>('')
  const [model, setModel] = React.useState<string>('EX220')
  const [pppoeUsername, setPppoeUsername] = React.useState<string>('')
  const [pppoePassword, setPppoePassword] = React.useState<string>('')
  const [wifiSsid, setWifiSsid] = React.useState<string>('')
  const [wifiPassword, setWifiPassword] = React.useState<string>('')

  // State variables for error tracking
  const [ssidError, setSsidError] = React.useState(false)
  const [pppoePasswordError, setPppoePasswordError] = React.useState(false)

  // State variables for image data
  const [imageData, setImageData] = React.useState('')

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  React.useEffect(() => {
    setSsidError(!validateSsid(wifiSsid))
  }, [wifiSsid])

  React.useEffect(() => {
    setPppoePasswordError(!validatePppoePassword(pppoePassword))
  }, [pppoePassword])

  React.useEffect(() => {
    if (!pppoePassword || !wifiSsid || !wifiPassword)
      return
    if (wifiPassword.length < 8)
      return
    if (!validatePppoePassword(pppoePassword))
      return
    if (!validateSsid(wifiSsid))
      return

    const renderAndSetImageData = async () => {
      const router = {
        id: '',
        serialNumber,
        model,
        pppoeUsername,
        pppoePassword,
        wifiSSID: wifiSsid,
        wifiPassword,
        status: '',
        configVersion: '',
      }
      const image = await renderPreview(router)

      // Need to parse the image data as it is URL encoded
      setImageData(JSON.parse(image))
    }
    renderAndSetImageData()
  }, [pppoePassword, wifiSsid, wifiPassword])

  const createRouter = async (
    serialNumber: string,
    model: string,
    pppoeUsername: string,
    pppoePassword: string,
    wifiSsid: string,
    wifiPassword: string,
  ) => {
    // Validation Checks
    if (!validateSsid(wifiSsid)) {
      return toast.error('SSID mag het & teken niet bevatten.')
    }
    if (!validatePppoePassword(pppoePassword)) {
      return toast.error('PPPoE Wachtwoord moet bestaan uit 1 hoofdletter, 1 kleine letter, 1 nummer met een minimale lengte van 8.')
    }

    const queryString = `/api/provisioning/router`

    console.log(JSON.stringify({
      serialNumber,
      model,
      pppoeUsername,
      pppoePassword,
      wifiSsid,
      wifiPassword,
    }))

    const response = await fetch(queryString, {
      method: 'post',
      headers: new Headers({
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        serialNumber,
        model,
        pppoeUsername,
        pppoePassword,
        wifiSSID: wifiSsid,
        wifiPassword,
      }),
    })

    if (!response.ok) {
      return toast.error('Fout tijdens invoeren van de router')
    }

    toast.success('Router succesvol toegevoegd')

    queryClient.invalidateQueries({
      queryKey: ['provisioning/router'],
    })

    navigate('/provisioning/routers')
  }

  return (
    <div>
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Router Hardware</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Serienummer</label>
                <input
                  type="text"
                  className="form-control"
                  value={serialNumber}
                  onChange={(e) => { setSerialNumber(e.target.value) }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Model</label>
                <input
                  type="text"
                  className="form-control"
                  value={model}
                  onChange={(e) => { setModel(e.target.value) }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Internet Instellingen</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>PPPoE Username</label>
                <input
                  type="text"
                  className="form-control"
                  value={pppoeUsername}
                  onChange={(e) => { setPppoeUsername(e.target.value) }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>PPPoE Wachtwoord</label>
                <input
                  type="text"
                  className={`form-control ${pppoePasswordError ? 'is-invalid' : ''}`}
                  value={pppoePassword}
                  onChange={(e) => { setPppoePassword(e.target.value) }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Wifi Instellingen</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Wifi SSID</label>
                <input
                  type="text"
                  className={`form-control ${ssidError ? 'is-invalid' : ''}`}
                  value={wifiSsid}
                  onChange={(e) => { setWifiSsid(e.target.value) }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Wifi Wachtwoord</label>
                <input
                  type="text"
                  className="form-control"
                  value={wifiPassword}
                  onChange={(e) => { setWifiPassword(e.target.value) }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Sticker Preview</h3>
        </div>
        <div className="card-body">
          {/* returns imageData as base64 encoded png. */}
          <img src={`data:image/png;base64,${imageData}`} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12 ml-auto mt-1 col-xl-2">
          <button
            className="btn btn-block btn-primary"
            onClick={async () => {
              await createRouter(serialNumber, model, pppoeUsername, pppoePassword, wifiSsid, wifiPassword)
            }}
          >
            Opslaan
          </button>
        </div>
      </div>
    </div>
  )
}

export default RouterProvisioningToevoegen
