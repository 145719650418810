import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { getAccessToken } from '../../../services/auth.service'

export function ImportJobs() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [modalOpen, setModalOpen] = React.useState(false)
  const [file, setFile] = React.useState<File | null>(null)
  const [isUploading, setIsUploading] = React.useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)

  const importJobs = useQuery({
    queryKey: ['address', 'connections'],

    queryFn: async () => {
      const response = await fetch(`/api/bag/import`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  const handleSubmit = async () => {
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    setIsUploading(true)

    try {
      const response = await axios.post('/api/bag/uploadExtract', formData, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setUploadProgress(percentCompleted)
          }
        },
      })

      if (response.status === 201) {
        toast.success('File uploaded successfully!')
        queryClient.invalidateQueries({
          queryKey: ['address', 'connections'],
        })
        setModalOpen(false)
        setIsUploading(false)
      }
      else {
        toast.error('Error uploading the file.')
        setIsUploading(false)
      }
    }
    catch (error) {
      toast.error('Network error. Please try again.')
      setIsUploading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="card card-primary card-outline">
        <div className="card-header">
          <h3 className="card-title">Importeer BAG</h3>
          <div className="card-tools">
            {/* Upload modal */}
            <button type="button" className="btn btn-tool" onClick={() => setModalOpen(true)}>
              <i className="fas fa-upload"></i>
            </button>
            {/* Refresh button */}
            <button type="button" className="btn btn-tool" onClick={() => importJobs.refetch()}>
              <i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Volgnummer</th>
                    <th>Versie</th>
                    <th>Status</th>
                    <th>Aangemaakt</th>
                    <th>Laatst bijgewerkt</th>
                  </tr>
                </thead>
                <tbody>
                  {importJobs.data && importJobs.data.map((importJob: any) => (
                    <tr key={importJob.id} onDoubleClick={() => navigate(`/bag/import/${importJob.id}`)}>
                      <td>{importJob.id}</td>
                      <td>{importJob.bagExtractVersion}</td>
                      <td>{importJob.status}</td>
                      <td>{new Date(importJob.createdAt).toLocaleString()}</td>
                      <td>{new Date(importJob.updatedAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal fade ${modalOpen ? 'show' : ''}`} style={modalOpen ? { display: 'block' } : {}}>
        <div className="modal-dialog">
          <div className="modal-content">
            {isUploading && (
              <div className="overlay">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 36 36"
                  className="circular-progress"
                >
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9"
                    className="background"
                  />
                  <circle
                    cx="18"
                    cy="18"
                    r="15.9"
                    className="progress"
                    strokeDasharray={`${uploadProgress}, 100`}
                  />
                  <text x="18" y="18" textAnchor="middle" dominantBaseline="middle" fontSize="10" fill="white" transform="rotate(90 18,18)">{uploadProgress}</text>

                </svg>
              </div>
            )}
            <div className="modal-header">
              <h5 className="modal-title">Upload BAG extract</h5>
              <button type="button" className="close" onClick={() => setModalOpen(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input type="file" onChange={e => setFile(e.target.files ? e.target.files[0] : null)} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleSubmit}>Upload</button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
