import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import Select from 'react-select'
import { Card, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { faFileExport, faLock, faPrint } from '@fortawesome/free-solid-svg-icons'
import { faScannerGun } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import Button from '../../components/Button'
import { prettySpeed, readFileAsText, selectTheme } from '../../util/helpers'
import type { ApiResponse } from '../../util/helpers'
import { printTextLabelArray } from '../../util/dymo'
import { DarkModeContext } from '../../providers/DarkModeProvider'
import { getAccessToken } from '../../services/auth.service'

interface selectType {
  value: string
  label: string
}

interface Project {
  id: string
  name: string
  group: {
    id: string
    name: string
  }
  connections: {
    id: string
    address: {
      city: string
      street: string
      number: string
      zipcode: string
      homeLetter: string
      homeAddition: string
    }
    company: {
      id: string
      name: string
    }
    product: {
      type: string
      speed: number
    }
  }[]
}

interface DeviceModel {
  id: string
  deviceType: string
  deviceName: string
  vendor: string
  isPON: boolean
  isCPE: boolean
  defaultMonitoredPortIndex: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

interface DeviceApiResponse extends ApiResponse {
  result: any[]
}

export function GponPreProcessor() {
  const [selectedProject, setSelectedProject] = React.useState<selectType | null>(null)
  const [projecten, setProjecten] = React.useState([])
  const [projectSearch, setProjectSearch] = React.useState('')
  const [importData, setImportData] = React.useState<string[]>([])

  const [project, setProject] = React.useState<Project | null>(null)

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const projectenQuery = useQuery({
    queryKey: ['projecten', {
      projectSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/projects?limit=5'

      if (projectSearch) {
        queryString += `&naam=${encodeURIComponent(projectSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (projectenQuery.data) {
      setProjecten(projectenQuery.data.result.map((project: any) => {
        return {
          label: `${project.usergroup.name} | ${project.name}`,
          value: project.id,
        }
      }))
    }
  }, [projectenQuery.data])

  const projectQuery = useQuery({
    queryKey: ['project', selectedProject?.value],

    queryFn: async () => {
      if (!selectedProject)
        return null

      const response = await fetch(`/api/projects/${selectedProject?.value}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  React.useEffect(() => {
    if (projectQuery.data) {
      setProject(projectQuery.data)
    }
  }, [projectQuery.data])

  const handleFileUpload = async (event: any) => {
    const importedData = await readFileAsText(event.target.files[0])

    const parsedData = JSON.parse(importedData as string)

    const modems: string[] = []

    parsedData.forEach((modem: any) => {
      modems.push(modem.serial)
    })

    console.log(modems)

    setImportData(modems)
  }

  const handleExportModems = async ({
    differential,
  }: {
    differential: boolean
  }) => {
    const devices: {
      name: string
      serialNumber: string
    }[] = []

    if (!project)
      return

    await Promise.all(project.connections.map(async (connection) => {
      if (connection.product.speed < 0)
        return

      const response = await fetch(`/api/netwerk/devices?${new URLSearchParams({
        connectionID: connection.id,
      })
        }`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const modems = await response.json() as DeviceApiResponse

      if (!response.ok) {
        toast.error('Er is iets misgegaan bij het ophalen van de modems')
        throw new Error(response.statusText)
      }

      if (modems.total_rows == 0)
        return
      if (modems.total_rows > 1) {
        toast.error(`Er zijn meerdere modems gevonden voor ${connection.address.street} ${connection.address.number}${connection.address.homeLetter ? connection.address.homeLetter : ``}${connection.address.homeAddition ? `-${connection.address.homeAddition}` : ``}`)
        return
      }

      if (differential && importData.includes(modems.result[0].serialNumber))
        return

      if (!connection.product.speed) {
        devices.push({
          name: `${connection.address.street} ${connection.address.number}${connection.address.homeLetter ? connection.address.homeLetter : ``}${connection.address.homeAddition ? `-${connection.address.homeAddition}` : ``}`,
          serialNumber: modems.result[0].serialNumber,
        })
        return
      }

      devices.push({
        name: `${connection.address.street} ${connection.address.number}${connection.address.homeLetter ? connection.address.homeLetter : ``}${connection.address.homeAddition ? `-${connection.address.homeAddition}` : ``} - ${connection.company.name}`,
        serialNumber: modems.result[0].serialNumber,
      })
    }))

    const modemExport: { adminPassword: string, bandwidthLimit: { download: { enabled: boolean, limit: number }, upload: { enabled: boolean, limit: number } }, bridgeMode: { ports: { includeVLANs: never[], nativeVLAN: number, port: string }[] }, countryListId: string, enabled: boolean, lanAddress: string, lanProvisioned: boolean, mode: string, model: string, name: string, notes: string, ports: { id: string, speed: string }[], routerMode: { dhcpLeaseTime: number, dhcpPool: { rangeStart: string, rangeStop: string }, dhcpRelay: string, dhcpServerMode: string, dnsProxyEnabled: boolean, dnsResolvers: never[], firewallEnabled6: boolean, gateway: string, gateway6: string, ipv6Enabled: boolean, lanAddress6: string, lanMode6: string, nat: { ftp: boolean, pptp: boolean, rtsp: boolean, sip: boolean }, portForwards: never[], pppoeMode: string, pppoePassword: string, pppoeUser: string, routerAdvertisement: { mode: string, prefix: string }, upnpEnabled: boolean, wanAccessBlocked: boolean, wanAddress: string, wanAddress6: string, wanDnsResolvers: never[], wanMode: string, wanMode6: string, wanVLAN: number }, serial: string, services: { httpPort: number, sshEnabled: boolean, sshPort: number, telnetEnabled: boolean, telnetPort: number, ubntDiscoveryEnabled: boolean } }[] = []

    devices.forEach((device) => {
      modemExport.push({
        adminPassword: 'ubnt',
        bandwidthLimit: {
          download: {
            enabled: false,
            limit: 1,
          },
          upload: {
            enabled: false,
            limit: 1,
          },
        },
        bridgeMode: {
          ports: [
            {
              includeVLANs: [],
              nativeVLAN: 666,
              port: '1',
            },
          ],
        },
        countryListId: '1',
        enabled: true,
        lanAddress: '192.168.1.1/24',
        lanProvisioned: true,
        mode: 'bridge',
        model: 'Loco',
        name: `${device.name}`,
        notes: '',
        ports: [
          {
            id: '1',
            speed: 'auto',
          },
        ],
        routerMode: {
          dhcpLeaseTime: 600,
          dhcpPool: {
            rangeStart: '192.168.1.10',
            rangeStop: '192.168.1.250',
          },
          dhcpRelay: '',
          dhcpServerMode: 'enabled',
          dnsProxyEnabled: true,
          dnsResolvers: [],
          firewallEnabled6: true,
          gateway: '',
          gateway6: '',
          ipv6Enabled: false,
          lanAddress6: '',
          lanMode6: 'auto',
          nat: {
            ftp: true,
            pptp: true,
            rtsp: true,
            sip: false,
          },
          portForwards: [],
          pppoeMode: 'auto',
          pppoePassword: '',
          pppoeUser: '',
          routerAdvertisement: {
            mode: 'auto',
            prefix: '',
          },
          upnpEnabled: false,
          wanAccessBlocked: true,
          wanAddress: '',
          wanAddress6: '',
          wanDnsResolvers: [],
          wanMode: 'dhcp',
          wanMode6: 'slaac',
          wanVLAN: 1,
        },
        serial: `${device.serialNumber}`,
        services: {
          httpPort: 80,
          sshEnabled: false,
          sshPort: 22,
          telnetEnabled: false,
          telnetPort: 23,
          ubntDiscoveryEnabled: true,
        },
      })
    })

    const blob = new Blob([JSON.stringify(modemExport)], { type: 'application/json' })

    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = 'modems.json'
    link.click()

    toast.success(`${devices.length} modems geëxporteerd!`)
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <div className="card card-outline card-primary">
              <div className="card-header">
                <h3 className="card-title">Instellingen</h3>
              </div>
              <div className="card-body">
                {/* Selecteer Project */}
                <div className="form-group">
                  <label>Project</label>
                  <Select
                    options={projecten}
                    onChange={(value) => { setSelectedProject(value) }}
                    onInputChange={(e) => { setProjectSearch(e) }}
                    value={selectedProject}
                    isLoading={projectenQuery.isLoading}
                    placeholder="Zoek een project"
                    theme={selectStyle}
                    noOptionsMessage={
                      () => 'Geen project gevonden'
                    }
                    menuPlacement="auto"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <Card className="card-outline card-primary">
              <Card.Header>
                <Card.Title>Tools</Card.Title>
              </Card.Header>
              <Card.Body>
                <PrintStickerButton
                  project={project}
                />
                <Button block disabled={true}>
                  <FontAwesomeIcon icon={faScannerGun as IconProp} />
&nbsp;
                  Scan & Go
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="card-outline card-primary">
              <Card.Header>
                <Card.Title>Export Modems</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form className="mb-1">
                  <Form.Group>
                    <Form.Label>Upload een export</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      id="file"
                      accept=".json"
                      onChange={handleFileUpload}
                    />
                  </Form.Group>
                </Form>
                <Container>
                  <Row>
                    <Col>
                      <Button block disabled={!project} onClick={() => { handleExportModems({ differential: false }) }}>
                        <FontAwesomeIcon icon={faFileExport as IconProp} />
&nbsp;
                        Volledige Export
                      </Button>
                    </Col>
                    {importData.length > 0 && (
                      <Col>
                        <Button block disabled={!project} onClick={() => handleExportModems({ differential: true })}>
                          <FontAwesomeIcon icon={faFileExport as IconProp} />
&nbsp;
                          Differentiële Export
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">GPON Modem Voorbereiding</h3>
        </div>
        <div className="card-body">
          {/* Table */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Adres</th>
                <th>Bedrijf</th>
                <th>Product</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              {project?.connections.map((connection) => {
                return (
                  <AansluitingRow key={connection.id} connection={connection} />
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function AansluitingRow({ connection }: { connection: any }) {
  const [modems, setModems] = React.useState<DeviceApiResponse>({
    total_rows: 0,
    total_pages: 0,
    page: 0,
    result: [],
  })
  const [modalOpen, setModalOpen] = React.useState(false)
  const [serialNumber, setSerialNumber] = React.useState('')
  const [deviceModel, setDeviceModel] = React.useState<selectType | null>({
    label: 'uFiber Loco',
    value: 'f8473ee6-d972-48ff-a48e-3a76ee3a8d7a',
  })
  const [deviceModels, setDeviceModels] = React.useState<selectType[]>([])
  const [deviceName, setDeviceName] = React.useState(`CPE-${connection.address.zipcode}-${connection.address.number}${connection.address.homeLetter ? connection.address.homeLetter : ``}${connection.address.homeAddition ? `-${connection.address.homeAddition}` : ``}`)
  const [editName, setEditName] = useState(false)

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const queryClient = useQueryClient()

  const modemsQuery = useQuery({
    queryKey: ['modems', connection.id],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices?${new URLSearchParams({
        connectionID: connection.id,
      })
        }`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const json = await response.json() as DeviceApiResponse

      return json
    },
  })

  React.useEffect(() => {
    if (modemsQuery.data) {
      setModems(modemsQuery.data)
    }
  }, [modemsQuery.data])

  const deviceModelQuery = useQuery({
    queryKey: ['deviceModel'],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devicemodels`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  React.useEffect(() => {
    if (deviceModelQuery.data) {
      const ponModems = deviceModelQuery.data.filter((deviceModel: DeviceModel) => {
        return deviceModel.isPON
      })

      setDeviceModels(ponModems.map((deviceModel: DeviceModel) => {
        return {
          label: `${deviceModel.deviceName}`,
          value: deviceModel.id,
        }
      }))
    }
  }, [deviceModelQuery.data])

  const requiresPreparation = (modems.total_rows === 0 && connection.product.speed >= 0)
  const hasModem = (modems.total_rows > 0)

  const handleSubmit = async (event: any) => {
    // Fix for serialNumber format
    let fixedSerialNumber = serialNumber
    if (serialNumber.startsWith('UBNT')) {
      fixedSerialNumber = `UBNT${serialNumber.substring(4).toLowerCase()}`
    }

    const dataObject = {
      connectionId: connection.id,
      deviceModel: deviceModel?.value,
      deviceName,
      serialNumber: fixedSerialNumber,
    }

    const response = await fetch(`/api/netwerk/devices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(dataObject),
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het opslaan van de verbinding')
      throw new Error(response.statusText)
    }
    else {
      toast.success('Modem is succesvol toegevoegd!')
      queryClient.invalidateQueries({
        queryKey: ['modems', connection.id],
      })
      setModalOpen(false)
    }
  }

  return (
    <>
      <tr style={{
        backgroundColor: (hasModem ? 'lightgreen' : (requiresPreparation ? '' : 'lightcoral')),
      }}
      >
        <td>
          {connection.address.street}
          {' '}
          {connection.address.number}
          {connection.address.homeLetter}
          {connection.address.homeAddition ? `-${connection.address.homeAddition}` : ``}
          ,
          {' '}
          {connection.address.zipcode}
          {' '}
          {connection.address.city}
        </td>
        <td>{connection.company.name}</td>
        <td>{prettySpeed(connection.product.speed)}</td>
        <td>
          {requiresPreparation && (
            <button className="btn btn-sm btn-primary" onClick={() => { setModalOpen(true) }}>
              <i className="fas fa-plus"></i>
              {' '}
              Modem toevoegen
            </button>
          )}
        </td>
      </tr>
      {modalOpen && (
        <div className="modal fade show" style={{ display: 'block' }}>
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>
                Modem Toevoegen:
                {connection.address.street}
                {' '}
                {connection.address.number}
                {connection.address.homeLetter}
                {connection.address.homeAddition ? `-${connection.address.homeAddition}` : ``}
              </Modal.Title>
            </Modal.Header>
            {/* We only require serial number */}
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Modem Type</Form.Label>
                  <Select
                    options={deviceModels}
                    onChange={(value) => { setDeviceModel(value) }}
                    value={deviceModel}
                    isLoading={deviceModelQuery.isLoading}
                    placeholder="Selecteer een modem type"
                    theme={selectStyle}
                    noOptionsMessage={
                      () => 'Geen modem types gevonden'
                    }
                    menuPlacement="auto"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Modem Naam</Form.Label>
                  <InputGroup>
                    <div className="input-group">
                      <input
                        type="text"
                        id="modemName"
                        className="form-control"
                        value={deviceName}
                        onChange={event => setDeviceName(event.target.value)}
                        disabled={!editName}
                      />
                      {!editName && (
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() => setEditName(true)}
                          >
                            <FontAwesomeIcon icon={faLock} />
                          </button>
                        </div>
                      )}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Serienummer</Form.Label>
                  <Form.Control type="text" name="serialNumber" placeholder="Serienummer" onChange={(e) => { setSerialNumber(e.target.value) }} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" block className="test" disabled={!(serialNumber && deviceModel)} onClick={handleSubmit}>
                Toevoegen
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </>
  )
}

function PrintStickerButton({ project }: { project: Project | null }) {
  const handlePrintStickers = async () => {
    const stickers: string[] = []

    // If no project is selected, return
    if (!project)
      return

    // Push the address string to the stickers array
    await Promise.all(project.connections.map(async (connection) => {
      if (connection.product.speed < 0)
        return

      const response = await fetch(`/api/netwerk/devices?${new URLSearchParams({
        connectionID: connection.id,
      })
        }`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const modems = await response.json() as DeviceApiResponse

      if (!response.ok) {
        toast.error('Er is iets misgegaan bij het ophalen van de modems')
        throw new Error(response.statusText)
      }

      if (modems.total_rows > 0)
        return

      stickers.push(`${connection.address.street} ${connection.address.number}${connection.address.homeLetter ? connection.address.homeLetter : ``}${connection.address.homeAddition ? `-${connection.address.homeAddition}` : ``}`)
    }))

    await printTextLabelArray(stickers)

    toast.success('Stickers zijn geprint!')
  }

  if (!project) {
    return (
      <>
        <Button disabled={true} block>
          <FontAwesomeIcon icon={faPrint} />
          &nbsp;
          Print Stickers
        </Button>
      </>

    )
  }

  return (
    <>
      <Button block onClick={handlePrintStickers}>
        <FontAwesomeIcon icon={faPrint} />
&nbsp;
        Print Stickers
      </Button>
    </>
  )
}
