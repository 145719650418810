import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from '../services/auth.service'
import { getWorkflowStatus, prettyProduct, prettySpeed } from '../util/helpers'

export function NewestConnectionsCard() {
  const navigate = useNavigate()

  const orderBy = [
    {
      field: 'created',
      order: 'DESC',
    },
  ]

  const encodedOrderBy = encodeURIComponent(JSON.stringify(orderBy))

  const newestConnectionsQuery = useQuery({
    queryKey: ['newestConnections'],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/aansluitingen?orderBy=${encodedOrderBy}&limit=10`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok)
        throw new Error(response.statusText)

      const json = await response.json()
      return json
    },
  })

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Nieuwste aansluitingen</h5>
      </div>
      <div className="card-body">
        {newestConnectionsQuery.isLoading && (
          <div className="alert alert-info" role="alert">
            Loading...
          </div>
        )}
        {newestConnectionsQuery.isError && (
          <div className="alert alert-danger" role="alert">
            Error:
            {' '}
            {(newestConnectionsQuery.error as Error).message}
          </div>
        )}

        {newestConnectionsQuery.data && newestConnectionsQuery.data.total_rows === 0 && (
          <div className="alert alert-info" role="alert">
            Geen aansluitingen gevonden.
          </div>
        )}

        {newestConnectionsQuery.data && newestConnectionsQuery.data.total_rows !== 0 && (
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Bedrijf</th>
                  <th scope="col">Adres</th>
                  <th scope="col">Type</th>
                  <th scope="col">Snelheid</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {newestConnectionsQuery.data?.result.map((connection: any) => (
                  <tr key={connection.connection.id} onDoubleClick={() => navigate(`/netwerkbeheer/aansluitingen/${connection.connection.id}`)}>
                    <td>{connection.company.name}</td>
                    <td>{connection.adres.street}</td>
                    <td>{prettyProduct(connection.connection.type)}</td>
                    <td>{prettySpeed(connection.connection.speed)}</td>
                    <td>{getWorkflowStatus(connection.connection.workflow.status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
