import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getAccessToken } from '../../../services/auth.service'

interface Connections {
  id: string
  address: {
    id: string
    zipcode: string
    homeNumber: string
    homeLetter: string
    homeAddition: string
    street: string
    city: string
  }
  speed: number
  type: string
}

export function BagAddress() {
  const { id } = useParams()
  const navigate = useNavigate()

  const [connections, setConnections] = React.useState<Connections[]>()

  const connectionsQuery = useQuery({
    queryKey: ['address', 'connections', id],

    queryFn: async () => {
      const response = await fetch(`/api/bag/num/${id}/connections`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  React.useEffect(() => {
    if (connectionsQuery.data) {
      setConnections(connectionsQuery.data)
    }
  }, [connectionsQuery.data])

  return (
    <React.Fragment>
      {/* On the top of a page, show 2 collumns */}
      <div className="row">
        <div className="col-md-4">
          {/* Show general information */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Algemene informatie</h3>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          {/* Show the openlayers map */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Kaart</h3>
            </div>
            <div className="card-body">

            </div>
          </div>
        </div>
      </div>
      {/* Show all connections for this address */}
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Aansluitingen</h3>
        </div>
        <div className="card-body">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Bedrijf</th>
                <th>Project</th>
                <th>Aansluiting</th>
              </tr>
            </thead>
            <tbody>
              {connections && connections.map((connection: any) => (
                <tr key={connection.id} onDoubleClick={() => { navigate(`/netwerkbeheer/aansluitingen/${connection.id}`) }}>
                  <td>{connection.company.name}</td>
                  <td>{connection.project.name}</td>
                  <td>{`${connection.speed} Mbit/s ${connection.type}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Show subscriptions for this address */}
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Abonnementen</h3>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BagAddress
