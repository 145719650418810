import type { Dispatch, SetStateAction } from 'react'
import React, { useCallback } from 'react'
import { DarkModeContext } from '../../../providers/DarkModeProvider'
import UserDropdown from './user-dropdown/UserDropdown'

interface HeaderProps {
  sidebar: {
    get: boolean
    set: Dispatch<SetStateAction<boolean>>
  }
}

function Header(props: HeaderProps) {
  const isDev = process.env.NODE_ENV === 'development'
  const navbarVariant = isDev ? 'navbar-red' : 'navbar-blue'
  const headerBorder = true

  const { isDarkMode, toggleDarkMode } = React.useContext(DarkModeContext)

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand ${navbarVariant}`
    if (headerBorder) {
      classes = `${classes} border-bottom-0`
    }
    return classes
  }, [navbarVariant, headerBorder])

  return (
    <nav className={getContainerClasses()}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            onClick={() => props.sidebar.set(prev => !prev)}
            type="button"
            className="nav-link"
          >
            <i className="fas fa-bars" />
          </button>
        </li>
        <li className="nav-item">
          <button
            onClick={toggleDarkMode}
            type="button"
            className="nav-link"
          >
            {/* If class root has dark mode */}
            {isDarkMode
              ? (
                  <i className="fas fa-sun" />
                )
              : (
                  <i className="fas fa-moon" />
                )}
          </button>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <UserDropdown />
      </ul>
    </nav>
  )
}

export default Header
