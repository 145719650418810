import * as React from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { getAccessToken } from '../../../services/auth.service'

import { selectTheme } from '../../../util/helpers'
import { DarkModeContext } from '../../../providers/DarkModeProvider'

interface UrenRegistratie {
  company: {
    id: string
    name: string
  }
  users: [
    {
      id: string
      name: string
    },
  ]
  start_time: Date
  end_time: Date
  time_spent: number
  notes: string
  billable: boolean
}

interface selectType {
  value: string
  label: string
}

export function UrenRegistratieToevoegen() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [date, setDate] = React.useState<string>(new Date().toISOString().slice(0, 10))
  const [startTime, setStartTime] = React.useState<string>('')
  const [endTime, setEndTime] = React.useState<string>('')
  const [timeSpent, setTimeSpent] = React.useState<number>(0)
  const [notes, setNotes] = React.useState<string>('')
  const [billable, setBillable] = React.useState<boolean>(false)

  const [customers, setCustomers] = React.useState([])
  const [customerSearch, setCustomerSearch] = React.useState<string>('')
  const [selectedCustomer, setSelectedCustomer] = React.useState<selectType>()

  const [categories, setCategories] = React.useState<selectType[]>([])
  const [selectedCategory, setSelectedCategory] = React.useState<selectType>()

  const [users, setUsers] = React.useState<selectType[]>([])
  const [selectedUsers, setSelectedUsers] = React.useState<selectType[]>([])

  const customerQuery = useQuery({
    queryKey: ['customer', {
      customerSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/crm/companies?limit=5'

      if (customerSearch) {
        queryString += `&naam=${encodeURIComponent(customerSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (customerQuery.data) {
      setCustomers(customerQuery.data.data.map((bedrijf: any) => {
        return {
          label: bedrijf.Account_Name,
          value: bedrijf.id,
        } as selectType
      }))
    }
  }, [customerQuery.data])

  const userQuery = useQuery({
    queryKey: ['users'],

    queryFn: async () => {
      const response = await fetch('/api/users', {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (userQuery.data) {
      setUsers(userQuery.data.map((user: any) => {
        return {
          label: user.name,
          value: user.id,
        } as selectType
      }))
    }
  }, [userQuery.data])

  const categoryQuery = useQuery({
    queryKey: ['categories'],

    queryFn: async () => {
      const response = await fetch('/api/administratie/hour-registration/categories', {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (categoryQuery.data) {
      setCategories(categoryQuery.data.map((category: any) => {
        return {
          label: category.name,
          value: category.id,
        } as selectType
      }))
    }
  }, [categoryQuery.data])

  const handleSubmit = async () => {
    const data: any = {
      company_id: selectedCustomer,
      users: [...selectedUsers.map(user => user.value)],
      date,
      start_time: startTime,
      end_time: endTime,
      time_spent: timeSpent * 60,
      notes,
      billable,
    }

    const start_datetime = new Date(`${data.date}T${data.start_time}`)
    const end_datetime = new Date(`${data.date}T${data.end_time}`)

    if (start_datetime > end_datetime) {
      end_datetime.setDate(end_datetime.getDate() + 1)
    }

    const body = {
      company_id: data.company_id,
      user_id: data.users,
      category_id: selectedCategory?.value,
      start_time: start_datetime.toISOString(),
      end_time: end_datetime.toISOString(),
      time_spent: data.time_spent.toString(),
      notes: data.notes,
      billable: data.billable,
    }

    console.log(body)

    const response = await fetch('/api/administratie/hour-registration', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      }),
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      toast.error(`Er is iets misgegaan bij het toevoegen van de urenregistratie.`)
      throw new Error(response.statusText)
    }

    await queryClient.invalidateQueries({
      queryKey: ['urenregistraties'],
    })

    toast.success(`De urenregistratie is succesvol toegevoegd.`)

    navigate('/administratie/urenregistratie')
  }

  // Re-Calculate time spent
  React.useEffect(() => {
    const start_datetime = new Date(`${date}T${startTime}`)
    const end_datetime = new Date(`${date}T${endTime}`)

    if (start_datetime > end_datetime) {
      end_datetime.setDate(end_datetime.getDate() + 1)
    }

    const time_spent = (end_datetime.getTime() - start_datetime.getTime()) / 1000 / 60 / 60

    setTimeSpent(time_spent * selectedUsers.length)
  }, [date, startTime, endTime, selectedUsers])

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  // Mobile Friendly entry
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          Urenregistratie toevoegen
        </h3>
        <div className="card-tools">

        </div>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label>Relatie</label>
          <Select
            options={customers}
            onChange={(event: any) => { setSelectedCustomer(event.value) }}
            onInputChange={(value) => { setCustomerSearch(value) }}
            // value={customers.find(option => option.value === selectedCustomer)}
            isDisabled={false}
            placeholder="Zoek een klant"
            theme={selectStyle}
            noOptionsMessage={
              () => 'Geen groepen gevonden'
            }
            isLoading={customerQuery.isLoading}
          />
        </div>
        <div className="form-group">
          <label>Werknemer(s)</label>
          <Select
            options={users}
            onChange={(event: any) => { setSelectedUsers(event) }}
            // value={customers.find(option => option.value === selectedCustomer)}
            isDisabled={false}
            placeholder="Zoek een gebruiker"
            theme={selectStyle}
            noOptionsMessage={
              () => 'Geen gebruikers gevonden'
            }
            isLoading={userQuery.isLoading}
            isMulti
          />
        </div>

        {/* Categorie */}
        <div className="form-group">
          <label>Categorie</label>
          <Select
            options={categories}
            onChange={(event: any) => { setSelectedCategory(event) }}
            // value={customers.find(option => option.value === selectedCustomer)}
            isDisabled={false}
            placeholder="Zoek een categorie"
            theme={selectStyle}
            noOptionsMessage={
              () => 'Geen categorieën gevonden'
            }
            isLoading={categoryQuery.isLoading}
          />
        </div>

        {/* Datum */}
        <div className="form-group">
          <label>Datum</label>
          <input
            type="date"
            className="form-control"
            placeholder="Datum"
            defaultValue={date}
            onChange={(event) => { setDate(event.target.value) }}
          />
        </div>

        <div className="form-group">
          <label>Start tijd</label>
          <input
            type="time"
            className="form-control"
            placeholder="Start tijd"
            defaultValue={new Date().toISOString().slice(0, 16)}
            onChange={(event) => { setStartTime(event.target.value) }}
          />
        </div>

        <div className="form-group">
          <label>Eind tijd</label>
          <input
            type="time"
            className="form-control"
            placeholder="Eind tijd"
            defaultValue={new Date().toISOString().slice(0, 16)}
            onChange={(event) => { setEndTime(event.target.value) }}
          />
        </div>

        <div className="form-group">
          <label>Tijd besteed</label>
          <input
            type="number"
            className="form-control"
            onWheel={(event) => { event.currentTarget.blur() }}
            placeholder="Tijd besteed"
            value={timeSpent}
            onChange={(event) => { setTimeSpent(Number(event.target.value)) }}
            style={{
              appearance: 'textfield',
            }}
          />
        </div>

        <div className="form-group">
          <label>Notities</label>
          <textarea
            className="form-control"
            placeholder="Notities"
            defaultValue={notes}
            onChange={(event) => { setNotes(event.target.value) }}
          />
        </div>

        <div className="form-group">
          <label>Advies factureerbaar</label>
          {/* Left align */}
          <input
            type="checkbox"
            className="form-control"
            placeholder="Factureerbaar"
            style={{
              width: '30px',
            }}
            defaultChecked={billable}
            onChange={(event) => { setBillable(event.target.checked) }}
          />
        </div>
      </div>
      <div className="card-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => navigate('/administratie/urenregistratie')}
          style={{
            float: 'left',

          }}
        >
          Annuleren
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleSubmit}
          style={{
            float: 'right',
          }}
        >
          Opslaan
        </button>
      </div>
    </div>
  )
}
